/* eslint-disable sonarjs/no-duplicate-string */
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { cn } from '@chaseweb/utils/cn'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { Slot } from '@radix-ui/react-slot'

export type CardShapeType = 'rounded-sm' | 'rounded-md' | 'rounded-lg' | 'hero'

type CardVariantProps = VariantProps<typeof cardVariants>

const cardVariants = cva('tw-relative tw-h-full tw-overflow-hidden', {
  variants: {
    shape: {
      hero: ['tw-rounded-b-[2rem]'],
      'rounded-sm': ['tw-rounded-lg'],
      'rounded-md': ['tw-rounded-2xl'],
      'rounded-lg': ['tw-rounded-2xl', 'lg:tw-rounded-3xl'],
    },
  },
  defaultVariants: {
    shape: null,
  },
})

export interface CardProps
  extends HTMLAttributes<HTMLDivElement>,
    CardVariantProps {
  shape?: CardShapeType
  asChild?: boolean
}

const CardProfile = forwardRef<HTMLDivElement, CardProps>(
  ({ className, shape, children, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        data-ui-component={CardProfile.displayName}
        className={cn(cardVariants({ shape }), className)}
        {...props}
      >
        {children}
      </Comp>
    )
  },
)
CardProfile.displayName = 'CardProfile'

export { CardProfile }
