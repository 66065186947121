import { forwardRef } from 'react'
import type { SvgType } from '../../atoms'
import { Heading, CardProfile, SVG } from '../../atoms'
import { FeatureText, InfoBlock } from '../../molecules'

export interface ContactUsTemplateProps {
  title: string
  intro: string | JSX.Element | JSX.Element[]
  boxes: Array<{
    icon: SvgType
    title: string
    content: string | JSX.Element | JSX.Element[]
    disclaimer?: string | JSX.Element | JSX.Element[]
    cta?: JSX.Element
  }>
  infoBlock?: {
    title: string
    description: string
    cta: JSX.Element
  }
  outro: string | JSX.Element | JSX.Element[]
}

export const ContactUsTemplate = forwardRef<
  HTMLDivElement,
  ContactUsTemplateProps
>(({ title, intro, outro, boxes, infoBlock }, ref) => {
  return (
    <div className="tw-container" ref={ref}>
      <Heading
        type="h1"
        context="pageTitle"
        className="tw-mb-6 tw-mt-4 xl:tw-mt-6"
      >
        {title}
      </Heading>
      <div className="tw-prose tw-w-full">{intro}</div>
      <div className="tw-mb-20 tw-mt-14 tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 xl:tw-grid-cols-4">
        {boxes.map(({ icon, title, content, cta, disclaimer }) => {
          return (
            <CardProfile
              key={title}
              data-testid="card"
              shape="rounded-md"
              className="tw-bg-blue90 tw-p-6"
            >
              <FeatureText.Root>
                <FeatureText.Title
                  type="h5"
                  as="h2"
                  className="tw-mt-0 tw-flex tw-gap-2 tw-font-semibold"
                >
                  <SVG name={icon} aria-label={title} />
                  {title}
                </FeatureText.Title>
                <FeatureText.Content className="tw-prose tw-mb-4 prose-p:tw-mb-0 prose-p:tw-mt-4">
                  {content}
                </FeatureText.Content>
                {disclaimer && (
                  <FeatureText.Disclaimer className="tw-prose tw-mb-4 tw-text-sm tw-text-grey30">
                    {disclaimer}
                  </FeatureText.Disclaimer>
                )}
                {cta && (
                  <FeatureText.Cta asChild variant="primary">
                    {cta}
                  </FeatureText.Cta>
                )}
              </FeatureText.Root>
            </CardProfile>
          )
        })}
      </div>
      <div className="tw-prose tw-mb-14 md:tw-mb-20 lg:tw-mb-[136px]">
        {outro}
      </div>
      {infoBlock && (
        <InfoBlock.Root variant="secondary" className="tw-mb-16">
          <InfoBlock.ImageWrapper>
            <SVG name="InformationBlueRing" />
          </InfoBlock.ImageWrapper>
          <InfoBlock.Content>
            <InfoBlock.Title>{infoBlock.title}</InfoBlock.Title>
            <InfoBlock.Description>
              {infoBlock.description}
            </InfoBlock.Description>
            <InfoBlock.Cta asChild noExternalIcon>
              {infoBlock.cta}
            </InfoBlock.Cta>
          </InfoBlock.Content>
        </InfoBlock.Root>
      )}
    </div>
  )
})
ContactUsTemplate.displayName = 'ContactUs'
