import type { SVGSpriteComponent } from '../types'

const IsaAccount: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M21.6551 7.51303C21.8323 7.12602 21.927 6.70639 21.9332 6.28082C21.9394 5.85524 21.8569 5.43303 21.6911 5.04103C21.6193 4.85538 21.4766 4.70588 21.2945 4.62543C21.1124 4.54497 20.9058 4.54015 20.7201 4.61203C20.5345 4.6839 20.385 4.82658 20.3045 5.00868C20.2241 5.19078 20.2193 5.39738 20.2911 5.58303C20.3825 5.78754 20.4298 6.00902 20.4298 6.23303C20.4298 6.45703 20.3825 6.67852 20.2911 6.88303C20.1932 7.08728 20.0625 7.2741 19.9041 7.43603C19.2148 6.65349 18.3933 5.99819 17.4771 5.50003C17.4771 5.57403 17.4981 5.64303 17.4981 5.71703C17.4717 7.09142 16.9073 8.40061 15.9261 9.36338C14.9449 10.3262 13.6252 10.8657 12.2506 10.8661C10.876 10.8665 9.556 10.3277 8.57427 9.36548C7.59254 8.40328 7.02736 7.0944 7.00014 5.72003C7.00865 5.52506 7.02835 5.33074 7.05914 5.13803L4.78014 5.47803C4.66442 5.49521 4.55431 5.53918 4.4586 5.60645C4.36289 5.67372 4.28421 5.76241 4.22885 5.86547C4.17348 5.96853 4.14296 6.08309 4.13971 6.20003C4.13646 6.31697 4.16058 6.43306 4.21014 6.53903L4.90014 8.01703C4.6129 8.41078 4.36504 8.8318 4.16014 9.27403L2.37614 9.94403C2.23367 9.99761 2.11087 10.0933 2.02406 10.2183C1.93725 10.3433 1.89054 10.4918 1.89014 10.644V13.7C1.8904 13.8523 1.93706 14.0008 2.02388 14.1259C2.11071 14.2509 2.23359 14.3466 2.37614 14.4L4.15914 15.069C4.99867 16.8145 6.41316 18.2181 8.16514 19.044V21.22C8.16514 21.4189 8.24415 21.6097 8.38481 21.7504C8.52546 21.891 8.71622 21.97 8.91514 21.97H11.0071C11.206 21.97 11.3968 21.891 11.5375 21.7504C11.6781 21.6097 11.7571 21.4189 11.7571 21.22V20.02C12.6524 20.1061 13.5554 20.0677 14.4401 19.906V21.22C14.4401 21.4189 14.5192 21.6097 14.6598 21.7504C14.8005 21.891 14.9912 21.97 15.1901 21.97H17.2811C17.48 21.97 17.6708 21.891 17.8115 21.7504C17.9521 21.6097 18.0311 21.4189 18.0311 21.22V18.534C19.1404 17.8768 20.0639 16.9478 20.7146 15.8347C21.3652 14.7216 21.7216 13.461 21.7501 12.172C21.7442 10.9391 21.4126 9.72967 20.7891 8.66603C21.1499 8.34151 21.444 7.9499 21.6551 7.51303Z"
        fill="#005EB8"
      />
      <path
        d="M15.8711 4.80004C15.6242 3.89006 15.0398 3.10837 14.2369 2.61404C13.434 2.11971 12.4729 1.94991 11.5492 2.1392C10.6256 2.32848 9.80873 2.86263 9.26498 3.63291C8.72122 4.4032 8.49142 5.35174 8.62235 6.28548C8.75328 7.21922 9.23509 8.06798 9.96972 8.65902C10.7044 9.25005 11.6366 9.53894 12.5767 9.46689C13.5168 9.39484 14.3942 8.96726 15.0302 8.27118C15.6661 7.5751 16.013 6.66282 16.0001 5.72004C15.9954 5.40921 15.9521 5.10017 15.8711 4.80004Z"
        fill="#80BBF6"
      />
    </symbol>
  )
}
IsaAccount.displayName = 'SVG.IsaAccount'
const IsaAccountConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { IsaAccount, IsaAccountConfigProps }
