import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { NotificationSessionKeyTypesEnum } from './types'

export interface NotificationSessionStorageState {
  [NotificationSessionKeyTypesEnum.HOME]: boolean
  [NotificationSessionKeyTypesEnum.PRODUCT]: boolean
  [NotificationSessionKeyTypesEnum.SUPPORT]: boolean
  [NotificationSessionKeyTypesEnum.BROWSER]: boolean
  getNotificationValue: (
    notificationName: NotificationSessionKeyTypesEnum,
  ) => boolean
  setNotificationValue: (
    notificationName: NotificationSessionKeyTypesEnum,
    value: boolean,
  ) => void
}

const initialNotificationSessionStorageState = {
  [NotificationSessionKeyTypesEnum.HOME]: true,
  [NotificationSessionKeyTypesEnum.PRODUCT]: true,
  [NotificationSessionKeyTypesEnum.SUPPORT]: true,
  [NotificationSessionKeyTypesEnum.BROWSER]: true,
}

const notificationSessionStore = (set: any, get: any) => ({
  ...initialNotificationSessionStorageState,
  getNotificationValue: (notificationName: NotificationSessionKeyTypesEnum) => {
    return get()[notificationName]
  },
  setNotificationValue: (
    notificationName: NotificationSessionKeyTypesEnum,
    value: boolean,
  ) => {
    set(() => {
      return { [notificationName]: value }
    })
  },
})

export const useSessionStorageNotificationStore =
  create<NotificationSessionStorageState>()(
    persist(notificationSessionStore, {
      name: 'notification-dismissals',
      storage: createJSONStorage(() => sessionStorage),
    }),
  )
