import type { SVGSpriteComponent } from '../types'

const Security: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M20.1816 4.27247L12.1816 2.27247C12.0624 2.24267 11.9376 2.24267 11.8184 2.27247L3.8184 4.27247C3.65609 4.31298 3.51199 4.40658 3.409 4.5384C3.30601 4.67022 3.25004 4.83269 3.25 4.99997V13C3.25 13.1821 3.3457 17.5112 11.6646 21.6709C11.7688 21.7229 11.8836 21.75 12 21.75C12.1164 21.75 12.2312 21.7229 12.3354 21.6709C20.6543 17.5112 20.75 13.1821 20.75 13V4.99997C20.75 4.83269 20.694 4.67022 20.591 4.5384C20.488 4.40658 20.3439 4.31298 20.1816 4.27247ZM12 20.1592C4.9492 16.5288 4.751 13.0234 4.75 13V5.58537L12 3.77287L19.25 5.58537V12.9878C19.249 13.0234 19.0508 16.5288 12 20.1592ZM13.75 8.99997C13.749 9.33007 13.6545 9.65314 13.4775 9.9318C13.3006 10.2105 13.0483 10.4333 12.75 10.5747V14C12.75 14.1989 12.671 14.3896 12.5303 14.5303C12.3897 14.671 12.1989 14.75 12 14.75C11.8011 14.75 11.6103 14.671 11.4697 14.5303C11.329 14.3896 11.25 14.1989 11.25 14V10.5747C10.9678 10.44 10.7269 10.2321 10.5524 9.97269C10.3779 9.71324 10.2763 9.41173 10.258 9.0996C10.2397 8.78746 10.3055 8.47615 10.4486 8.19811C10.5916 7.92007 10.8066 7.68551 11.0711 7.51887C11.3357 7.35222 11.6401 7.25962 11.9527 7.2507C12.2652 7.24178 12.5744 7.31689 12.848 7.46817C13.1217 7.61946 13.3497 7.84139 13.5083 8.11082C13.667 8.38024 13.7504 8.6873 13.75 8.99997Z"
        fill="currentColor"
      />
    </symbol>
  )
}
Security.displayName = 'SVG.Security'
const SecurityConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { Security, SecurityConfigProps }
