import type { SVGSpriteComponent } from '../types'

const Card: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M20 4.25H4C3.53605 4.25058 3.09127 4.43514 2.76321 4.76321C2.43514 5.09127 2.25058 5.53605 2.25 6V18C2.25058 18.464 2.43514 18.9087 2.76321 19.2368C3.09127 19.5649 3.53605 19.7494 4 19.75H20C20.464 19.7494 20.9087 19.5649 21.2368 19.2368C21.5649 18.9087 21.7494 18.464 21.75 18V6C21.7494 5.53605 21.5649 5.09127 21.2368 4.76321C20.9087 4.43514 20.464 4.25058 20 4.25ZM20.25 18C20.25 18.0663 20.2237 18.1299 20.1768 18.1768C20.1299 18.2237 20.0663 18.25 20 18.25H4C3.9337 18.25 3.87011 18.2237 3.82322 18.1768C3.77634 18.1299 3.75 18.0663 3.75 18V10.75H20.25V18ZM3.75 8.25V6C3.75 5.9337 3.77634 5.87011 3.82322 5.82322C3.87011 5.77634 3.9337 5.75 4 5.75H20C20.0663 5.75 20.1299 5.77634 20.1768 5.82322C20.2237 5.87011 20.25 5.9337 20.25 6V8.25H3.75ZM5.25 16C5.25 15.8011 5.32902 15.6103 5.46967 15.4697C5.61032 15.329 5.80109 15.25 6 15.25H8C8.19891 15.25 8.38968 15.329 8.53033 15.4697C8.67098 15.6103 8.75 15.8011 8.75 16C8.75 16.1989 8.67098 16.3897 8.53033 16.5303C8.38968 16.671 8.19891 16.75 8 16.75H6C5.80109 16.75 5.61032 16.671 5.46967 16.5303C5.32902 16.3897 5.25 16.1989 5.25 16ZM10.25 16C10.25 15.8011 10.329 15.6103 10.4697 15.4697C10.6103 15.329 10.8011 15.25 11 15.25H15C15.1989 15.25 15.3897 15.329 15.5303 15.4697C15.671 15.6103 15.75 15.8011 15.75 16C15.75 16.1989 15.671 16.3897 15.5303 16.5303C15.3897 16.671 15.1989 16.75 15 16.75H11C10.8011 16.75 10.6103 16.671 10.4697 16.5303C10.329 16.3897 10.25 16.1989 10.25 16Z"
        fill="currentColor"
      />
    </symbol>
  )
}
Card.displayName = 'SVG.Card'
const CardConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { Card, CardConfigProps }
