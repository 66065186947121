import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { Children, forwardRef } from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { Heading } from '../../atoms'

const Root = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      className={cn(
        'tw-flex tw-w-auto tw-flex-col tw-items-start tw-overflow-hidden',
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})
Root.displayName = 'LogoTileGrid.Root'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type = 'h2', ...props }, ref) => (
  <Heading
    ref={ref}
    type={type}
    className={cn('tw-w-full tw-pt-16 lg:tw-pt-18', className)}
    {...props}
  />
))
Title.displayName = 'LogoTileGrid.Title'

export interface DescriptionProps extends HTMLAttributes<HTMLParagraphElement> {
  asChild?: boolean
}

const Description = forwardRef<HTMLParagraphElement, DescriptionProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        className={cn('tw-w-full tw-pt-6', className)}
        {...props}
      />
    )
  },
)
Description.displayName = 'LogoTileGrid.Description'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    const childrenCount = Children.count(children)
    return (
      <div
        ref={ref}
        className={cn(
          'tw-flex tw-w-full tw-flex-col tw-items-start tw-pt-10 lg:tw-pt-12 [&>*]:tw-h-full',
          { 'md:tw-grid md:tw-grid-cols-2 md:tw-gap-6': childrenCount >= 2 },
          {
            'lg:tw-grid lg:tw-grid-cols-3':
              childrenCount === 3 || childrenCount > 4,
          },
          { 'lg:tw-grid-cols-2 xl:tw-grid-cols-4': childrenCount === 4 },
          className,
        )}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Content.displayName = 'LogoTileGrid.Content'

export { Root, Title, Description, Content }
