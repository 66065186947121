export const motionValues = {
  easing: {
    standard: [0.4, 0.0, 0.2, 1.0],
    sharp: [0.4, 0.0, 0.6, 1.0],
  },
  duration: {
    fast3: 0.1,
    moderate4: 0.3,
  },
}
