import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'

const Root = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
    hasLink?: boolean
  }
>(({ className, asChild, hasLink = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      ref={ref}
      className={cn(
        'tw-mb-6 tw-flex tw-w-full tw-flex-col tw-items-center tw-rounded-2xl tw-border tw-border-grey70 tw-bg-white tw-p-6',
        'tw-transition-all tw-duration-300',
        'md:tw-mb-0 md:tw-w-auto lg:tw-rounded-3xl lg:tw-p-8',
        'focus:tw-border-blue30 focus-visible:tw-ring-2 focus-visible:tw-ring-focusRing focus-visible:tw-ring-offset-0',
        { 'hover:tw-border-blue30 active:tw-border-blue10': hasLink },
        className,
      )}
      {...props}
    />
  )
})
Root.displayName = 'LogoTile.Root'

const ImageWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div className="tw-relative tw-my-2 tw-h-full tw-w-full">
        <div
          className={cn(
            'tw-flex tw-justify-center [&_*]:tw-absolute [&_*]:tw-top-1/2 [&_*]:tw-max-h-full [&_*]:tw-max-w-full [&_*]:-tw-translate-y-1/2',
            className,
          )}
          {...props}
          ref={ref}
        >
          {children}
        </div>
        <div className="tw-h-20 tw-w-[180px]" />
      </div>
    )
  },
)
ImageWrapper.displayName = 'LogoTile.ImageWrapper'

export interface LogoLabelProps extends HTMLAttributes<HTMLHeadingElement> {
  asChild?: boolean
}

const LogoLabel = forwardRef<HTMLHeadingElement, LogoLabelProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'h3'
    return (
      <Comp
        ref={ref}
        className={cn(
          'tw-mt-2 tw-h-full tw-text-center tw-font-sans tw-text-base tw-font-semibold tw-text-blue30',
          className,
        )}
        {...props}
      />
    )
  },
)
LogoLabel.displayName = 'LogoTile.LogoLabel'

export { Root, ImageWrapper, LogoLabel }
