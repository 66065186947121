import type { SVGSpriteComponent } from '../types'

const Instagram: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M12 2c2.717 0 3.056.01 4.122.06a7.367 7.367 0 012.428.465 4.876 4.876 0 011.772 1.153 4.908 4.908 0 011.153 1.772 7.387 7.387 0 01.465 2.428C21.987 8.944 22 9.283 22 12s-.01 3.056-.06 4.122a7.393 7.393 0 01-.465 2.428 5.106 5.106 0 01-2.925 2.925 7.387 7.387 0 01-2.428.465c-1.066.047-1.405.06-4.122.06s-3.056-.01-4.122-.06a7.393 7.393 0 01-2.428-.465 5.106 5.106 0 01-2.925-2.925 7.361 7.361 0 01-.465-2.428C2.013 15.056 2 14.717 2 12s.01-3.056.06-4.122a7.361 7.361 0 01.465-2.428 4.88 4.88 0 011.153-1.772A4.897 4.897 0 015.45 2.525a7.361 7.361 0 012.428-.465C8.944 2.013 9.283 2 12 2zm0 2c-2.474 0-2.878.007-4.029.058a5.433 5.433 0 00-1.798.332 2.886 2.886 0 00-1.08.703 2.89 2.89 0 00-.704 1.08 5.425 5.425 0 00-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029a5.454 5.454 0 00.331 1.797 3.11 3.11 0 001.782 1.783 5.444 5.444 0 001.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058a5.465 5.465 0 001.797-.331 2.92 2.92 0 001.08-.702 2.891 2.891 0 00.704-1.08 5.467 5.467 0 00.332-1.8c.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029a5.443 5.443 0 00-.332-1.798 2.911 2.911 0 00-.703-1.08 2.884 2.884 0 00-1.08-.704 5.43 5.43 0 00-1.798-.331C14.925 4.006 14.539 4 12 4zm6.5 2.75a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zM12 7a5 5 0 11-5 5 5 5 0 015-5zm0 2a3 3 0 103 3 3 3 0 00-3-3z"
        fill="currentColor"
      />
    </symbol>
  )
}
Instagram.displayName = 'SVG.Instagram'
const InstagramConfigProps = { width: '24', viewBox: '1 1 22 22' }
export { Instagram, InstagramConfigProps }
