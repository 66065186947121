import type { SVGSpriteComponent } from '../types'

const ChaseOctagon: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol {...props}>
      {children}
      <path
        d="M9.688.119c-.23 0-.45.088-.611.246a.823.823 0 00-.251.594v5.85h15.958L17.513.12H9.688zM25.383 8.59a.82.82 0 00-.25-.595.872.872 0 00-.613-.245h-6.05v15.487l6.913-7.06V8.59zM16.633 23.782a.85.85 0 00.862-.835v-5.851H1.537l7.262 6.686h7.834zM.947 15.324a.823.823 0 00.256.59.877.877 0 00.611.242h6.037V.686L.956 7.732l-.009 7.592z"
        fill="currentColor"
      />
    </symbol>
  )
}
ChaseOctagon.displayName = 'SVG.ChaseOctagon'
const ChaseOctagonConfigProps = { viewBox: '0 0 26 24' }
export { ChaseOctagon, ChaseOctagonConfigProps }
