import type { SVGSpriteComponent } from '../types'

const InformationBlueRing: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <g>
        <path
          d="M64 128C76.658 128 89.0317 124.246 99.5565 117.214C110.081 110.182 118.284 100.186 123.128 88.4918C127.972 76.7973 129.24 63.929 126.77 51.5142C124.301 39.0995 118.205 27.6957 109.255 18.7452C100.304 9.79462 88.9006 3.69921 76.4858 1.22976C64.071 -1.2397 51.2027 0.0277149 39.5083 4.87172C27.8138 9.71573 17.8184 17.9188 10.7859 28.4435C3.75353 38.9683 0 51.342 0 64C0 80.9739 6.74284 97.2525 18.7452 109.255C30.7475 121.257 47.0261 128 64 128Z"
          fill="#EAF6FF"
        />
        <path
          d="M64 102.624C71.639 102.624 79.1065 100.358 85.458 96.1142C91.8096 91.87 96.76 85.8377 99.6832 78.7801C102.606 71.7225 103.371 63.9565 101.881 56.4642C100.39 48.972 96.7117 42.0899 91.31 36.6884C85.9082 31.2868 79.0261 27.6083 71.5338 26.1181C64.0415 24.6278 56.2756 25.3928 49.218 28.3162C42.1605 31.2396 36.1283 36.1901 31.8843 42.5417C27.6402 48.8934 25.375 56.3609 25.375 64C25.375 69.0723 26.3741 74.0948 28.3152 78.781C30.2563 83.4671 33.1014 87.725 36.688 91.3116C40.2747 94.8982 44.5327 97.7432 49.2189 99.6842C53.9051 101.625 58.9277 102.624 64 102.624Z"
          fill="#0D76D9"
        />
        <path
          d="M64 51.7107C65.2151 51.7107 66.4029 51.3504 67.4133 50.6753C68.4237 50.0002 69.2112 49.0407 69.6762 47.9181C70.1412 46.7955 70.263 45.5602 70.026 44.3684C69.789 43.1766 69.2039 42.0818 68.3447 41.2225C67.4855 40.3633 66.3909 39.778 65.1991 39.5409C64.0073 39.3037 62.772 39.4253 61.6493 39.8902C60.5266 40.3551 59.567 41.1424 58.8918 42.1527C58.2166 43.163 57.8561 44.3508 57.856 45.5659C57.8559 46.3728 58.0147 47.1719 58.3234 47.9174C58.6321 48.6629 59.0847 49.3403 59.6552 49.9109C60.2257 50.4815 60.9031 50.9341 61.6486 51.243C62.394 51.5518 63.1931 51.7107 64 51.7107Z"
          fill="white"
        />
        <path
          d="M64 58.733C62.6033 58.7332 61.2638 59.2882 60.2761 60.2759C59.2885 61.2636 58.7337 62.6032 58.7336 64V81.5562C58.7336 82.9529 59.2885 84.2925 60.2761 85.2803C61.2637 86.268 62.6032 86.823 64 86.8232C64.6917 86.8232 65.3766 86.687 66.0156 86.4223C66.6546 86.1576 67.2353 85.7696 67.7243 85.2805C68.2134 84.7914 68.6014 84.2108 68.8661 83.5718C69.1307 82.9327 69.2669 82.2478 69.2669 81.5562V64C69.2669 62.6031 68.712 61.2634 67.7243 60.2757C66.7366 59.2879 65.3969 58.733 64 58.733Z"
          fill="white"
        />
      </g>
    </symbol>
  )
}

InformationBlueRing.displayName = 'SVG.InformationBlueRing'
const InformationBlueRingConfigProps = {
  width: '128px',
  height: '128px',
  viewBox: '0 0 128 128',
}
export { InformationBlueRing, InformationBlueRingConfigProps }
