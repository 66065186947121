import type { SVGSpriteComponent } from '../types'

const YouTube: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M10 15.5v-7l6 3.5zm11.543-9.002A31.058 31.058 0 0122 12a31.058 31.058 0 01-.457 5.502 2.811 2.811 0 01-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476a2.81 2.81 0 01-1.938-2.022A31.058 31.058 0 012 12a31.058 31.058 0 01.457-5.502 2.811 2.811 0 011.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476a2.81 2.81 0 011.938 2.022zm-1.937.497a.812.812 0 00-.539-.592A38.578 38.578 0 0012 6a38.653 38.653 0 00-7.069.403.814.814 0 00-.537.592A24.962 24.962 0 004 12a24.996 24.996 0 00.394 5.006.81.81 0 00.538.59A38.458 38.458 0 0012 18a38.643 38.643 0 007.069-.403.814.814 0 00.537-.592A25 25 0 0020 12a25.01 25.01 0 00-.394-5.005z"
        fill="currentColor"
      />
    </symbol>
  )
}
YouTube.displayName = 'SVG.YouTube'
const YouTubeConfigProps = { width: '24', viewBox: '1 1 22 20' }
export { YouTube, YouTubeConfigProps }
