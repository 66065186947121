import { cn } from '@chaseweb/utils/cn'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { forwardRef } from 'react'
import { Heading } from '../../atoms'
import { Slot } from '@radix-ui/react-slot'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div className={cn('tw-w-full')} ref={ref} {...props}>
        {children}
      </div>
    )
  },
)
Root.displayName = 'SummaryList.Root'

const Card = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-grid tw-grid-cols-1 tw-gap-8 tw-rounded-2xl tw-bg-blue90 tw-p-8',
          'md:tw-grid-cols-[192px_auto]',
          'tw-rounded-3xl lg:tw-grid-cols-[301px_auto] lg:tw-gap-x-10 lg:tw-gap-y-8 lg:tw-p-10',
          'xl:tw-grid-cols-[397px_auto]',
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Card.displayName = 'SummaryList.Card'

const ImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      className={cn(
        'tw-flex tw-w-full tw-rounded-lg tw-shadow-image lg:tw-row-span-2 xl:tw-rounded-2xl',
      )}
      ref={ref}
      {...props}
    ></Comp>
  )
})
ImageWrapper.displayName = 'SummaryList.ImageWrapper'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type, ...props }, ref) => (
  <Heading
    ref={ref}
    className={cn('md:tw-self-center')}
    type={'h2'}
    {...props}
  />
))
Title.displayName = 'SummaryList.Title'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn('md:tw-col-span-2 lg:tw-col-span-1')}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Content.displayName = 'SummaryList.Content'

const Wrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-grid tw-w-fit tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2 md:tw-gap-x-14 md:tw-gap-y-6',
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Wrapper.displayName = 'SummaryList.Wrapper'

const Item = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    )
  },
)
Item.displayName = 'SummaryList.Item'

const Label = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  return (
    <p className={cn('tw-font-semibold')} ref={ref} {...props}>
      {children}
    </p>
  )
})
Label.displayName = 'SummaryList.Label'

const TextWrapper = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      className={cn('tw-mt-2 tw-inline-flex tw-flex-wrap tw-items-baseline')}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  )
})
TextWrapper.displayName = 'SummaryList.TextWrapper'

const Value = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  return (
    <Heading
      type={'h3'}
      className={cn('tw-mr-2 tw-text-2xl')}
      ref={ref}
      {...props}
    >
      {children}
    </Heading>
  )
})
Value.displayName = 'SummaryList.Value'

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  return (
    <span ref={ref} {...props}>
      {children}
    </span>
  )
})
Description.displayName = 'SummaryList.Description'

const Disclaimer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-mt-6 tw-text-center tw-text-sm tw-text-grey40 lg:tw-mt-8',
        className,
      )}
      {...props}
    />
  ),
)
Disclaimer.displayName = 'SummaryList.Disclaimer'

export {
  Root,
  Card,
  ImageWrapper,
  Title,
  Content,
  Wrapper,
  Item,
  Label,
  TextWrapper,
  Description,
  Value,
  Disclaimer,
}
