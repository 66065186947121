import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { Heading, Prose } from '../../atoms'

export interface PlainPageTemplateProps {
  title: string
  content: ReactNode
}

export const PlainPageTemplate = forwardRef<
  HTMLDivElement,
  PlainPageTemplateProps
>(({ title, content }, ref) => {
  return (
    <div className="tw-container-article" ref={ref}>
      <Heading type="h1" context="pageTitle">
        {title}
      </Heading>
      <Prose>{content}</Prose>
    </div>
  )
})
PlainPageTemplate.displayName = 'PlainText'
