import type { SVGSpriteComponent } from '../types'

const SuccessStatus: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M21.75 12C21.75 13.9284 21.1782 15.8134 20.1068 17.4168C19.0355 19.0202 17.5127 20.2699 15.7312 21.0078C13.9496 21.7458 11.9892 21.9389 10.0979 21.5627C8.20656 21.1865 6.46928 20.2579 5.10571 18.8943C3.74215 17.5307 2.81355 15.7934 2.43735 13.9021C2.06114 12.0108 2.25422 10.0504 2.99218 8.26884C3.73013 6.48726 4.97982 4.96452 6.58319 3.89317C8.18657 2.82183 10.0716 2.25 12 2.25C14.5849 2.25299 17.0632 3.28118 18.891 5.10901C20.7188 6.93684 21.747 9.41506 21.75 12ZM11.53 15.53L16.53 10.53C16.6625 10.3878 16.7346 10.1998 16.7312 10.0055C16.7278 9.81118 16.649 9.62579 16.5116 9.48838C16.3742 9.35097 16.1888 9.27225 15.9945 9.26882C15.8002 9.2654 15.6122 9.33752 15.47 9.47L11 13.9393L8.53 11.47C8.38783 11.3375 8.19978 11.2654 8.00548 11.2688C7.81118 11.2723 7.6258 11.351 7.48838 11.4884C7.35097 11.6258 7.27226 11.8112 7.26883 12.0055C7.2654 12.1998 7.33752 12.3878 7.47 12.53L10.47 15.53C10.6107 15.6706 10.8014 15.7496 11.0003 15.7496C11.1992 15.7496 11.39 15.6706 11.5306 15.53H11.53Z"
        fill="currentColor"
      />
    </symbol>
  )
}

SuccessStatus.displayName = 'SVG.SuccessStatus'
const SuccessStatusConfigProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
}
export { SuccessStatus, SuccessStatusConfigProps }
