import type { SVGSpriteComponent } from '../types'

const RedirectIcon: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path
        fill="currentColor"
        d="M19.6919 11.7134C19.6547 11.6223 19.5997 11.5395 19.53 11.47L13.53 5.47C13.3878 5.33752 13.1998 5.2654 13.0055 5.26882C12.8112 5.27225 12.6258 5.35096 12.4884 5.48838C12.351 5.62579 12.2723 5.81118 12.2688 6.00548C12.2654 6.19978 12.3375 6.38782 12.47 6.53L17.19 11.25H5C4.80109 11.25 4.61032 11.329 4.46967 11.4697C4.32902 11.6103 4.25 11.8011 4.25 12C4.25 12.1989 4.32902 12.3897 4.46967 12.5303C4.61032 12.671 4.80109 12.75 5 12.75H17.19L12.47 17.47C12.3963 17.5387 12.3372 17.6215 12.2962 17.7135C12.2552 17.8055 12.2332 17.9048 12.2314 18.0055C12.2296 18.1062 12.2482 18.2062 12.2859 18.2996C12.3236 18.393 12.3797 18.4778 12.451 18.549C12.5222 18.6203 12.607 18.6764 12.7004 18.7141C12.7938 18.7518 12.8938 18.7704 12.9945 18.7686C13.0952 18.7668 13.1945 18.7448 13.2865 18.7038C13.3785 18.6628 13.4613 18.6037 13.53 18.53L19.53 12.53C19.5996 12.4604 19.6546 12.3775 19.6916 12.2863C19.7293 12.1955 19.7488 12.0982 19.7488 11.9999C19.7489 11.9016 19.7295 11.8042 19.6919 11.7134Z"
      />
    </symbol>
  )
}
RedirectIcon.displayName = 'SVG.RedirectIcon'
const RedirectIconConfigProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
}
export { RedirectIcon, RedirectIconConfigProps }
