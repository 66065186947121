import * as appsflyer from './appsflyerOnelinkSmartScript'

const AF_WEB_DP = 'af_web_dp'
const APPSTORE_REDIRECT = 'https://apps.apple.com/GB/app/id1517121245?mt=8'
const PLAYSTORE_REDIRECT =
  'https://play.google.com/store/apps/details?id=com.chase.intl&hl=en_GB&gl=US'

export const SEARCH_ENGINES: Record<string, string> = {
  Google: 'WWW.GOOGLE',
  Bing: 'WWW.BING',
  YahooUK: 'UK.SEARCH.YAHOO.COM',
  Duckduckgo: 'DUCKDUCKGO.COM',
  Yahoo: 'SEARCH.YAHOO.COM',
  Ecosia: 'ECOSIA.ORG',
  AOL: 'SEARCH.AOL',
}

const getReferrerDetails = () => {
  const { referrer } = document
  if (referrer) {
    const name = Object.keys(SEARCH_ENGINES).find(
      (value) =>
        referrer.toUpperCase().includes(SEARCH_ENGINES[value]) && value,
    )
    return name
      ? {
          pid: 'Organic Search',
          channel: 'Organic Search',
          c: name,
          ad: referrer,
        }
      : {
          pid: 'organicwebsite',
          channel: 'Referring Domain',
          ad: referrer,
        }
  }
}

export const generateOneLinkUrl = (
  oneLinkURL: string,
  variant?: string,
  sitePrefix?: string,
): string => {
  if (typeof window !== 'undefined' && window) {
    const { pid, channel, c, ad } = getReferrerDetails() ?? {
      channel: 'organicwebsite-no referrer/UTM',
    }
    const onelinkGenerator = new appsflyer.OneLinkUrlGenerator({
      oneLinkURL,
      pidKeysList: ['utm_source', 'pid'],
      campaignKeysList: ['utm_campaign', 'c'],
      pidStaticValue: pid ?? 'organicwebsite',
      campaignStaticValue: c ?? (variant ? `website${variant}` : 'website'),
    })
    onelinkGenerator.setChannel('utm_medium', channel)
    onelinkGenerator.setChannel('af_channel')
    onelinkGenerator.setAd('utm_content', ad)
    onelinkGenerator.setAd('af_ad')
    onelinkGenerator.setCustomParameter('utm_term', 'af_keywords')
    onelinkGenerator.setCustomParameter('af_keywords', 'af_keywords')
    onelinkGenerator.setCustomParameter('af_xp', 'af_xp')
    onelinkGenerator.setCustomParameter('af_sub1', 'af_sub1')
    onelinkGenerator.setCustomParameter('ranMID', 'ranMID')
    onelinkGenerator.setCustomParameter('af_adset_id', 'af_adset_id')
    onelinkGenerator.setCustomParameter('af_sub_siteID', 'af_sub_siteID')
    onelinkGenerator.setCustomParameter('ranSiteID', 'ranSiteID')
    onelinkGenerator.setCustomParameter(
      'af_click_lookback',
      'af_click_lookback',
    )
    onelinkGenerator.setCustomParameter('ranEAID', 'ranEAID')
    onelinkGenerator.setCustomParameter('clickid', 'clickid')
    onelinkGenerator.setCustomParameter('af_siteid', 'af_siteid')
    switch (variant) {
      case 'download':
        onelinkGenerator.setCustomParameter(
          '',
          AF_WEB_DP,
          `${window.location.origin}/${sitePrefix ?? ''}download/`,
        )
        break
      case 'appstore':
        onelinkGenerator.setCustomParameter('', AF_WEB_DP, APPSTORE_REDIRECT)
        onelinkGenerator.setCustomParameter(
          '',
          'af_android_url',
          APPSTORE_REDIRECT,
        )
        break
      case 'playstore':
        onelinkGenerator.setCustomParameter('', AF_WEB_DP, PLAYSTORE_REDIRECT)
        onelinkGenerator.setCustomParameter(
          '',
          'af_ios_url',
          PLAYSTORE_REDIRECT,
        )
        break
      default:
        break
    }
    return onelinkGenerator.generateUrl()
  }
  /* istanbul ignore next - edge case for window=undefined */
  return oneLinkURL
}
