import { REDACTED } from '.'
import {
  CREDIT_CARD_NUMBER,
  ENCRYPTED_ID_REGEX,
  INTERNATIONAL_PHONE_NUMBER_1,
  INTERNATIONAL_PHONE_NUMBER_2,
  LOCAL_PHONE_NUMBER_1_REGEX,
  LOCAL_PHONE_NUMBER_2_REGEX,
  UUID_REGEX,
} from './pii'

export const isUUID = (value: string): boolean => UUID_REGEX.test(value)

export const isValidEncryptedId = (value?: string | null): boolean => {
  const match = value?.match(ENCRYPTED_ID_REGEX)
  return Boolean(match && match.length > 0)
}

/* Regular validator checks for mobile numbers only */
const containsPossibleLocalUKPhoneNumber = (value: string): boolean =>
  LOCAL_PHONE_NUMBER_1_REGEX.test(value) ||
  LOCAL_PHONE_NUMBER_2_REGEX.test(value)

const containsPossibleInternationalUKPhoneNumber = (value: string): boolean =>
  INTERNATIONAL_PHONE_NUMBER_1.test(value) ||
  INTERNATIONAL_PHONE_NUMBER_2.test(value)

export const containsPossibleUKPhoneNumber = (value: string): boolean =>
  containsPossibleLocalUKPhoneNumber(value) ||
  containsPossibleInternationalUKPhoneNumber(value)

export const containsPossibleCreditCardNumber = (value: string): boolean =>
  CREDIT_CARD_NUMBER.test(value)

const isEmailAddress = (email: string) => {
  if (email.split('@')[0].length > 64) {
    return false
  }
  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  return emailRegex.test(email)
}

export const redactPii = (str: string): string => {
  return [
    containsPossibleUKPhoneNumber,
    isEmailAddress,
    containsPossibleCreditCardNumber,
    isUUID,
  ].some((_) => _(str))
    ? REDACTED
    : str
}
