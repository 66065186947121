import { v4 as randomUuid } from 'uuid'
import { deleteCookies, getCookie, setCookieWithNoExpiry } from '../cookies'

export const OLD_PROSPECT_ID_COOKIE_NAME = 'prospect_id'
export const PROSPECT_ID_COOKIE_NAME = 'WebProspectId'

export const fetchProspectId = () => {
  const oldProspectID = getCookie(OLD_PROSPECT_ID_COOKIE_NAME)
  const existingProspectID = getCookie(PROSPECT_ID_COOKIE_NAME)

  if (existingProspectID) {
    deleteCookies([OLD_PROSPECT_ID_COOKIE_NAME])
    return existingProspectID.value
  } else if (oldProspectID) {
    deleteCookies([OLD_PROSPECT_ID_COOKIE_NAME])
    return setCookieWithNoExpiry({
      name: PROSPECT_ID_COOKIE_NAME,
      value: oldProspectID.value,
    }).value
  } else {
    return setCookieWithNoExpiry({
      name: PROSPECT_ID_COOKIE_NAME,
      value: randomUuid(),
    }).value
  }
}
