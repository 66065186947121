import type { SVGSpriteComponent } from '../types'

const Information: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M11.75 2C6.374 2 2 6.374 2 11.75C2 17.126 6.374 21.5 11.75 21.5C17.126 21.5 21.5 17.126 21.5 11.75C21.5 6.374 17.126 2 11.75 2ZM11.75 20C7.2012 20 3.5 16.2988 3.5 11.75C3.5 7.2012 7.2012 3.5 11.75 3.5C16.2988 3.5 20 7.2012 20 11.75C20 16.2988 16.2988 20 11.75 20Z"
        fill="currentColor"
      />
      <path
        d="M11.75 9.0161C12.2471 9.0161 12.6499 8.6128 12.6499 8.1157C12.6499 7.6186 12.2471 7.2158 11.75 7.2158C11.2529 7.2158 10.8501 7.6186 10.8501 8.1157C10.8501 8.6128 11.2529 9.0161 11.75 9.0161Z"
        fill="currentColor"
      />
      <path
        d="M13.4502 15H12.5V11.2495C12.5024 11.0488 12.4248 10.8555 12.2837 10.7129C12.1426 10.5703 11.9507 10.4902 11.75 10.4902H10.75C10.3359 10.4902 10 10.8256 10 11.2402C10 11.6543 10.3359 11.9902 10.75 11.9902H11V15H10.0498C9.6357 15 9.2998 15.3359 9.2998 15.75C9.2998 16.1641 9.6357 16.5 10.0498 16.5H13.4502C13.8643 16.5 14.2002 16.1641 14.2002 15.75C14.2002 15.3359 13.8643 15 13.4502 15Z"
        fill="currentColor"
      />
    </symbol>
  )
}
Information.displayName = 'SVG.Information'
const InformationConfigProps = {
  width: '24px',
  height: '24px',
  viewBox: '0 0 24 24',
}
export { Information, InformationConfigProps }
