import type { SVGSpriteComponent } from '../types'

const Tick: SVGSpriteComponent = ({
  children,
  role,
  spriteFilePath,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <symbol {...props}>
      {children}
      <path
        fill="currentColor"
        d="M10.152 14.944l7.878-7.879a.928.928 0 011.313 0 .928.928 0 010 1.314l-8.535 8.535a.928.928 0 01-1.313 0L4.9 12.318a.928.928 0 010-1.313.928.928 0 011.313 0z"
      />
    </symbol>
  )
}

Tick.displayName = 'SVG.Tick'
const TickConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { Tick, TickConfigProps }
