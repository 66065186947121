import type { SVGSpriteComponent } from '../types'

const CheckFill: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#fff"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"
      />
    </symbol>
  )
}

CheckFill.displayName = 'SVG.CheckFill'
const CheckFillConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { CheckFill, CheckFillConfigProps }
