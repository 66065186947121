import { Button } from '@chaseweb/ui-library/src'
import type { ComponentPropsWithoutRef } from 'react'
import React from 'react'

import type { LinkProps } from './link'
import { Link } from './link'

export function StyledLink({
  variant = 'link',
  href,
  sectionLabel,
  trackingActionLabel,
  notTracked,
  children,
  ...props
}: LinkProps & ComponentPropsWithoutRef<typeof Button>) {
  return (
    <Button variant={variant} asChild {...props}>
      {notTracked ? (
        <Link href={href} notTracked={notTracked}>
          {children}
        </Link>
      ) : (
        sectionLabel &&
        trackingActionLabel && (
          <Link
            href={href}
            sectionLabel={sectionLabel}
            trackingActionLabel={trackingActionLabel}
          >
            {children}
          </Link>
        )
      )}
    </Button>
  )
}
