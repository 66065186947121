/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  fetchProspectId,
  getUtmParametersFromCookie,
} from '@chaseweb/utils/tracking'

interface UrlGenerator {
  mediaSource: string
  campaign?: string
  afParams: object
}

interface UrlGeneratorConfig {
  oneLinkURL: string
  pidKeysList: string[]
  pidStaticValue: string
  campaignKeysList: string[]
  campaignStaticValue: string
  pidOverrideList?: string[]
  gclIdParam?: string
  currentLocationUrl?: string
}
export class OneLinkUrlGenerator implements UrlGenerator {
  mediaSource: string

  campaign?: string

  afParams: Record<string, any>

  constructor(private readonly config: UrlGeneratorConfig) {
    const {
      pidKeysList,
      pidStaticValue,
      campaignKeysList,
      campaignStaticValue,
      pidOverrideList = [],
    } = config

    if (!this.oneLinkUrlIsValid()) {
      throw new Error('OneLinkUrlGenerator: oneLinkURL arg invalid')
    }

    this.campaign = getCampaignValue(campaignKeysList, campaignStaticValue)
    this.mediaSource = getMediaSourceValue(
      pidKeysList,
      pidStaticValue,
      pidOverrideList,
    )
    this.afParams = { af_js_web: 'true' }
  }

  private oneLinkUrlIsValid(): boolean {
    const { oneLinkURL } = this.config
    return typeof oneLinkURL !== 'undefined' && oneLinkURL.trim().length > 0
  }

  generateUrl() {
    const searchParamsUrl = new URLSearchParams()
    const webProspectId = fetchProspectId()
    if (typeof this.mediaSource !== 'undefined') {
      searchParamsUrl.append('pid', this.mediaSource)
    }

    if (typeof this.campaign !== 'undefined') {
      searchParamsUrl.append('c', this.campaign)
    }
    /* istanbul ignore next: legacy */
    if (webProspectId) {
      searchParamsUrl.append('web_prospect_id', webProspectId)
    }

    Object.entries(this.afParams).forEach(([key, value]) => {
      searchParamsUrl.append(key, value)
    })
    const { oneLinkURL } = this.config
    return `${oneLinkURL}?${searchParamsUrl}`
  }

  setChannel(channelParam: any, channelValue: any = null) {
    setGenericParameter(this.afParams, 'af_channel', channelParam, channelValue)
  }

  setAd(adParam: any, adValue: any = null) {
    setGenericParameter(this.afParams, 'af_ad', adParam, adValue)
  }

  setCustomParameter(searchKey: any, customKey: any, customValue: any = null) {
    setGenericParameter(this.afParams, customKey, searchKey, customValue)
  }
}
function getParameterFromURL(name: any) {
  const utmParams = getUtmParametersFromCookie()
  let urlValue = name
  const url = new URL(window.location.href)
  if (utmParams) {
    for (const [key] of Object.entries(utmParams)) {
      url.searchParams.append(key, utmParams[key])
    }
  }
  urlValue = urlValue.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${urlValue}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url.toString())
  if (!results) return null
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function getMediaSourceValue(
  pidKeysList: string[],
  pidStaticValue: any,
  pidOverrideList: any,
) {
  let pidValue = null
  if (pidKeysList.length > 0) {
    for (const pidKey of pidKeysList) {
      if (pidKey !== null && getParameterFromURL(pidKey)) {
        pidValue = getParameterFromURL(pidKey)
      }
    }
  }

  if (pidValue !== null) {
    if (pidOverrideList.hasOwnProperty(pidValue)) {
      pidValue = pidOverrideList[pidValue]
    }
  } else {
    pidValue = pidStaticValue
  }
  return pidValue
}

function getCampaignValue(campaignKeysList: any, campaignStaticValue: any) {
  for (const campaignKey of campaignKeysList) {
    if (getParameterFromURL(campaignKey)) {
      return getParameterFromURL(campaignKey)
    }
  }

  if (campaignStaticValue) {
    return campaignStaticValue
  }
  return undefined
}

function setGenericParameter(
  afParams: any,
  oneLinkParam: any,
  searchKey: any,
  /* istanbul ignore next: legacy */
  newParamValue = null,
) {
  const appsflyerParameter = afParams
  const searchKeyResult = getParameterFromURL(searchKey)
  if (searchKeyResult) {
    appsflyerParameter[oneLinkParam] = searchKeyResult
  } else if (newParamValue !== null) {
    appsflyerParameter[oneLinkParam] = newParamValue
  }
}
