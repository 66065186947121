/* eslint-disable @typescript-eslint/no-namespace */
/* eslint no-restricted-imports: "off" */
import { v4 as uuid } from 'uuid'

import type {
  ActionEvent,
  AnalyticsConfigProps,
  AnalyticsEnvironment,
  MParticleConfig,
  PageViewEvent,
  PartialScreenEvent,
} from '.'
import { PartialScreenInterfaceType, UserActionInterfaceType } from '.'

const appVersionBuild = uuid()
const apiKey = uuid()
const dataPlanId = uuid()
const webProspectId = uuid()

export namespace ChaseMocks {
  export namespace Analytics {
    export namespace Config {
      export const environment: AnalyticsEnvironment = {
        appVersionBuild,
        environmentName: 'test',
      }

      export const mParticleConfig: MParticleConfig = {
        environment: 'test',
        apiKey,
        dataPlanId,
        dataPlanVersion: 2,
        webProspectId,
      }

      export const props: AnalyticsConfigProps = {
        environment,
        mParticleConfig,
      }

      export const propsOfEnvironment = (
        env: string,
      ): AnalyticsConfigProps => ({
        environment: {
          appVersionBuild,
          environmentName: env,
        },
        mParticleConfig: {
          ...ChaseMocks.Analytics.Config.mParticleConfig,
          environment: env,
        },
      })
    }

    export const actionEvent = (
      interfaceType: UserActionInterfaceType = UserActionInterfaceType.BUTTON,
    ): ActionEvent => ({
      actionName: uuid(),
      interfaceType,
      sectionName: uuid(),
    })

    export const partialScreenEvent = (
      interfaceType: PartialScreenInterfaceType = PartialScreenInterfaceType.POPUP,
    ): PartialScreenEvent => ({
      partialScreenName: uuid(),
      interfaceType,
    })

    export const pageViewEvent = (): PageViewEvent => ({
      pageTitle: uuid(),
    })
  }
}
