'use client'

import { Button, SVG } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import type { HTMLAttributes } from 'react'
import { useEffect, useState } from 'react'

import type { HrefExternals } from '@/types'

import { Link } from '../link'
import { CopyWidget } from './components'

export const getCombinedShareLink = (
  shareMessage: string,
  type: 'facebook' | 'linkedin' | 'copy' | 'x',
): HrefExternals => {
  let link: HrefExternals
  const shareLink = globalThis.document.location.href
  switch (type) {
    case 'facebook': {
      link = `http://www.facebook.com/sharer/sharer.php?u=${shareLink}`
      break
    }
    case 'x': {
      link = `http://www.twitter.com/intent/tweet?text=${shareMessage}&url=${shareLink}`
      break
    }
    case 'linkedin': {
      link = `http://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${shareMessage}`
      break
    }
    case 'copy':
    default: {
      link = shareLink as HrefExternals
      break
    }
  }
  return link
}

export interface ShareWidgetProps extends HTMLAttributes<HTMLUListElement> {
  /**
   * Message included in the sharing link, typically the page title
   */
  shareMessage: string
}

export const ShareWidget = ({
  shareMessage,
  className,
  ...props
}: ShareWidgetProps) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  if (!loaded) {
    return null
  }
  return (
    <ul className={cn('tw-flex tw-gap-4 tw-text-grey40', className)} {...props}>
      <li>
        <Button
          noLinkUnderline
          asChild
          noExternalIcon
          variant="link"
          className="tw-text-grey40 focus-visible:tw-text-blue30"
        >
          <Link
            className="tw-flex"
            notTracked
            href={getCombinedShareLink(shareMessage, 'facebook')}
            target="_blank"
          >
            <SVG name="Facebook" aria-label={`Share on facebook`} />
          </Link>
        </Button>
      </li>
      <li>
        <Button
          noLinkUnderline
          asChild
          noExternalIcon
          variant="link"
          className="tw-text-grey40 focus-visible:tw-text-blue30"
        >
          <Link
            className="tw-flex"
            notTracked
            href={getCombinedShareLink(shareMessage, 'x')}
            target="_blank"
          >
            <SVG name="X" aria-label={`Share on x`} />
          </Link>
        </Button>
      </li>
      <li>
        <Button
          noLinkUnderline
          asChild
          noExternalIcon
          variant="link"
          className="tw-text-grey40 focus-visible:tw-text-blue30"
        >
          <Link
            className="tw-flex"
            notTracked
            href={getCombinedShareLink(shareMessage, 'linkedin')}
            target="_blank"
          >
            <SVG name="LinkedIn" aria-label={`Share on linkedin`} />
          </Link>
        </Button>
      </li>
      <li>
        <CopyWidget shareLink={getCombinedShareLink(shareMessage, 'copy')} />
      </li>
    </ul>
  )
}
