'use client'

import type { Cookie } from './types'

export const getAllCookies = (): any =>
  document.cookie
    .split(';')
    .map((v) => {
      const [, name, value] = v.match(/(.+?)=(.*)/) ?? []
      return [name, value]
    })
    .reduce((acc: any, [name, value]) => {
      if (!name) {
        return acc
      }

      acc[decodeURIComponent(name.trim())] = decodeURIComponent(value.trim())
      return acc
    }, {})

export const getCookie = (name: string): Cookie | undefined => {
  if (typeof window !== 'undefined') {
    const value = getAllCookies()[name]

    return value
      ? {
          name,
          value,
        }
      : undefined
  }
}

export const setCookie = (cookie: Cookie): Cookie => {
  const { name, value, expires, path, sameSite, domain } = cookie
  const expiresParam = expires ? `;expires=${expires}` : ''
  const pathParam = path ? `;path=${path}` : ''
  const sameSiteParam = sameSite ? `;SameSite=${sameSite}` : ''
  const domainParam = domain ? `;domain=${domain}` : ''
  document.cookie = `${name}=${value}${expiresParam}${pathParam}${sameSiteParam}${domainParam}`
  return cookie
}

export const setCookieWithNoExpiry = (cookie: Cookie): Cookie =>
  setCookie({
    path: '/',
    sameSite: 'Lax',
    ...cookie,
    expires: '19 Jan 2038 04:14:07 GMT',
  })

export const deleteCookies = (
  names: string[],
  path?: string,
  domain?: string,
): void => {
  names?.map((name) =>
    setCookie({
      name,
      value: '',
      expires: '01 Jan 1970 00:00:00 GMT',
      path,
      domain,
    }),
  )
}
