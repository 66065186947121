import type { SVGSpriteComponent } from '../types'

const ErrorStatus: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M22.3975 11.4966L17.6475 3.3716C17.5132 3.1416 17.2666 3 17 3H7.50003C7.23343 3 6.98682 3.1416 6.85253 3.3716L2.10253 11.4966C1.96582 11.7305 1.96582 12.0195 2.10253 12.2534L6.85253 20.3784C6.98682 20.6084 7.23343 20.75 7.50003 20.75H17C17.2666 20.75 17.5132 20.6084 17.6475 20.3784L22.3975 12.2534C22.5342 12.0195 22.5342 11.7305 22.3975 11.4966ZM11.5 7.9292C11.5 7.5151 11.8359 7.1792 12.25 7.1792C12.6641 7.1792 13 7.5151 13 7.9292V12.4292C13 12.8438 12.6641 13.1792 12.25 13.1792C11.8359 13.1792 11.5 12.8438 11.5 12.4292V7.9292ZM12.25 16.4204C11.7529 16.4204 11.3501 16.0176 11.3501 15.5205C11.3501 15.0234 11.7529 14.6206 12.25 14.6206C12.7471 14.6206 13.1499 15.0234 13.1499 15.5205C13.1499 16.0176 12.7471 16.4204 12.25 16.4204Z"
        fill="currentColor"
      />
    </symbol>
  )
}

ErrorStatus.displayName = 'SVG.ErrorStatus'
const ErrorStatusConfigProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
}
export { ErrorStatus, ErrorStatusConfigProps }
