import type { SVGSpriteComponent } from '../types'

const Home: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M19 21.7499H5C4.27091 21.7491 3.57191 21.4591 3.05636 20.9435C2.54082 20.428 2.25082 19.729 2.25 18.9999V10.1767C2.25015 9.68631 2.38136 9.20488 2.63003 8.78224C2.87871 8.35959 3.23582 8.01108 3.6644 7.77278L10.6577 3.36528C11.0687 3.12861 11.5339 3.0023 12.0082 2.99862C12.4824 2.99494 12.9496 3.11402 13.3642 3.34428L20.3735 7.79528C20.7931 8.03496 21.1416 8.38167 21.3834 8.80004C21.6253 9.21841 21.7518 9.69345 21.75 10.1767V18.9999C21.7492 19.729 21.4592 20.428 20.9436 20.9435C20.4281 21.4591 19.7291 21.7491 19 21.7499ZM12.0412 4.49418C11.8242 4.49453 11.6109 4.55003 11.4212 4.65548L4.4285 9.06288C4.22538 9.16998 4.05505 9.33007 3.93558 9.52617C3.81612 9.72228 3.75199 9.94707 3.75 10.1767V18.9999C3.75037 19.3313 3.88219 19.649 4.11653 19.8834C4.35087 20.1177 4.66859 20.2495 5 20.2499H19C19.3314 20.2495 19.6491 20.1177 19.8835 19.8834C20.1178 19.649 20.2496 19.3313 20.25 18.9999V10.1767C20.25 9.95378 20.1903 9.73494 20.0773 9.54282C19.9643 9.3507 19.8019 9.19229 19.6071 9.08398L12.5978 4.63298C12.4269 4.54057 12.2355 4.49283 12.0412 4.49418ZM17.75 16.9999C17.75 16.801 17.671 16.6102 17.5303 16.4696C17.3897 16.3289 17.1989 16.2499 17 16.2499H7C6.80109 16.2499 6.61032 16.3289 6.46967 16.4696C6.32902 16.6102 6.25 16.801 6.25 16.9999C6.25 17.1988 6.32902 17.3896 6.46967 17.5302C6.61032 17.6709 6.80109 17.7499 7 17.7499H17C17.1989 17.7499 17.3897 17.6709 17.5303 17.5302C17.671 17.3896 17.75 17.1988 17.75 16.9999Z"
        fill="currentColor"
      />
    </symbol>
  )
}
Home.displayName = 'SVG.Home'
const HomeConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { Home, HomeConfigProps }
