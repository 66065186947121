import { forwardRef } from 'react'
import type { HTMLAttributes } from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'

const Root = forwardRef<
  HTMLElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'section'
  return (
    <Comp
      ref={ref}
      className={cn(
        'tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-8 tw-py-16 md:tw-flex-nowrap md:tw-py-14 lg:tw-py-20',
        className,
      )}
      {...props}
    />
  )
})
Root.displayName = 'Feature.Root'

const ImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      className={cn(
        'tw-w-full tw-overflow-hidden tw-rounded-2xl md:tw-flex-shrink-0 md:tw-basis-1/2 lg:tw-rounded-3xl',
        className,
      )}
      {...props}
      ref={ref}
    />
  )
})
ImageWrapper.displayName = 'Feature.ImageWrapper'

const Content = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      ref={ref}
      className={cn('tw-w-full md:tw-flex-shrink-0 md:tw-basis-2/5', className)}
      {...props}
    />
  )
})
Content.displayName = 'Feature.Content'

export { Root, ImageWrapper, Content }
