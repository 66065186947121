import type { SVGSpriteComponent } from '../types'

const ArrowDown: SVGSpriteComponent = ({
  children,
  role,
  spriteFilePath,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path
        fill="currentColor"
        d="M12 16a.997.997 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L12 13.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A.997.997 0 0112 16z"
      />
    </symbol>
  )
}
ArrowDown.displayName = 'SVG.ArrowDown'
const ArrowDownConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { ArrowDown, ArrowDownConfigProps }
