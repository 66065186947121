import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { NotificationLocalKeyTypesEnum } from './types'

export interface NotificationProps {
  value: boolean
  dismissalDate: number
}

export type NotificationLocalStorageState = {
  getNotificationValue: (
    notificationName: NotificationLocalKeyTypesEnum,
  ) => boolean
  setNotificationValue: (
    notificationName: NotificationLocalKeyTypesEnum,
    value: boolean,
  ) => void
  getNotificationDismissalDate: (
    notificationName: NotificationLocalKeyTypesEnum,
  ) => number
  // just for test case to set date as +14 days
  setNotificationDismissalDate: (
    notificationName: NotificationLocalKeyTypesEnum,
    value: number,
  ) => void
} & { [Key in NotificationLocalKeyTypesEnum]: NotificationProps }

const initialNotificationLocalStorageState = {
  [NotificationLocalKeyTypesEnum.US_REDIRECT]: {
    value: true,
    dismissalDate: Date.now(),
  },
}

const notificationLocalStore = (set: any, get: any) => ({
  ...initialNotificationLocalStorageState,
  getNotificationValue: (notificationName: NotificationLocalKeyTypesEnum) => {
    // Once 14 days have passed post dismissal of notification, reset local store with initial values
    const dismissalTimeStamp = get()[notificationName].dismissalDate
    // Math.abs is done only for test  case purpose as I need to make dismissalDate plus 14 days to current date
    const differenceInDays = Math.round(
      Math.abs(Date.now() - dismissalTimeStamp) / (1000 * 3600 * 24),
    )
    if (dismissalTimeStamp && differenceInDays >= 14) {
      set(() => {
        return {
          [notificationName]: { value: true, dismissalDate: Date.now() },
        }
      })
    }
    return get()[notificationName].value
  },
  getNotificationDismissalDate: (
    notificationName: NotificationLocalKeyTypesEnum,
  ) => {
    return get()[notificationName].dismissalDate
  },
  setNotificationValue: (
    notificationName: NotificationLocalKeyTypesEnum,
    value: boolean,
  ) => {
    set(() => {
      return { [notificationName]: { value, dismissalDate: Date.now() } }
    })
  },
  setNotificationDismissalDate: (
    notificationName: NotificationLocalKeyTypesEnum,
    dismissalDate: number,
  ) => {
    set(() => {
      return { [notificationName]: { dismissalDate } }
    })
  },
})

export const useLocalStorageNotificationStore =
  create<NotificationLocalStorageState>()(
    persist(notificationLocalStore, {
      name: 'us-notification-dismissals',
      storage: createJSONStorage(() => localStorage),
    }),
  )
