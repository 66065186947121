import type { ActionEvent, PageViewEvent, PartialScreenEvent } from '.'

export enum AnalyticsEventType {
  SCREEN = 'Screen',
  PARTIAL_SCREEN = 'Partial Screen Load',
  USER_ACTION = 'User Action',
}

export enum PartialScreenInterfaceType {
  MODAL = 'modal',
  POPUP = 'popup',
  INTERSTITIAL = 'interstitial',
}

export enum UserActionInterfaceType {
  BUTTON = 'button',
  LINK = 'link',
}

export const STRICTLY_NECESSARY_EVENTS: Array<
  Partial<ActionEvent | PageViewEvent | PartialScreenEvent>
> = [
  { actionName: 'acceptAllCookies' },
  { actionName: 'manageCookieConsents' },
  { actionName: 'exitManageCookieConsentDetails' },
  { actionName: 'applyCookieConsents' },
  { partialScreenName: 'manageCookieConsents' },
  { partialScreenName: 'manageCookieConsentDetails' },
  { pageTitle: 'App-Only Error' },
  { pageTitle: 'Error 404' },
  { pageTitle: 'Customer unsubscribe' },
]
