import {
  analytics,
  getTrackingLabel,
  PartialScreenInterfaceType,
  UserActionInterfaceType,
} from '@chaseweb/utils/tracking'

const ANALYTICAL = 'analytical'
const PERFORMANCE = 'performance'
const ADVERTISEMENT = 'advertisement'

import { Button, Heading, Toggle } from '@chaseweb/ui-library/src'
import { useEffect, useState } from 'react'

import { useOnClickTracked, usePageTitle } from '@/lib/hooks'
import { parseContent } from '@/lib/parse-content'
import { useConsentStore } from '@/lib/stores'

const HEADER_CONTENT_KEY =
  'webCookieConsents.manageCookieConsentDetails.manageCookieConsentDetails.header'
const ACCEPT_ALL_CONTENT_KEY =
  'webCookieConsents.manageCookieConsentDetails.acceptAllCookies.label'
const SAVE_SETTINGS_CONTENT_KEY =
  'webCookieConsents.manageCookieConsentDetails.applyCookieConsents.label'

export const ConsentModalStep2 = ({
  content,
}: {
  content: Record<string, string>
}) => {
  const setConsents = useConsentStore(({ setConsents }) => setConsents)
  const consents = useConsentStore(({ consents }) => consents)
  const [analytical, setAnalytical] = useState(consents.Analytical)
  const [performance, setPerformance] = useState(consents.Performance)
  const [advertisingAndTargeting, setAdvertisingAndTargeting] = useState(
    consents.AdvertisingAndTargeting,
  )

  const pageTitle = usePageTitle()
  const getTranslation = (key: string) => parseContent(content[key])

  useEffect(() => {
    if (!pageTitle) return
    // https://react.dev/learn/synchronizing-with-effects#sending-analytics
    analytics.trackPartialScreen({
      partialScreenName: getTrackingLabel({
        contentKey: HEADER_CONTENT_KEY,
        isScreenName: true,
      }),
      interfaceType: PartialScreenInterfaceType.MODAL,
      backgroundPageTitle: pageTitle,
    })
  }, [pageTitle])

  const handleAcceptAll = () => {
    const newConsentSettings = {
      Analytical: true,
      Performance: true,
      AdvertisingAndTargeting: true,
    }
    setConsents(newConsentSettings)
  }

  const handleSaveSettingsClick = () => {
    const newConsentSettings = {
      Analytical: analytical,
      Performance: performance,
      AdvertisingAndTargeting: advertisingAndTargeting,
    }
    setConsents(newConsentSettings)
  }

  const handlePressValueChange =
    (
      set:
        | typeof setAnalytical
        | typeof setPerformance
        | typeof setAdvertisingAndTargeting,
    ) =>
    (val: boolean) => {
      set(val)
    }

  return (
    <section data-testid="Step2">
      <Heading type="h2" className="tw-text-blue30">
        {getTranslation(HEADER_CONTENT_KEY)}
      </Heading>
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-8 tw-divide-y tw-divide-solid tw-divide-grey70 tw-self-stretch">
        <p className="tw-pt-6 tw-font-sans tw-text-sm tw-font-normal">
          {getTranslation(
            'webCookieConsents.manageCookieConsentDetails.manageCookieConsentDetails.message',
          )}
        </p>
        <div className="tw-w-full tw-pt-10">
          <div className="tw-flex tw-items-start tw-justify-between tw-gap-6">
            <Heading type="h4" className="tw-mb-3 tw-font-semibold">
              {getTranslation(
                'webCookieConsents.manageCookieConsentDetails.strictlyNecessary.header',
              )}
            </Heading>
            <span className="tw-pl-4 tw-text-base tw-font-semibold">
              {getTranslation(
                'webCookieConsents.manageCookieConsentDetails.strictlyNecessary.label',
              )}
            </span>
          </div>
          <p className="tw-font-sans tw-text-sm tw-font-normal">
            {getTranslation(
              'webCookieConsents.manageCookieConsentDetails.strictlyNecessary.message',
            )}
          </p>
        </div>
        <div className="tw-pt-10">
          <div className="tw-flex tw-items-start tw-justify-between tw-gap-6">
            <Heading
              type="h4"
              className="tw-mb-3 tw-font-semibold"
              id={`title-${ANALYTICAL}`}
            >
              {getTranslation(
                'webCookieConsents.manageCookieConsentDetails.analytical.header',
              )}
            </Heading>
            <div className="tw-pl-4">
              <Toggle
                id={ANALYTICAL}
                name={ANALYTICAL}
                pressed={analytical}
                onPressedChange={handlePressValueChange(setAnalytical)}
                onLabel={getTranslation(
                  'webCookieConsents.manageCookieConsentDetails.analyticalOption.label.ON',
                )}
                offLabel={getTranslation(
                  'webCookieConsents.manageCookieConsentDetails.analyticalOption.label.OFF',
                )}
                aria-labelledby={`title-${ANALYTICAL}`}
                aria-describedby={`description-${ANALYTICAL}`}
              />
              <label
                htmlFor={ANALYTICAL}
                className="tw-pl-4 tw-text-base tw-font-semibold"
                id="analytical-toggle-label"
              />
            </div>
          </div>
          <p
            className="tw-font-sans tw-text-sm tw-font-normal"
            id={`description-${ANALYTICAL}`}
          >
            {getTranslation(
              'webCookieConsents.manageCookieConsentDetails.analytical.message',
            )}
          </p>
        </div>
        <div className="tw-pt-10">
          <div className="tw-flex tw-items-start tw-justify-between tw-gap-6">
            <Heading
              type="h4"
              className="tw-mb-3 tw-font-semibold"
              id={`title-${PERFORMANCE}`}
            >
              {getTranslation(
                'webCookieConsents.manageCookieConsentDetails.performance.header',
              )}
            </Heading>
            <div className="tw-pl-4">
              <Toggle
                id={PERFORMANCE}
                name={PERFORMANCE}
                pressed={performance}
                onPressedChange={handlePressValueChange(setPerformance)}
                onLabel={getTranslation(
                  'webCookieConsents.manageCookieConsentDetails.performanceOption.label.ON',
                )}
                offLabel={getTranslation(
                  'webCookieConsents.manageCookieConsentDetails.performanceOption.label.OFF',
                )}
                aria-labelledby={`title-${PERFORMANCE}`}
                aria-describedby={`description-${PERFORMANCE}`}
              />
              <label
                htmlFor={PERFORMANCE}
                className="tw-pl-4 tw-text-base tw-font-semibold"
                id="performance-toggle-label"
              />
            </div>
          </div>
          <p
            className="tw-font-sans tw-text-sm tw-font-normal"
            id={`description-${PERFORMANCE}`}
          >
            {getTranslation(
              'webCookieConsents.manageCookieConsentDetails.performance.message',
            )}
          </p>
        </div>
        <div className="tw-pt-10">
          <div className="tw-flex tw-items-start tw-justify-between tw-gap-6">
            <Heading
              type="h4"
              className="tw-mb-3 tw-font-semibold"
              id={`title-${ADVERTISEMENT}`}
            >
              {getTranslation(
                'webCookieConsents.manageCookieConsentDetails.advertising.header',
              )}
            </Heading>
            <div className="tw-pl-4">
              <Toggle
                id={ADVERTISEMENT}
                name={ADVERTISEMENT}
                pressed={advertisingAndTargeting}
                onPressedChange={handlePressValueChange(
                  setAdvertisingAndTargeting,
                )}
                onLabel={getTranslation(
                  'webCookieConsents.manageCookieConsentDetails.advertisingOption.label.ON',
                )}
                offLabel={getTranslation(
                  'webCookieConsents.manageCookieConsentDetails.advertisingOption.label.OFF',
                )}
                aria-labelledby={`title-${ADVERTISEMENT}`}
                aria-describedby={`description-${ADVERTISEMENT}`}
              />
              <label
                className="tw-pl-4 tw-text-base tw-font-semibold"
                htmlFor={ADVERTISEMENT}
                id="advertisement-toggle-label"
              />
            </div>
          </div>
          <p
            className="tw-font-sans tw-text-sm tw-font-normal"
            id={`description-${ADVERTISEMENT}`}
          >
            {getTranslation(
              'webCookieConsents.manageCookieConsentDetails.advertising.message',
            )}
          </p>
        </div>
      </div>
      <div className="tw-mt-10 tw-flex tw-items-start tw-gap-2">
        <Button
          className="tw-w-full md:tw-w-auto"
          onClick={useOnClickTracked(
            {
              actionName: getTrackingLabel({
                contentKey: SAVE_SETTINGS_CONTENT_KEY,
              }),
              sectionName: PartialScreenInterfaceType.MODAL,
              interfaceType: UserActionInterfaceType.BUTTON,
            },
            () => handleSaveSettingsClick(),
          )}
        >
          {getTranslation(SAVE_SETTINGS_CONTENT_KEY)}
        </Button>
        <Button
          className="tw-w-full md:tw-w-auto"
          type="button"
          variant="secondary"
          onClick={useOnClickTracked(
            {
              actionName: getTrackingLabel({
                contentKey: ACCEPT_ALL_CONTENT_KEY,
              }),
              sectionName: PartialScreenInterfaceType.MODAL,
              interfaceType: UserActionInterfaceType.BUTTON,
            },
            () => handleAcceptAll(),
          )}
        >
          {getTranslation(ACCEPT_ALL_CONTENT_KEY)}
        </Button>
      </div>
    </section>
  )
}
