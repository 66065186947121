'use client'

import { getChaseEnv } from '@chaseweb/utils/getChaseEnv'
import type { RuntimeConfiguration } from '@chaseweb/utils/runtime-configuration'
import { useEffect, useState } from 'react'

function isRuntimeConfiguration(
  env: RuntimeConfiguration | Record<string, unknown>,
): env is RuntimeConfiguration {
  return typeof env === 'object' && env.hasOwnProperty('environment')
}

export const useChaseEnv = () => {
  const [env, setEnv] = useState<RuntimeConfiguration | null>(null)
  useEffect(() => {
    const run = async () => {
      const env = await getChaseEnv()
      if (isRuntimeConfiguration(env)) {
        setEnv(env)
      }
    }
    run()
  }, [setEnv])
  return env
}
