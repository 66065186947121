'use client'
import { useEffect, useState } from 'react'
import type { AttributionLinkVariant } from './createAttributionLink'
import { createAttributionLink, getOrigUrl } from './createAttributionLink'

/**
 * @deprecated Use the `useAttributionUrlLink()` instead
 */
export const useAttributionLink = (
  appsflyerLink: string,
  search?: string,
  href?: string,
  variant?: AttributionLinkVariant,
  sitePrefix?: string,
) => {
  const [appDownloadLink, setAppDownloadLink] = useState<string>('')

  useEffect(() => {
    if (appsflyerLink) {
      const origUrl = getOrigUrl(search, href)
      const link = createAttributionLink(
        appsflyerLink,
        variant,
        origUrl,
        sitePrefix,
      )
      setAppDownloadLink(link)
    }
  }, [href, search, appsflyerLink, variant, sitePrefix])

  return appDownloadLink
}

/**
 * @TODO: copy code from useAttributionLink when gatsby is deprecated
 */
export const useAttributionUrlLink = ({
  appsflyerLink,
  search,
  href,
  variant,
  sitePrefix,
}: {
  appsflyerLink: string
  search?: string
  href?: string
  variant?: AttributionLinkVariant
  sitePrefix?: string
}) => {
  return useAttributionLink(
    appsflyerLink,
    search,
    href,
    variant,
    sitePrefix,
  ) as `http${string}`
}
