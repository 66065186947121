import { forwardRef } from 'react'
import type { HTMLAttributes } from 'react'

import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'

const List = forwardRef<HTMLOListElement, HTMLAttributes<HTMLElement>>(
  ({ className, children }, ref) => {
    return (
      <ol
        ref={ref}
        className={cn(
          'ui-Breadcrumb tw-flex tw-items-start tw-gap-3 tw-py-4',
          className,
        )}
      >
        {children}
      </ol>
    )
  },
)
List.displayName = 'Breadcrumb.List'

interface ItemProps extends HTMLAttributes<HTMLLIElement> {
  asChild?: boolean
}

const Item = forwardRef<HTMLLIElement, ItemProps>(
  ({ asChild, className, children }, ref) => {
    const Comp = asChild ? Slot : 'span'
    return (
      <li ref={ref} className={cn('ui-Breadcrumb_Item', className)}>
        <Comp
          className={cn([
            'tw-flex',
            'tw-justify-center',
            'tw-items-center',
            'tw-text-sm',
          ])}
        >
          {children}
        </Comp>
      </li>
    )
  },
)

Item.displayName = 'Breadcrumb.Item'

const Label = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
  ({ className, children }, ref) => {
    return (
      <span
        ref={ref}
        className={cn(
          'tw-shadow-underscore',
          'tw-transition-shadow',
          'tw-duration-200',
          'hover:tw-shadow-[none]',
          className,
        )}
      >
        {children}
      </span>
    )
  },
)
Label.displayName = 'Breadcrumb.Label'

export { List, Item, Label }
