'use client'
import { Modal } from '@chaseweb/ui-library/src'
import { usePathname } from 'next/navigation'

import { useConsentStore } from '@/lib/stores'

import { ConsentModalStep1 } from './consent-modal-step1'
import { ConsentModalStep2 } from './consent-modal-step2'

export function ConsentModal({ content }: { content: Record<string, string> }) {
  const pathname = usePathname()
  const { captured, isModalOpen, currentModalStep, closeModal } =
    useConsentStore()
  const modalOpen = isModalOpen && !pathname.includes('cookie-policy')
  return (
    <Modal.Root open={modalOpen} onOpenChange={closeModal}>
      <Modal.Box
        className="modal"
        variant="bottomAnchored"
        currentStep={currentModalStep}
      >
        {!captured && (
          <Modal.Content>
            <ConsentModalStep1 content={content} />
          </Modal.Content>
        )}
        <Modal.Content>
          <Modal.Close
            // It is moved before step 2 because close button focus's priority is higher than other elements
            className="tw-border-primaryBorder
          tw-text-primaryBorder"
            onClick={closeModal}
            label="Close manage cookies"
          />
          <ConsentModalStep2 content={content} />
        </Modal.Content>
      </Modal.Box>
    </Modal.Root>
  )
}
