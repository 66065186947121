import type { SVGSpriteComponent } from '../types'

const Accessibility: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M3.161 4.469a6.5 6.5 0 0 1 8.84-.328 6.5 6.5 0 0 1 9.178 9.154l-7.765 7.79a2 2 0 0 1-2.719.102l-.11-.101-7.764-7.791a6.5 6.5 0 0 1 .34-8.826zm1.414 1.414a4.5 4.5 0 0 0-.146 6.21l.146.154L12 19.672l5.303-5.304-3.535-3.535-1.06 1.06a3 3 0 1 1-4.244-4.242l2.102-2.103a4.501 4.501 0 0 0-5.837.189l-.154.146zm8.486 2.828a1 1 0 0 1 1.414 0l4.242 4.242.708-.706a4.5 4.5 0 0 0-6.211-6.51l-.153.146-3.182 3.182a1 1 0 0 0-.078 1.327l.078.087a1 1 0 0 0 1.327.078l.087-.078 1.768-1.768z"
      />
    </symbol>
  )
}

Accessibility.displayName = 'SVG.Accessibility'
const AccessibilityConfigProps = { viewBox: '0 0 24 24', width: '24px' }
export { Accessibility, AccessibilityConfigProps }
