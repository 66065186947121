import type { SVGSpriteComponent } from '../types'

const InformationStatus: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM12 7.4658C12.4971 7.4658 12.8999 7.8686 12.8999 8.3657C12.8999 8.8628 12.4971 9.2661 12 9.2661C11.5029 9.2661 11.1001 8.8628 11.1001 8.3657C11.1001 7.8686 11.5029 7.4658 12 7.4658ZM13.7002 16.75H10.2998C9.8857 16.75 9.5498 16.4141 9.5498 16C9.5498 15.5859 9.8857 15.25 10.2998 15.25H11.25V12.2402H11C10.5859 12.2402 10.25 11.9043 10.25 11.4902C10.25 11.0756 10.5859 10.7402 11 10.7402H12C12.2007 10.7402 12.3926 10.8203 12.5337 10.9629C12.6748 11.1055 12.7525 11.2988 12.75 11.4995V15.25H13.7002C14.1143 15.25 14.4502 15.5859 14.4502 16C14.4502 16.4141 14.1143 16.75 13.7002 16.75Z"
        fill="currentColor"
      />
    </symbol>
  )
}

InformationStatus.displayName = 'SVG.InformationStatus'
const InformationStatusConfigProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
}
export { InformationStatus, InformationStatusConfigProps }
