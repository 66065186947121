'use client'

import { useState, useEffect, forwardRef } from 'react'
import type { ElementRef, ComponentPropsWithoutRef } from 'react'
import { Button } from '../button'
import { SVG } from '../svg'
import { cn } from '@chaseweb/utils/cn'

export const ScrollTopButton = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, children, ...props }, ref) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  useEffect(() => {
    let showScrollButton = false
    const isScrollToTopVisible = (): boolean => {
      return (
        typeof window !== 'undefined' && window.scrollY > window.innerHeight
      )
    }
    const checkScrollTop = () => {
      const callback = () => {
        const scrollVisible = isScrollToTopVisible()
        if (scrollVisible !== showScrollButton) {
          showScrollButton = scrollVisible
          setHasLoaded(true)
          setIsVisible(showScrollButton)
        }
      }
      window.requestAnimationFrame(callback)
    }

    window.addEventListener('scroll', checkScrollTop, false)
    checkScrollTop()
    return () => {
      window.removeEventListener('scroll', checkScrollTop, false)
    }
  }, [])

  const getFirstInteractiveElement = () => {
    // eslint-disable-next-line valid-typeof
    if (typeof document !== 'undefined') {
      return document.querySelector(
        'main a[href]:not([disabled]), main button:not([disabled]), main textarea:not([disabled]), main input[type="text"]:not([disabled]), main input[type="radio"]:not([disabled]), main input[type="checkbox"]:not([disabled]), main select:not([disabled])',
      ) as HTMLDivElement
    }
  }

  const firstInteractiveElement = getFirstInteractiveElement()

  const scrollTop = () => {
    if (firstInteractiveElement) {
      firstInteractiveElement?.focus({ preventScroll: true })
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Button
      ref={ref}
      variant="secondary"
      size="sm"
      data-testid="scrollTopButton"
      className={cn(
        'tw-fixed tw-bottom-4 tw-right-0 tw-z-50 tw-mr-4 tw-h-14 tw-w-14 tw-rounded-full tw-border-secondaryBorder tw-bg-white tw-p-0 tw-opacity-0 tw-duration-250 md:tw-bottom-6 md:tw-mr-6 lg:tw-mr-16',
        {
          'tw-animate-fadeIn tw-opacity-100': isVisible,
          'tw-animate-scrollTopFadeOut': hasLoaded && !isVisible,
        },
      )}
      tabIndex={isVisible ? 0 : -1}
      aria-hidden={!isVisible}
      onClick={scrollTop}
      aria-label="Scroll to the top of the page"
      {...props}
    >
      {children}
      <SVG name="ArrowDown" className="tw-h-6 tw-w-6 tw-rotate-180" />
    </Button>
  )
})

ScrollTopButton.displayName = 'ScrollTopButton'
