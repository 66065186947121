import { useEffect, useState } from 'react'
import type { MarketingPixelFragmentProps } from './types'
import { MarketingPixel, MarketingPixelId } from './components'

export const DEFAULT_PIXELS: MarketingPixelId[] = [
  MarketingPixelId.GOOGLE_ADS,
  MarketingPixelId.FACEBOOK,
  MarketingPixelId.GOOGLE_DCM,
  MarketingPixelId.TWITTER,
  MarketingPixelId.TWITTER_SHORTLINK,
  MarketingPixelId.YOUTUBE_PUBLIC,
  MarketingPixelId.YOUTUBE_IN_HOUSE,
  MarketingPixelId.YOUTUBE_MEDIACOM,
]

export const MarketingPixelsFragment = ({
  marketingPixels = [],
  appendDefaults = true,
  hasAdTargetingConsent = false,
}: MarketingPixelFragmentProps) => {
  const [firstRender, setFirstRender] = useState(true)

  const pixels = appendDefaults
    ? [...DEFAULT_PIXELS, ...marketingPixels]
    : marketingPixels

  useEffect(() => {
    setFirstRender(false)
  }, [])

  if (firstRender || !hasAdTargetingConsent) return null

  return (
    <>
      {pixels.map((type) => (
        <MarketingPixel type={type} key={type} />
      ))}
    </>
  )
}
