'use client'

import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { cn } from '@chaseweb/utils/cn'
import * as Select from '@radix-ui/react-select'
import { SVG } from '../../atoms/svg'

const preventDefaultCb = (event: any) => {
  event.preventDefault()
}

const Root = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Select.Root> & HTMLAttributes<HTMLDivElement>
>(
  (
    {
      onOpenChange,
      onValueChange,
      defaultOpen,
      defaultValue,
      value,
      name,
      disabled,
      required,
      children,
      className,
      open,
      ...props
    },
    ref,
  ) => {
    const handleOnOpenChange = (open: boolean) => {
      setTimeout(() => {
        document.body.style.removeProperty('pointer-events')
        document.body.style.setProperty('overflow', 'auto')
      })
      if (onOpenChange) {
        onOpenChange(open)
      }
    }
    return (
      <Select.Root
        onOpenChange={handleOnOpenChange}
        onValueChange={onValueChange}
        defaultOpen={defaultOpen}
        defaultValue={defaultValue}
        value={value}
        name={name}
        disabled={disabled}
        required={required}
        open={open}
      >
        <div
          ref={ref}
          className={cn('tw-flex tw-flex-col', className)}
          {...props}
        >
          {children}
        </div>
      </Select.Root>
    )
  },
)
Root.displayName = 'Select.Root'

const Trigger = forwardRef<
  ElementRef<typeof Select.Trigger>,
  ComponentPropsWithoutRef<typeof Select.Trigger>
>(({ className, ...props }, ref) => {
  return (
    <Select.Trigger
      ref={ref}
      className={cn(
        'tw-flex tw-max-h-[48px] tw-min-h-[48px] tw-items-center tw-gap-2 tw-rounded-lg tw-border tw-border-grey50 tw-px-4 tw-py-3 data-[state=open]:tw-cursor-pointer data-[state=open]:tw-border-blue30',
        'hover:tw-border-blue30',
        'focus-visible:tw-outline-offset-1 focus-visible:tw-outline-blue60',
        'disabled:tw-border-grey90 disabled:tw-bg-grey90 disabled:tw-text-grey40',
        className,
      )}
      {...props}
    />
  )
})
Trigger.displayName = 'Select.Trigger'

const Content = forwardRef<
  ElementRef<typeof Select.Content>,
  ComponentPropsWithoutRef<typeof Select.Content>
>(({ className, position = 'popper', ...props }, ref) => {
  return (
    <Select.Content
      ref={ref}
      onCloseAutoFocus={preventDefaultCb}
      style={{
        minWidth: 'var(--radix-select-trigger-width)',
        maxHeight: 'var(--radix-select-content-available-height)',
      }}
      position={position}
      className={cn(
        'tw-min-w-full tw-translate-y-2 tw-overflow-hidden tw-rounded-lg tw-border tw-border-grey50 tw-bg-white',
        'data-[state=open]:tw-animate-slideFadeInFromTop',
        className,
      )}
      {...props}
    />
  )
})
Content.displayName = 'Select.Content'

const Item = forwardRef<
  ElementRef<typeof Select.Item>,
  ComponentPropsWithoutRef<typeof Select.Item>
>(({ children, className, ...props }, ref) => {
  return (
    <Select.Item
      ref={ref}
      className={cn(
        'tw-relative tw-flex tw-cursor-pointer tw-justify-between tw-rounded-none tw-border-2 tw-border-transparent tw-px-4 tw-py-3 tw-pr-12 first:tw-rounded-t-md last:tw-rounded-b-md',
        'hover:tw-border-transparent hover:tw-bg-grey90 focus-visible:tw-bg-grey90',
        'focus-visible:tw-outline-blue60',
        'aria-[disabled]:tw-bg-grey90 aria-[disabled]:tw-text-grey40',
        className,
      )}
      {...props}
    >
      {children}
    </Select.Item>
  )
})
Item.displayName = 'Select.Item'

const ItemText = Select.ItemText
ItemText.displayName = 'Select.ItemText'

const ItemIndicator = forwardRef<
  ElementRef<typeof Select.ItemIndicator>,
  ComponentPropsWithoutRef<typeof Select.ItemIndicator>
>(({ children, className, ...props }, ref) => {
  return (
    <Select.ItemIndicator
      ref={ref}
      className={cn('tw-absolute tw-right-4 tw-text-blue30', className)}
      {...props}
    >
      {children ?? <SVG name="Tick" />}
    </Select.ItemIndicator>
  )
})
ItemIndicator.displayName = 'Select.ItemIndicator'

const Value = forwardRef<
  ElementRef<typeof Select.Value>,
  ComponentPropsWithoutRef<typeof Select.Value>
>(({ className, ...props }, ref) => {
  return (
    <div className={cn('tw-w-full tw-text-left', className)}>
      <Select.Value ref={ref} {...props} />
    </div>
  )
})
Value.displayName = 'Select.Value'

const SelectIcon = forwardRef<
  ElementRef<typeof Select.Icon>,
  ComponentPropsWithoutRef<typeof Select.Icon>
>(({ children, className, ...props }, ref) => {
  return (
    <Select.Icon ref={ref} {...props} className={cn(className)}>
      {children ?? <SVG name="ArrowDown" />}
    </Select.Icon>
  )
})
SelectIcon.displayName = 'Select.SelectIcon'

const ScrollUpButton = forwardRef<
  ElementRef<typeof Select.ScrollUpButton>,
  ComponentPropsWithoutRef<typeof Select.ScrollUpButton>
>(({ children, className, ...props }, ref) => {
  return (
    <Select.ScrollUpButton
      ref={ref}
      className={cn(
        'tw-flex tw-w-full tw-justify-center tw-border-b tw-border-b-grey90 hover:tw-bg-grey90',
        className,
      )}
      {...props}
    >
      {children ?? <SVG name="ArrowDown" className="tw-rotate-180" />}
    </Select.ScrollUpButton>
  )
})
ScrollUpButton.displayName = 'Select.ScrollUpButton'

const ScrollDownButton = forwardRef<
  ElementRef<typeof Select.ScrollDownButton>,
  ComponentPropsWithoutRef<typeof Select.ScrollDownButton>
>(({ children, className, ...props }, ref) => {
  return (
    <Select.ScrollDownButton
      ref={ref}
      className={cn(
        'tw-flex tw-w-full tw-justify-center tw-border-t tw-border-t-grey90 hover:tw-bg-grey90',
        className,
      )}
      {...props}
    >
      {children ?? <SVG name="ArrowDown" />}
    </Select.ScrollDownButton>
  )
})
ScrollDownButton.displayName = 'Select.ScrollDownButton'

const Viewport = Select.Viewport
Viewport.displayName = 'Select.Viewport'

const Group = forwardRef<
  ElementRef<typeof Select.Group>,
  ComponentPropsWithoutRef<typeof Select.Group>
>(({ className, ...props }, ref) => {
  return (
    <Select.Group
      className={cn('tw-flex tw-flex-col', className)}
      ref={ref}
      {...props}
    />
  )
})
Group.displayName = 'Select.Group'

const GroupTitle = forwardRef<
  ElementRef<typeof Select.Label>,
  ComponentPropsWithoutRef<typeof Select.Label>
>(({ className, ...props }, ref) => {
  return (
    <Select.Label
      className={cn(
        ' tw-border-t tw-bg-grey90 tw-px-4 tw-py-3 tw-font-semibold tw-text-grey30',
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})
GroupTitle.displayName = 'Select.GroupTitle'

const Separator = Select.Separator
Separator.displayName = 'Select.Separator'

const Label = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn('tw-mb-1 tw-mt-8 tw-font-semibold md:tw-mt-0', className)}
        ref={ref}
        {...props}
      />
    )
  },
)
Label.displayName = 'Select.Label'

export {
  Root,
  Trigger,
  Value,
  SelectIcon,
  Content,
  ScrollUpButton,
  ScrollDownButton,
  Viewport,
  Group,
  GroupTitle,
  Item,
  ItemIndicator,
  ItemText,
  Separator,
  Label,
}
