import { Prose } from '@chaseweb/ui-library/src'
import { Slot } from '@radix-ui/react-slot'
import { forwardRef, type HTMLAttributes } from 'react'

import { parseContent, type ParseContentOtionsType } from '@/lib/parse-content'
import type { RichTextType } from '@/types'

type RichTextProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  asChild?: boolean
  as?: string
  children: RichTextType | undefined
  parseOptions?: ParseContentOtionsType
} & Partial<ParseContentOtionsType>

const RichText = forwardRef<HTMLDivElement, RichTextProps>(
  (
    {
      children,
      asChild,
      as,
      parseOptions,
      replaceh4h6TagsWithSmallGreyText,
      useH1PageTitle,
      ...restProps
    },
    ref,
  ) => {
    if (!children || !children.__html__) return null
    const Comp = asChild ? Slot : as ?? Prose
    return (
      <Comp ref={ref} {...restProps}>
        {parseContent(
          children.__html__,
          parseOptions ?? {
            replaceh4h6TagsWithSmallGreyText,
            useH1PageTitle,
          },
        )}
      </Comp>
    )
  },
)
RichText.displayName = 'RichText'

export { RichText }
