import type { SVGSpriteComponent } from '../types'

const ArrowRight: SVGSpriteComponent = ({
  children,
  role,
  spriteFilePath,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path
        fill="currentColor"
        d="M16 12a.997.997 0 00-.293-.707l-5-5a1 1 0 00-1.414 1.414L13.586 12l-4.293 4.293a1 1 0 001.414 1.414l5-5A.997.997 0 0016 12z"
      />
    </symbol>
  )
}
ArrowRight.displayName = 'SVG.ArrowRight'
const ArrowRightConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { ArrowRight, ArrowRightConfigProps }
