import type { SVGSpriteComponent } from '../types'

const ArrowLeft: SVGSpriteComponent = ({
  children,
  role,
  spriteFilePath,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path
        fill="currentColor"
        d="M8 12a.997.997 0 01.293-.707l5-5a1 1 0 011.414 1.414L10.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5A.997.997 0 018 12z"
      />
    </symbol>
  )
}
ArrowLeft.displayName = 'SVG.ArrowLeft'
const ArrowLeftConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { ArrowLeft, ArrowLeftConfigProps }
