'use client'
import type { CanvasHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { QRCode as QrCode } from 'react-qrcode-logo'

export interface QRCodeProps extends CanvasHTMLAttributes<QrCode> {
  value?: string
}

const QRCode = forwardRef<QrCode, QRCodeProps>(({ value, ...props }, ref) => {
  return value ? (
    <QrCode
      size={330}
      eyeRadius={{
        inner: 4,
        outer: 12,
      }}
      ref={ref}
      qrStyle="dots"
      value={value}
      {...props}
    />
  ) : null
})

QRCode.displayName = 'QRCode'

export { QRCode }
