import { create } from 'zustand'
import { useSessionStorageNotificationStore } from './useSessionStorageNotificationStore'
import type { NotificationLocalKeyTypesEnum } from './types'
import { NotificationSessionKeyTypesEnum } from './types'
import { useLocalStorageNotificationStore } from './useLocalStorageNotificationStore'

export interface NotificationStorageState {
  getNotificationValue: (
    notificationName:
      | NotificationSessionKeyTypesEnum
      | NotificationLocalKeyTypesEnum,
  ) => boolean
  setNotificationVisible: (
    notificationName:
      | NotificationSessionKeyTypesEnum
      | NotificationLocalKeyTypesEnum,
    value: boolean,
  ) => void
}

export const useNotificationStore = create<NotificationStorageState>(() => {
  const sessionState = useSessionStorageNotificationStore.getState()
  const localState = useLocalStorageNotificationStore.getState()

  return {
    getNotificationValue: (
      notificationName:
        | NotificationSessionKeyTypesEnum
        | NotificationLocalKeyTypesEnum,
    ) => {
      if (
        Object.values<string>(NotificationSessionKeyTypesEnum).includes(
          notificationName,
        )
      ) {
        // Session Storage
        return sessionState.getNotificationValue(
          notificationName as NotificationSessionKeyTypesEnum,
        )
      } else {
        // Local Storage
        return localState.getNotificationValue(
          notificationName as NotificationLocalKeyTypesEnum,
        )
      }
    },

    setNotificationVisible: (
      notificationName:
        | NotificationSessionKeyTypesEnum
        | NotificationLocalKeyTypesEnum,
      value: boolean,
    ) => {
      if (
        Object.values<string>(NotificationSessionKeyTypesEnum).includes(
          notificationName,
        )
      ) {
        sessionState.setNotificationValue(
          notificationName as NotificationSessionKeyTypesEnum,
          value,
        )
      } else {
        localState.setNotificationValue(
          notificationName as NotificationLocalKeyTypesEnum,
          value,
        )
      }
    },
  }
})
