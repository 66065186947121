import type { SVGSpriteComponent } from '../types'

const QuickAccount: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M12 5.31201C6.23997 5.31201 1.56897 7.85901 1.56897 11C1.56897 14.141 6.23997 16.691 12 16.691C17.76 16.691 22.433 14.145 22.433 11C22.433 7.85501 17.762 5.31201 12 5.31201ZM12 13L8.25397 10.472L12 8.07701L15.746 10.472L12 13Z"
        fill="#005EB8"
      />
      <path
        d="M12 18.5879C6.23899 18.5879 1.56799 16.0409 1.56799 12.8979V14.7979L1.57299 14.9659C1.73599 18.0279 6.34199 20.485 12 20.485C17.761 20.485 22.432 17.9379 22.432 14.7949V12.8999C22.433 16.0409 17.762 18.5879 12 18.5879Z"
        fill="#80BBF6"
      />
    </symbol>
  )
}
QuickAccount.displayName = 'SVG.QuickAccount'
const QuickAccountConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { QuickAccount, QuickAccountConfigProps }
