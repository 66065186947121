import type { SVGSpriteComponent } from '../types'

const Twitter: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M22.191 5.68a8.384 8.384 0 01-2.402.659 4.196 4.196 0 001.84-2.314 8.358 8.358 0 01-2.656 1.015 4.182 4.182 0 00-7.126 3.814 11.874 11.874 0 01-8.62-4.37 4.188 4.188 0 001.294 5.584 4.168 4.168 0 01-1.894-.523v.052a4.185 4.185 0 003.355 4.1 4.21 4.21 0 01-1.89.073 4.185 4.185 0 003.907 2.905 8.394 8.394 0 01-6.191 1.732 11.83 11.83 0 006.41 1.88 11.819 11.819 0 0011.9-11.9c0-.18-.005-.362-.013-.54a8.496 8.496 0 002.087-2.165z"
        fill="currentColor"
      />
    </symbol>
  )
}
Twitter.displayName = 'SVG.Twitter'
const TwitterConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { Twitter, TwitterConfigProps }
