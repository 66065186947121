import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Button } from '../../atoms/button'
import { Heading } from '../../atoms/heading'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-group/cardroot',
        'tw-grid tw-grid-cols-1 ',
        'group-[&]/narrative:tw-gap-6 md:max-lg:group-data-[striped=true]/narrative:tw-grid-cols-2',
        'group-[&]/product:tw-flex group-[&]/product:tw-flex-col group-[&]/product:tw-gap-6 group-[&]/product:tw-self-start',
        'group-[&]/product:tw-rounded-2xl group-[&]/product:tw-border group-[&]/product:tw-border-grey70 group-[&]/product:tw-p-6',
        'group-[&]/product:tw-h-full group-[&]/product:tw-bg-white group-[&]/product:lg:tw-rounded-3xl group-[&]/product:lg:tw-p-8',
        'group-[&]/instruction:tw-grid-rows-[auto_1fr] group-[&]/instruction:tw-gap-6',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  ),
)
Root.displayName = 'Card.Root'

const ImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    classNameWrapper?: string
  }
>(({ className, classNameWrapper, ...props }, ref) => {
  return (
    <div
      className={cn(
        'tw-flex tw-items-center tw-overflow-hidden [&_*]:tw-object-cover [&_*]:tw-object-center',
        'group-[&]/narrative:tw-rounded-2xl lg:group-[&]/narrative:tw-rounded-3xl group-[&]/instruction:[&_*]:tw-min-w-full',
        'md:max-lg:group-data-[striped=true]/narrative:group-even/cardroot:tw-order-1 group-[&]/narrative:[&_*]:tw-min-w-full',
        'group-[&]/product:tw-h-20 group-[&]/product:tw-w-20 group-[&]/product:tw-items-center',
        'group-[&]/product:tw-shrink-0 group-[&]/product:lg:tw-h-32 group-[&]/product:lg:tw-w-32',
        classNameWrapper,
      )}
      {...props}
      ref={ref}
    />
  )
})
ImageWrapper.displayName = 'Card.ImageWrapper'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-w-full',
        'group-[&]/product:tw-flex group-[&]/product:tw-h-full group-[&]/product:tw-flex-col group-[&]/product:tw-items-start',
        className,
      )}
      {...props}
    />
  ),
)
Content.displayName = 'Card.Content'

const Label = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-mb-2 tw-text-sm tw-font-semibold tw-text-orange40',
        className,
      )}
      {...props}
    />
  ),
)
Label.displayName = 'Card.Label'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type = 'h3', ...props }, ref) => (
  <Heading
    ref={ref}
    type={type}
    className={cn(
      'group-[&]/instruction:tw-m-0',
      'group-[&]/product:tw-m-0',
      className,
    )}
    {...props}
  />
))
Title.displayName = 'Card.Title'

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('tw-mt-4 group-[&]/product:tw-mb-4', className)}
    {...props}
  />
))
Description.displayName = 'Card.Description'

const Disclaimer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-mt-2 tw-text-sm tw-text-grey40 group-[&]/product:tw-mt-0',
        className,
      )}
      {...props}
    />
  ),
)
Disclaimer.displayName = 'Card.Disclaimer'

const Cta = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, variant = 'link', ...props }, ref) => (
  <Button
    ref={ref}
    variant={variant}
    className={cn(
      'tw-mt-4 tw-text-center tw-font-semibold group-[&]/product:tw-mt-0 group-[&]/product:tw-w-max group-[&]/product:tw-max-w-full',
      className,
    )}
    {...props}
  />
))
Cta.displayName = 'Card.Cta'

export {
  Root,
  ImageWrapper,
  Label,
  Content,
  Title,
  Description,
  Disclaimer,
  Cta,
}
