import { Button, Heading } from '@chaseweb/ui-library/src'
import {
  analytics,
  getTrackingLabel,
  PartialScreenInterfaceType,
  UserActionInterfaceType,
} from '@chaseweb/utils/tracking'
import { useEffect } from 'react'

import { useOnClickTracked, usePageTitle } from '@/lib/hooks'
import { parseContent } from '@/lib/parse-content'
import { useConsentStore } from '@/lib/stores'

const HEADER_CONTENT_KEY =
  'webCookieConsents.manageCookieConsents.manageCookieConsents.header'
const ACCEPT_ALL_CONTENT_KEY =
  'webCookieConsents.manageCookieConsents.acceptAllCookies.label'
const MANAGE_SETTINGS_CONTENT_KEY =
  'webCookieConsents.manageCookieConsents.manageCookieConsents.label'

export const ConsentModalStep1 = ({
  content,
}: {
  content: Record<string, string>
}) => {
  const setCurrentStep = useConsentStore(({ setCurrentStep }) => setCurrentStep)
  const setConsents = useConsentStore(({ setConsents }) => setConsents)

  const pageTitle = usePageTitle()
  const getTranslation = (key: string) => parseContent(content[key])

  const onAccept = () => {
    setConsents({
      AdvertisingAndTargeting: true,
      Performance: true,
      Analytical: true,
    })
  }

  useEffect(() => {
    if (!pageTitle) return
    // https://react.dev/learn/synchronizing-with-effects#sending-analytics
    analytics.trackPartialScreen({
      partialScreenName: getTrackingLabel({
        contentKey: HEADER_CONTENT_KEY,
        isScreenName: true,
      }),
      interfaceType: PartialScreenInterfaceType.MODAL,
      backgroundPageTitle: pageTitle,
    })
  }, [pageTitle])

  return (
    <section data-testid="Step1">
      <Heading type="h2" className="tw-text-blue30">
        {getTranslation(HEADER_CONTENT_KEY)}
      </Heading>
      <p
        className="tw-pt-4 tw-font-sans tw-text-base"
        aria-label="What are cookies?"
      >
        {getTranslation(
          'webCookieConsents.manageCookieConsents.manageCookieConsents.message',
        )}
      </p>
      <div className="tw-mt-6 tw-flex tw-flex-wrap tw-items-start tw-gap-2">
        <Button
          className="tw-flex-grow"
          onClick={useOnClickTracked(
            {
              actionName: getTrackingLabel({
                contentKey: ACCEPT_ALL_CONTENT_KEY,
              }),
              sectionName: PartialScreenInterfaceType.MODAL,
              interfaceType: UserActionInterfaceType.BUTTON,
            },
            () => onAccept(),
          )}
        >
          {getTranslation(ACCEPT_ALL_CONTENT_KEY)}
        </Button>
        <Button
          className="tw-flex-grow"
          variant="secondary"
          onClick={useOnClickTracked(
            {
              actionName: getTrackingLabel({
                contentKey: MANAGE_SETTINGS_CONTENT_KEY,
              }),
              sectionName: PartialScreenInterfaceType.MODAL,
              interfaceType: UserActionInterfaceType.BUTTON,
            },
            () => setCurrentStep(1),
          )}
        >
          {getTranslation(MANAGE_SETTINGS_CONTENT_KEY)}
        </Button>
      </div>
    </section>
  )
}
