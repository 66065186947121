import { cn } from '@chaseweb/utils/cn'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

const Callout = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'tw-relative tw-bg-blue90',
          'tw-py-4 tw-pl-5 tw-pr-6',
          'md:tw-py-6 md:tw-pl-7 md:tw-pr-8',
          'last:[&_p]:tw-mb-0',
          'before:tw-absolute before:tw-left-0 before:tw-top-0 before:tw-h-full before:tw-w-1 before:tw-bg-blue30',
          '[&_h1]:tw-font-bold [&_h2]:tw-font-bold [&_h3]:tw-font-bold [&_h4]:tw-font-bold [&_h5]:tw-font-bold [&_h6]:tw-font-bold',
          '[&_h1]:tw-text-base [&_h2]:tw-text-base [&_h3]:tw-text-base [&_h4]:tw-text-base [&_h5]:tw-text-base [&_h6]:tw-text-base',
          className,
        )}
      >
        {children}
      </div>
    )
  },
)
Callout.displayName = 'Callout'

export { Callout }
