import type { HeadingType } from '@chaseweb/ui-library/src'
import { Heading } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import type {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  Text,
} from 'html-react-parser'
import parseHtml, { domToReact } from 'html-react-parser'

import { DocumentDetails, PageAnchor, StyledLink } from '@/components'
import type { RelativePathOrHrefExternalType } from '@/types'

import { InlineFeatureFlag } from './components/inline-feature-flag/inline-feature-flag'

export const isElement = (a?: DOMNode): a is Element => a?.nodeType === 1
export const isText = (a?: DOMNode): a is Text => a?.nodeType === 3
const extractText = (
  element: string | React.JSX.Element[] | React.JSX.Element | undefined,
): string => {
  if (typeof element == 'string') return element
  if (Array.isArray(element)) {
    return element
      .map((item: React.JSX.Element | string) =>
        typeof item === 'string' ? item : extractText(item.props.children),
      )
      .join('')
  }
  return ''
}

type ReplaceMapType = {
  replaceh4h6TagsWithSmallGreyText?: boolean
  useH1PageTitle?: boolean
}
export type StringOrElementType =
  | string
  | React.JSX.Element
  | React.JSX.Element[]

export type ParseContentOtionsType = HTMLReactParserOptions & ReplaceMapType

export const parseContent = (
  content: string,
  options?: ParseContentOtionsType,
): StringOrElementType => {
  const parseOptions = {
    replace: options?.replace ?? defaultContentReplace(options),
    trim: options?.trim ?? true,
  }
  return parseHtml(content, parseOptions)
}

export const defaultContentReplace = (
  replaceMap: ReplaceMapType = {},
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { replaceh4h6TagsWithSmallGreyText, useH1PageTitle } = {
    replaceh4h6TagsWithSmallGreyText: false,
    ...replaceMap,
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return function defaultReplace(domNode: DOMNode): JSX.Element | undefined {
    if (isText(domNode)) {
      return domNode.data as unknown as JSX.Element
    }
    if (isElement(domNode)) {
      if (domNode.name === 'a') {
        const children = domNode.children
        const target =
          domNode.attribs.target === '_blank' ? '_blank' : undefined
        const href = domNode.attribs.href as string | undefined
        if (!href) return

        const lastPathSegment = href.split('/').at(-1) as string
        let linkHref = href
        if (
          href.startsWith('/') &&
          !/\.\S{1,4}$/.test(lastPathSegment) &&
          !href.endsWith('/')
        ) {
          linkHref = `${linkHref}/`
        }

        let text = extractText(domToReact(children))
        if (text === 'Download as PDF') {
          return (
            <DocumentDetails
              data={{
                downloadLabel: text,
                versionDetails: 'PDF Version',
                pdfUrl: linkHref,
              }}
            />
          )
        }

        const options = {
          asButton: false,
          mobileOnly: false,
        }
        if (text.includes('{button}')) {
          text = text.replace('{button}', '')
          options.asButton = true
        }
        if (text.includes('{mobile}')) {
          text = text.replace('{mobile}', '')
          options.mobileOnly = true
        }

        return (
          <StyledLink
            variant={options.asButton ? 'primary' : 'link'}
            key={linkHref}
            trackingActionLabel={text}
            sectionLabel="BodyText"
            invertLinkUnderline
            href={linkHref as RelativePathOrHrefExternalType}
            target={target}
            className={cn({
              'md:tw-hidden': options.mobileOnly,
            })}
          >
            {text}
          </StyledLink>
        )
      }

      // This hacky solution and its specific state should only be applied to the components used by the product pages
      // where h4/h6 tags should be displayed as small grey text
      // This has been agreed with the AEM/Content teams
      // https://jira.dynamo.prd.aws.jpmchase.net/browse/DYN-528272
      if (
        replaceh4h6TagsWithSmallGreyText &&
        ['h4', 'h6'].includes(domNode.name)
      ) {
        const children = domNode.children
        return (
          <p className="tw-text-sm tw-text-grey40">
            {/* Need to recursively loop over children as may contain nested links */}
            {Array.from(children).map(defaultReplace)}
          </p>
        )
      }
      if (['h1', 'h2', 'h3', 'h4', 'h5'].includes(domNode.name)) {
        const children = domNode.children

        const id = extractText(domToReact(children))

        return (
          <PageAnchor idString={id} asChild>
            <Heading
              type={domNode.name as HeadingType}
              context={
                useH1PageTitle && domNode.name === 'h1'
                  ? 'pageTitle'
                  : undefined
              }
            >
              {domToReact(children)}
            </Heading>
          </PageAnchor>
        )
      }
      if (['h6'].includes(domNode.name)) {
        throw new Error('h6 is not in the Design System')
      }
      if (['sub'].includes(domNode.name)) {
        const children = domNode.children
        return (
          <p className="!tw-mt-2 tw-text-sm tw-leading-[21px] tw-text-grey40">
            {Array.from(children).map(defaultReplace)}
          </p>
        )
      }
      if (['inline-feature-flag'].includes(domNode.name)) {
        const flagNameAttrib = domNode.attribs['flagName']
        const controlValue = extractText(domToReact(domNode.children))
        return (
          <InlineFeatureFlag flagName={flagNameAttrib}>
            {controlValue}
          </InlineFeatureFlag>
        )
      }
    }
  }
}
