'use client'

import { createContext, useContext, useMemo } from 'react'
import type { ReactNode, FC, Dispatch, SetStateAction } from 'react'

const NotImplementedErrorMsg = 'Function not implemented'

export const TabsContext = createContext<{
  tabSelected: { label?: string; path?: string }
  isMobile: boolean
  defaultValue: string | undefined
  isTopMenuOpen: boolean
  setIsTopMenuOpen: Dispatch<SetStateAction<boolean>>
  setTabSelected: Dispatch<SetStateAction<{ label?: string; path?: string }>>
  onValueChange: ((value: string) => void) | undefined
}>({
  tabSelected: { label: '', path: '' },
  isMobile: false,
  defaultValue: '',
  isTopMenuOpen: false,
  setIsTopMenuOpen: (): void => {
    throw new Error(NotImplementedErrorMsg)
  },
  setTabSelected: (): void => {
    throw new Error(NotImplementedErrorMsg)
  },
  onValueChange: (): void => {
    throw new Error(NotImplementedErrorMsg)
  },
})

export const useTabsContext = () => useContext(TabsContext)

export const TabsContextProvider: FC<{
  tabSelected: { label?: string; path?: string }
  isMobile: boolean
  isTopMenuOpen: boolean
  defaultValue: string | undefined
  setIsTopMenuOpen: Dispatch<SetStateAction<boolean>>
  setTabSelected: Dispatch<SetStateAction<{ label?: string; path?: string }>>
  onValueChange: ((value: string) => void) | undefined
  children?: ReactNode | ReactNode[]
}> = (props) => {
  const contextValue = useMemo(
    () => ({
      tabSelected: props.tabSelected,
      isMobile: props.isMobile,
      defaultValue: props.defaultValue,
      isTopMenuOpen: props.isTopMenuOpen,
      setIsTopMenuOpen: props.setIsTopMenuOpen,
      setTabSelected: props.setTabSelected,
      onValueChange: props.onValueChange,
    }),
    [
      props.tabSelected,
      props.isMobile,
      props.defaultValue,
      props.isTopMenuOpen,
      props.setIsTopMenuOpen,
      props.setTabSelected,
      props.onValueChange,
    ],
  )

  return (
    <TabsContext.Provider value={contextValue}>
      {props.children}
    </TabsContext.Provider>
  )
}
