import type { SVGSpriteComponent } from '../types'

// NOTE: do not modify the styling or colours of this logo.
// It is provided by a third party with stipulations on its use: https://play.google.com/intl/en_us/badges/

const GooglePlay: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol {...props}>
      {children}
      <rect x="-.01" y=".01" width="136" height="40" rx="5.09" />
      <path
        d="M132.35.82a4.28 4.28 0 0 1 4.27 4.28v30.54a4.28 4.28 0 0 1-4.27 4.28H5.09a4.28 4.28 0 0 1-4.28-4.28V5.1A4.28 4.28 0 0 1 5.09.82h127.26m0-.81H5.09A5.11 5.11 0 0 0 0 5.1v30.54a5.11 5.11 0 0 0 5.1 5.09h127.25a5.1 5.1 0 0 0 5.09-5.09V5.1a5.1 5.1 0 0 0-5.09-5.1Z"
        fill="#a6a6a6"
      />
      <path
        d="M69.36 22.15a4.33 4.33 0 1 0 4.35 4.33 4.27 4.27 0 0 0-4.35-4.33Zm0 7a2.63 2.63 0 1 1 2.45-2.63 2.51 2.51 0 0 1-2.45 2.59Zm-9.48-7a4.33 4.33 0 1 0 4.35 4.33 4.27 4.27 0 0 0-4.35-4.33Zm0 7a2.63 2.63 0 1 1 2.45-2.63 2.51 2.51 0 0 1-2.45 2.59ZM48.6 23.48v1.84H53a3.87 3.87 0 0 1-1 2.31A4.5 4.5 0 0 1 48.6 29a4.89 4.89 0 0 1 0-9.77 4.7 4.7 0 0 1 3.32 1.31l1.29-1.3a6.41 6.41 0 0 0-4.61-1.85 6.73 6.73 0 1 0 0 13.45 6.15 6.15 0 0 0 4.69-1.89 6 6 0 0 0 1.59-4.29 6.55 6.55 0 0 0-.09-1.15Zm46.13 1.43A4 4 0 0 0 91 22.15a4.12 4.12 0 0 0-4.08 4.33 4.24 4.24 0 0 0 4.3 4.33 4.31 4.31 0 0 0 3.61-1.92l-1.48-1a2.47 2.47 0 0 1-2.13 1.2 2.2 2.2 0 0 1-2.1-1.32l5.79-2.39Zm-5.9 1.44a2.38 2.38 0 0 1 2.26-2.53 1.7 1.7 0 0 1 1.61.92Zm-4.71 4.2H86V17.82h-1.9ZM81 23.12h-.06a3 3 0 0 0-2.28-1 4.33 4.33 0 0 0 0 8.66 3 3 0 0 0 2.28-1H81v.62c0 1.66-.88 2.54-2.31 2.54a2.38 2.38 0 0 1-2.18-1.54l-1.66.69a4.13 4.13 0 0 0 3.84 2.56c2.23 0 4.12-1.31 4.12-4.51v-7.73H81Zm-2.18 6a2.63 2.63 0 0 1 0-5.25 2.43 2.43 0 0 1 2.31 2.64 2.42 2.42 0 0 1-2.31 2.6Zm24.82-11.29h-4.55v12.72H101v-4.82h2.65a4 4 0 1 0 0-7.91Zm0 6.13H101v-4.37h2.7a2.18 2.18 0 1 1 0 4.36Zm11.74-1.82a3.56 3.56 0 0 0-3.38 1.94l1.69.7a2.05 2.05 0 0 1 3.73.7v.13a4.27 4.27 0 0 0-2-.49c-1.82 0-3.67 1-3.67 2.87a2.94 2.94 0 0 0 3.16 2.8 2.67 2.67 0 0 0 2.43-1.25h.06v1h1.84v-4.89c.06-2.26-1.63-3.52-3.81-3.52Zm-.23 7c-.62 0-1.49-.31-1.49-1.08 0-1 1.08-1.36 2-1.36a3.31 3.31 0 0 1 1.73.43 2.29 2.29 0 0 1-2.19 1.97ZM126 22.41l-2.18 5.51h-.06l-2.26-5.51h-2l3.39 7.71-1.93 4.29h2l5.22-12Zm-17.11 8.14h1.9V17.82h-1.9Z"
        fill="#fff"
      />
      <path
        d="M21.09 19.78 10.25 31.29a2.92 2.92 0 0 0 2.83 2.17 2.85 2.85 0 0 0 1.48-.4l12.2-7Z"
        fill="#ea4335"
      />
      <path
        d="m32.05 17.82-5.26-3L20.84 20l6 6L32 23a2.93 2.93 0 0 0 0-5.15Z"
        fill="#fbbc04"
      />
      <path
        d="M10.25 9.45a2.66 2.66 0 0 0-.1.75v20.33a2.73 2.73 0 0 0 .1.76l11.21-11.21Z"
        fill="#4285f4"
      />
      <path
        d="m21.17 20.37 5.61-5.61-12.19-7.07a3 3 0 0 0-1.51-.42 2.93 2.93 0 0 0-2.83 2.18Z"
        fill="#34a853"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth=".19px"
        d="M47.88 10.59a2.56 2.56 0 0 1-.71 1.92 2.78 2.78 0 0 1-2.11.85 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 1.18.24 2.41 2.41 0 0 1 .9.64l-.51.51a1.92 1.92 0 0 0-1.57-.69 2.24 2.24 0 0 0-2.24 2.3A2.26 2.26 0 0 0 46.66 12a1.85 1.85 0 0 0 .49-1.17h-2.09v-.69h2.78a2.15 2.15 0 0 1 .04.45ZM52.3 8.19h-2.62V10H52v.7h-2.32v1.82h2.62v.71h-3.36V7.48h3.36ZM55.42 13.24h-.74V8.19h-1.61v-.71h4v.71h-1.6ZM59.88 13.24V7.48h.74v5.76ZM63.9 13.24h-.74V8.19h-1.61v-.71h4v.71H63.9ZM73 12.49a3 3 0 0 1-4.22 0 3 3 0 0 1-.85-2.13 3 3 0 0 1 .85-2.13 3 3 0 0 1 4.21 0 2.93 2.93 0 0 1 .85 2.13 3 3 0 0 1-.84 2.13ZM69.32 12a2.2 2.2 0 0 0 3.12 0 2.23 2.23 0 0 0 .64-1.65 2.23 2.23 0 0 0-.64-1.65 2.2 2.2 0 0 0-3.12 0 2.23 2.23 0 0 0-.64 1.65 2.23 2.23 0 0 0 .64 1.65ZM74.87 13.24V7.48h.9l2.8 4.52V7.48h.74v5.76h-.78l-2.92-4.69V13.24Z"
      />
    </symbol>
  )
}
GooglePlay.displayName = 'SVG.GooglePlay'
const GooglePlayConfigProps = {
  viewBox: '0 0 137.44 40.74',
  width: '135px',
  height: '40px',
}
export { GooglePlay, GooglePlayConfigProps }
