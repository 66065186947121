import type { SVGSpriteComponent } from '../types'

const Calendar: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path
        d="M20 4.25H16.75V3C16.75 2.80109 16.671 2.61032 16.5303 2.46967C16.3897 2.32902 16.1989 2.25 16 2.25C15.8011 2.25 15.6103 2.32902 15.4697 2.46967C15.329 2.61032 15.25 2.80109 15.25 3V4.25H8.75V3C8.75 2.80109 8.67098 2.61032 8.53033 2.46967C8.38968 2.32902 8.19891 2.25 8 2.25C7.80109 2.25 7.61032 2.32902 7.46967 2.46967C7.32902 2.61032 7.25 2.80109 7.25 3V4.25H4C3.53605 4.25058 3.09127 4.43514 2.76321 4.76321C2.43514 5.09127 2.25058 5.53605 2.25 6V20C2.25058 20.464 2.43514 20.9087 2.76321 21.2368C3.09127 21.5649 3.53605 21.7494 4 21.75H20C20.464 21.7494 20.9087 21.5649 21.2368 21.2368C21.5649 20.9087 21.7494 20.464 21.75 20V6C21.7494 5.53605 21.5649 5.09127 21.2368 4.76321C20.9087 4.43514 20.464 4.25058 20 4.25ZM4 5.75H7.25V7C7.25 7.19891 7.32902 7.38968 7.46967 7.53033C7.61032 7.67098 7.80109 7.75 8 7.75C8.19891 7.75 8.38968 7.67098 8.53033 7.53033C8.67098 7.38968 8.75 7.19891 8.75 7V5.75H15.25V7C15.25 7.19891 15.329 7.38968 15.4697 7.53033C15.6103 7.67098 15.8011 7.75 16 7.75C16.1989 7.75 16.3897 7.67098 16.5303 7.53033C16.671 7.38968 16.75 7.19891 16.75 7V5.75H20C20.0663 5.75 20.1299 5.77634 20.1768 5.82322C20.2237 5.87011 20.25 5.9337 20.25 6V10.25H3.75V6C3.75 5.9337 3.77634 5.87011 3.82322 5.82322C3.87011 5.77634 3.9337 5.75 4 5.75ZM20 20.25H4C3.9337 20.25 3.87011 20.2237 3.82322 20.1768C3.77634 20.1299 3.75 20.0663 3.75 20V11.75H20.25V20C20.25 20.0663 20.2237 20.1299 20.1768 20.1768C20.1299 20.2237 20.0663 20.25 20 20.25Z"
        fill="currentColor"
      />
    </symbol>
  )
}
Calendar.displayName = 'SVG.Calendar'
const CalendarConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { Calendar, CalendarConfigProps }
