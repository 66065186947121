'use client'

import { AnimationError404 } from '@chaseweb/ui-library/src/atoms/animation'
import { Suspense } from 'react'

export const Error404Animation = () => {
  return (
    <Suspense>
      <AnimationError404 />
    </Suspense>
  )
}
