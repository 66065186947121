import { forwardRef, type HTMLAttributes, type RefObject } from 'react'

import type { ImageWithPlaceholderData } from '@/types'

import { NextImage, type NextImageProps } from '../vendor'

interface OptimisedImgProps
  extends Omit<NextImageProps, 'src' | 'priority' | 'fill'> {
  data: ImageWithPlaceholderData
}

/**
 * Handles AVIF + JPG but does not use NextImage (so no placeholder support)
 */
const OptimisedImg = forwardRef<
  RefObject<HTMLPictureElement>,
  HTMLAttributes<HTMLPictureElement> & OptimisedImgProps
>((props, ref) => {
  const { data, alt, ...restProps } = props

  if (process.env.IS_DOCKER_BUILD) {
    return (
      <picture ref={ref as RefObject<HTMLElement>}>
        <NextImage alt={alt} src={data.src} {...restProps} />
      </picture>
    )
  }

  return (
    <picture ref={ref as RefObject<HTMLElement>}>
      {data.optimisedVersions?.map(({ src, type }) => (
        <source key={src} srcSet={src} type={type} />
      ))}
      <img alt={alt} src={data.src} {...restProps} />
    </picture>
  )
})
OptimisedImg.displayName = 'OptimisedImg'

export { OptimisedImg }
