import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'

import { cn } from '@chaseweb/utils/cn'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
export type HeadingContext = 'pageTitle'

const headingVariants = cva('', {
  variants: {
    type: {
      h1: 'ui-h1',
      h2: 'ui-h2',
      h3: 'ui-h3',
      h4: 'ui-h4',
      h5: 'ui-h5',
    },
    context: {
      pageTitle: 'tw-mb-6 tw-mt-4 tw-text-primary lg:tw-mt-6',
    },
  },
  defaultVariants: {
    type: 'h1',
  },
})
export interface HeadingProps
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingVariants> {
  type: HeadingType
  as?: HeadingType
  context?: HeadingContext
  asChild?: boolean
}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, asChild, type, context, as, ...props }, ref) => {
    const Comp = asChild ? Slot : as ?? type
    return (
      <Comp
        className={cn(headingVariants({ type, context }), className)}
        ref={ref}
        {...props}
      />
    )
  },
)
Heading.displayName = 'Heading'

export { Heading }
