import type { SVGSpriteComponent } from '../types'

const Question: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M12,21.75A9.75,9.75,0,1,1,21.75,12,9.761,9.761,0,0,1,12,21.75Zm0-18A8.25,8.25,0,1,0,20.25,12,8.2593,8.2593,0,0,0,12,3.75Zm.75,9.408v-.354a2.75,2.75,0,1,0-3.5-2.646.75.75,0,1,0,1.5,0A1.25,1.25,0,1,1,12,11.408a.75.75,0,0,0-.75.75v1a.75.75,0,1,0,1.5,0Zm-1.5,2h0a.75.75,0,0,0,.75.75h0a.75.75,0,0,0,.75-.75h0a.75.75,0,0,0-.75-.75h0A.75.75,0,0,0,11.25,15.158Z"
      />
    </symbol>
  )
}

Question.displayName = 'SVG.Question'
const QuestionConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { Question, QuestionConfigProps }
