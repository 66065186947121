export enum FeatureTypeIdEnum {
  ACCORDION_FAQ_FEATURE = 'accordion_faq_feature',
  ACCORDION_FEATURE = 'accordion_feature',
  BOAST_BAR_FEATURE = 'boast_bar_feature',
  BREADCRUMBS_FEATURE = 'breadcrumbs_feature',
  CAROUSEL_EDITORIAL_FEATURE = 'carousel_editorial_feature',
  CAROUSEL_IMAGE_CARD_FEATURE = 'carousel_image_card_feature',
  CAROUSEL_LATEST_ARTICLE_FEATURE = 'carousel_latest_article_feature',
  CAROUSEL_LIFESTYLE_FEATURE = 'carousel_lifestyle_feature',
  FOOTER_FEATURE = 'footer_feature',
  FULL_WIDTH_FEATURE = 'full_width_feature',
  HERO_FEATURE = 'hero_feature',
  HERO_MULTI_IMAGE_FEATURE = 'hero_multi_image_feature',
  IMAGE_CARD_GRID_FEATURE = 'image_card_grid_feature',
  IMAGE_TEXT_FEATURE = 'image_text_feature',
  INFO_BLOCK_FEATURE = 'info_block_feature',
  LOGO_TILE_FEATURE = 'logo_tile_feature',
  NAVIBAR_FEATURE = 'navibar_feature',
  NOTIFICATION_FEATURE = 'notification_feature',
  PAGE_CONTENT_FEATURE = 'page_content_feature',
  PRODUCT_CARD_GRID_FEATURE = 'product_card_grid_feature',
  PROMO_FEATURE = 'promo_feature',
  RATES_FEATURE = 'rates_feature',
  RAW_IMAGE_GRID_FEATURE = 'raw_image_grid_feature',
  REPORT_FEATURE = 'report_feature',
  STEP_CARD_GRID_FEATURE = 'step_card_grid_feature',
  TABS_FEATURE = 'tabs_feature',
  TEXT_GRID_FEATURE = 'text_grid_feature',
  TEXT_ONLY_FEATURE = 'text_only_feature',
  TEXT_ONLY_STYLED_FEATURE = 'text_only_styled_feature',
  WIDGET_FEATURE = 'widget_feature',
}

const PAGE_CONTENT_TYPE = 'page-content'
export const PageContentVariantEnum = {
  PAGE_HUB_CONTENT: `${PAGE_CONTENT_TYPE}_hub`,
  PAGE_LEGAL_CONTENT: `${PAGE_CONTENT_TYPE}_legal`,
  PAGE_MEDIA_CONTENT: `${PAGE_CONTENT_TYPE}_media`,
  PAGE_UPDATES_FROM_CHASE_CONTENT: `${PAGE_CONTENT_TYPE}_updates-from-chase`,
  PAGE_DOWNLOAD_CONTENT: `${PAGE_CONTENT_TYPE}_download`,
  PAGE_SUPPORT_CONTENT: `${PAGE_CONTENT_TYPE}_support`,
  PAGE_SUPPORT_SEARCH_CONTENT: `${PAGE_CONTENT_TYPE}_support-search`,
} as const

export enum FeatureWidgetVariantEnum {
  TRUSTPILOT = 'trustpilot',
}

export enum FeatureTabsVariantEnum {
  PAGE = 'page',
  DEFAULT = 'default',
}

export enum FeatureTextOnlyVariantEnum {
  CENTER = 'center',
  LEFT = 'left',
}

export enum FeatureTextOnlyStyledVariantEnum {
  STYLED = 'styled',
  ARTICLE = 'article',
  HEADER = 'header',
}

export enum ReportAvailabilityAndPerformanceVariantEnum {
  OPEN_BANKING_PERFORMANCE = 'open-banking-performance',
  APP_PERFORMANCE = 'app-performance',
}

export enum FeatureInfoBlockVariantEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum FeatureHeroVariantEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum FeatureItemLinkVariantEnum {
  DEFAULT = 'default',
  ICON_ONLY = 'icon-only',
  ICON_ONLY_STYLED = 'icon-only-styled',
}

export const ActionLinkStart = 'action:'
export enum FeatureActionLinkEnum {
  DOWNLOAD = `${ActionLinkStart}download`,
  MANAGE_COOKIES = `${ActionLinkStart}manage-cookies`,
  APPLE_STORE = `${ActionLinkStart}apple-store`,
  ANDROID_STORE = `${ActionLinkStart}android-store`,
}
