import { StyledLink } from '@/components'
import type { DocumentDetailsSection } from '@/data/aem'
import type { RelativePathOrHrefExternalType } from '@/types'

const BUTTON_LABEL = 'Download as PDF'

export const DocumentDetails = ({ data }: { data: DocumentDetailsSection }) => {
  const label = data.downloadLabel || BUTTON_LABEL
  return (
    <span className="tw-mb-6 tw-block">
      {data.versionDetails && (
        <span className="!tw-mt-0 tw-mb-2 tw-block tw-text-grey40">
          {data.versionDetails}
        </span>
      )}
      <StyledLink
        className="tw-font-semibold"
        invertLinkUnderline
        href={(data.pdfUrl || '') as RelativePathOrHrefExternalType}
        trackingActionLabel={label}
        sectionLabel="document-details"
        target="_blank"
      >
        {label}
      </StyledLink>
    </span>
  )
}
