import type { SVGSpriteComponent } from '../types'

const ExternalLink: SVGSpriteComponent = ({
  children,
  role,
  spriteFilePath,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <symbol {...props}>
      {children}
      <path
        d="M17.993 6.963a.994.994 0 00-.07-.346v-.003l-.016-.024a.99.99 0 00-.178-.265 1.007 1.007 0 00-.076-.069.983.983 0 00-.209-.14.948.948 0 00-.095-.046A.991.991 0 0017 6H9a1 1 0 000 2h5.586l-8.293 8.293a1 1 0 101.414 1.414L16 9.414V15a1 1 0 002 0V7c0-.013-.007-.024-.007-.037z"
        fill="currentColor"
      />
    </symbol>
  )
}

ExternalLink.displayName = 'SVG.ExternalLink'
const ExternalLinkConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { ExternalLink, ExternalLinkConfigProps }
