import type { SVGSpriteComponent } from '../types'

const Dash: SVGSpriteComponent = ({
  children,
  role,
  spriteFilePath,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <symbol {...props}>
      {children}
      <path
        d="M5 11.25L19 11.25C19.1989 11.25 19.3897 11.329 19.5303 11.4697C19.671 11.6103 19.75 11.8011 19.75 12C19.75 12.1989 19.671 12.3897 19.5303 12.5303C19.3897 12.671 19.1989 12.75 19 12.75L5 12.75C4.80109 12.75 4.61032 12.671 4.46967 12.5303C4.32902 12.3897 4.25 12.1989 4.25 12C4.25 11.8011 4.32902 11.6103 4.46967 11.4697C4.61032 11.329 4.80109 11.25 5 11.25Z"
        fill="currentColor"
      />
    </symbol>
  )
}

Dash.displayName = 'SVG.Dash'
const DashConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { Dash, DashConfigProps }
