import { useCallback, useState, type FC } from 'react'
import { MarketingPixelId, MarketingPixelsFragment } from '.'

export const useDownloadConversionPixelsFragment = ({
  hasAdTargetingConsent,
}: {
  hasAdTargetingConsent: boolean
}) => {
  const [firePixels, setFirePixels] = useState(false)

  const triggerConversionPixels = useCallback(() => {
    setFirePixels(true)
  }, [])

  const DownloadConversionPixelsFragment: FC = useCallback(
    () => (
      <>
        {firePixels && (
          <MarketingPixelsFragment
            appendDefaults={false}
            hasAdTargetingConsent={hasAdTargetingConsent}
            marketingPixels={[
              MarketingPixelId.GOOGLE_PAID_SEARCH,
              MarketingPixelId.GOOGLE_ADS_DOWNLOAD_CLICK,
              MarketingPixelId.GOOGLE_DCM_DOWNLOAD_CLICK,
              MarketingPixelId.FACEBOOK_DOWNLOAD_CLICK,
              MarketingPixelId.TWITTER_DOWNLOAD_CLICK,
              MarketingPixelId.TWITTER_SHORTLINK_DOWNLOAD_CLICK,
            ]}
          />
        )}
      </>
    ),
    [firePixels],
  )

  return {
    DownloadConversionPixelsFragment,
    triggerConversionPixels,
  }
}
