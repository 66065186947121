import type { SVGSpriteComponent } from '../types'

const NutmegIcon: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M14.6784 3V3.29703C16.255 3.53465 16.4886 5.25742 16.4886 7.75247V14.2277L9.2479 3.05941H4.10938V3.35644C4.98526 3.53466 5.62758 4.24753 5.74436 5.13862V16.2475C5.74436 18.6832 5.68596 20.6436 4.34294 20.703V21H8.83916V20.703C7.26256 20.4653 7.02899 18.7426 7.02899 16.2475V5.85149L17.0141 20.9406H17.7732V7.75247C17.7732 5.31683 17.8316 3.35644 19.1746 3.29703V3H14.6784Z"
        fill="#2FB153"
      />
      <path
        d="M19.1758 19.0369C19.1758 17.9664 20.0587 17.1338 21.1183 17.1338C22.1778 17.1338 23.0019 17.9664 23.0019 19.0369C23.0019 20.1075 22.1778 20.9401 21.1183 20.9996C20.0587 20.9401 19.1758 20.1075 19.1758 19.0369Z"
        fill="#2FB153"
      />
    </symbol>
  )
}
NutmegIcon.displayName = 'SVG.NutmegIcon'
const NutmegIconConfigProps = { width: '40px', viewBox: '0 0 24 24' }
export { NutmegIcon, NutmegIconConfigProps }
