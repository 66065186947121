import type { SVGSpriteComponent } from '../types'

const Facebook: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M15.78 22v-7.74h2.592l.388-3.009h-2.98v-1.92c0-.871.242-1.465 1.491-1.465h1.593V5.174a21.32 21.32 0 00-2.322-.118 3.625 3.625 0 00-3.869 3.977v2.218h-2.597v3.009h2.597V22H3.111A1.111 1.111 0 012 20.889V3.11A1.111 1.111 0 013.111 2H20.89A1.111 1.111 0 0122 3.111V20.89A1.111 1.111 0 0120.889 22z"
        fill="currentColor"
      />
    </symbol>
  )
}
Facebook.displayName = 'SVG.Facebook'
const FacebookConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { Facebook, FacebookConfigProps }
