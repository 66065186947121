import { memo } from 'react'

export function getRandomNumberWithinRange(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min)
}

const MarketingPixelNoMemo = ({ type }: { type: MarketingPixelId }) => {
  const { name, src } = MARKETING_PIXEL_INFO[type]

  return (
    <img
      style={{ display: 'none', width: 1, height: 1 }}
      alt=""
      data-testid={name}
      src={src}
    />
  )
}

export const MarketingPixel = memo(MarketingPixelNoMemo)

export enum MarketingPixelId {
  GOOGLE_PAID_SEARCH,
  GOOGLE_ADS,
  GOOGLE_ADS_DOWNLOAD_CLICK,
  FACEBOOK,
  FACEBOOK_DOWNLOAD_CLICK,
  FACEBOOK_SEARCH_JOBS,
  GOOGLE_DCM,
  GOOGLE_DCM_DOWNLOAD_CLICK,
  TWITTER,
  TWITTER_SHORTLINK,
  TWITTER_DOWNLOAD_CLICK,
  TWITTER_SHORTLINK_DOWNLOAD_CLICK,
  YOUTUBE_PUBLIC,
  YOUTUBE_IN_HOUSE,
  YOUTUBE_MEDIACOM,
}

const generateFloodlightOrderNumber = () =>
  getRandomNumberWithinRange(0, 10000000000000000)

export const MARKETING_PIXEL_INFO = {
  [MarketingPixelId.GOOGLE_PAID_SEARCH]: {
    name: 'Google Paid Search Pixel',
    src: 'https://www.googleadservices.com/pagead/conversion/665658870/?value=1&label=M-7OCOa85I4YEPbLtL0C&guid=ON&script=0',
  },
  [MarketingPixelId.GOOGLE_ADS]: {
    name: 'Google Ads Pixel',
    src: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/665658870/?value=0&label=LM6yCPC6_t8BEPbLtL0C&guid=ON&script=0',
  },
  [MarketingPixelId.GOOGLE_ADS_DOWNLOAD_CLICK]: {
    name: 'Google Ads Download Click Pixel',
    src: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/665658870/?value=0&label=AizBCKf44vwCEPbLtL0C&guid=ON&script=0',
  },
  [MarketingPixelId.FACEBOOK]: {
    name: 'Facebook Pixel',
    src: 'https://www.facebook.com/tr?id=182692723223268&ev=ViewContent&noscript=1',
  },
  [MarketingPixelId.FACEBOOK_DOWNLOAD_CLICK]: {
    name: 'Facebook Download Click Pixel',
    src: 'https://www.facebook.com/tr?id=182692723223268&ev=SubmitApplication&noscript=1',
  },
  [MarketingPixelId.FACEBOOK_SEARCH_JOBS]: {
    name: 'Facebook Search Jobs Pixel',
    src: 'https://www.facebook.com/tr?id=182692723223268&ev=applynow&noscript=1',
  },
  [MarketingPixelId.GOOGLE_DCM]: {
    name: 'Google DCM Pixel',
    src: `https://ad.doubleclick.net/ddm/activity/src=10005275;type=dynamo;cat=pagev0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=${generateFloodlightOrderNumber()}?`,
  },
  [MarketingPixelId.GOOGLE_DCM_DOWNLOAD_CLICK]: {
    name: 'Google DCM Download Click Pixel',
    src: `https://ad.doubleclick.net/ddm/activity/src=10005275;type=dynamo;cat=conve00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=${generateFloodlightOrderNumber()}?`,
  },
  [MarketingPixelId.TWITTER]: {
    name: 'Twitter Pixel',
    src: 'https://analytics.twitter.com/i/adsct?txn_id=o5hn0&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0',
  },
  [MarketingPixelId.TWITTER_SHORTLINK]: {
    name: 'Twitter Shortlink Pixel',
    src: '//t.co/i/adsct?txn_id=o5hn0&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0',
  },
  [MarketingPixelId.TWITTER_DOWNLOAD_CLICK]: {
    name: 'Twitter Download Click Pixel',
    src: 'https://analytics.twitter.com/i/adsct?txn_id=o6w77&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0',
  },
  [MarketingPixelId.TWITTER_SHORTLINK_DOWNLOAD_CLICK]: {
    name: 'Twitter Shortlink Download Click Pixel',
    src: '//t.co/i/adsct?txn_id=o6w77&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0',
  },
  [MarketingPixelId.YOUTUBE_PUBLIC]: {
    name: 'YouTube Public Advertiser Pixel',
    src: 'https://www.googleadservices.com/pagead/conversion/454491271/?label=WbG1CKr8g_MCEIf529gB&guid=ON&script=0&npa=1',
  },
  [MarketingPixelId.YOUTUBE_IN_HOUSE]: {
    name: 'YouTube In-House Advertiser Pixel',
    src: 'https://www.googleadservices.com/pagead/conversion/454493092/?label=UwAnCMqJ2PICEKSH3NgB&guid=ON&script=0&npa=1',
  },
  [MarketingPixelId.YOUTUBE_MEDIACOM]: {
    name: 'YouTube Mediacom Advertiser Pixel',
    src: 'https://www.googleadservices.com/pagead/conversion/10880092675/?label=l_bfCJOrzLADEIOUhMQo&guid=ON&script=0&npa=1',
  },
}
