import { type RefObject } from 'react'

export const getOffsetTop = (elem: HTMLElement) => {
  let distance = 0
  if (elem?.offsetParent) {
    do {
      distance += elem.offsetTop
      elem = elem.offsetParent as HTMLElement
    } while (elem)
  }
  return distance < 0 ? 0 : distance
}

export const updatePictureYTranslation = (
  pictureRef: RefObject<HTMLElement>,
  pictureWrapperRef: RefObject<HTMLDivElement>,
  factor?: number,
) => {
  const calculateFrame = () => {
    if (pictureRef.current && pictureWrapperRef.current) {
      // The below calculations are based on this equation: yPos / (viewportHeight + imageViewport) === yTranslation / fullSizeImage * factor (0 - 10% being safest)
      // ie the distance travelled by the imageViewport within the viewport + height of the imageViewport itself should be proportional to the distance travelled by the imageViewport within the full size image (image background).

      const viewportHeight = window.innerHeight
      const fullSizeImage = pictureRef.current.offsetHeight
      const imageCoverage = factor ? factor / 100 : 0.1 // Defaults to 10% as it is a safe value

      // Get imageViewport (imageWrapper) height and position (absolute and relative to yScroll)
      const distanceToImageViewportTop =
        pictureWrapperRef.current.getBoundingClientRect().y
      const imageViewportHeight = pictureWrapperRef.current.offsetHeight
      const absoluteDistanceToImageViewportTop = getOffsetTop(
        pictureWrapperRef.current,
      )

      // If the image is above the fold, we need to offset the absolute distance to the top of the image viewport
      // This is to prevent the image from jumping on load
      /* istanbul ignore next - scrolling cannot be tested with jsdom */
      const yPos =
        absoluteDistanceToImageViewportTop < viewportHeight
          ? distanceToImageViewportTop - absoluteDistanceToImageViewportTop
          : distanceToImageViewportTop

      const yTranslation = Math.round(
        (yPos / (viewportHeight + imageViewportHeight)) *
          (fullSizeImage * imageCoverage),
      )
      //  @ts-expect-error ts error for setting style directly
      pictureRef.current.style = `transform: translateY(${-yTranslation}px)`
    }
  }
  window.requestAnimationFrame(calculateFrame)
}
