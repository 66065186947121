'use client'

import type { NotificationWidget } from '@chaseweb/ui-library/src'
import {
  NotificationKeyTypesEnum,
  type NotificationLocalKeyTypesEnum,
  type NotificationSessionKeyTypesEnum,
} from '@chaseweb/utils/notifications/types'
import { useNotificationStore } from '@chaseweb/utils/notifications/useNotificationStore'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

import type { RelativePathOrHrefExternalType } from '@/types'

export type NotificationSplitConfig = {
  variant: NotificationWidget.NotificationType
  title: string
  titleUrl: RelativePathOrHrefExternalType
  content: string
}

const HomeBannerNotification = dynamic(
  () =>
    import('./home-banner-notification').then((mod) => {
      return mod.HomeBannerNotification
    }),
  { ssr: false },
)

const ProductBannerNotification = dynamic(
  () =>
    import('./product-banner-notification').then((mod) => {
      return mod.ProductBannerNotification
    }),
  { ssr: false },
)

const SupportBannerNotification = dynamic(
  () =>
    import('./support-banner-notification').then((mod) => {
      return mod.SupportBannerNotification
    }),
  { ssr: false },
)
const BrowserSupportNotification = dynamic(
  () =>
    import('./browser-support-notification').then((mod) => {
      return mod.BrowserSupportNotification
    }),
  { ssr: false },
)
const UsRedirectNotification = dynamic(
  () =>
    import('./us-redirect-notification').then((mod) => {
      return mod.UsRedirectNotification
    }),
  { ssr: false },
)

export const Notification = ({
  notification,
}: {
  notification: NotificationSessionKeyTypesEnum | NotificationLocalKeyTypesEnum
}) => {
  const [mounted, setMounted] = useState(false)

  const { getNotificationValue, setNotificationVisible } =
    useNotificationStore()

  useEffect(() => {
    setMounted(true)
  }, [])

  /* istanbul ignore next */
  const handleNotificationDismiss = () => {
    setNotificationVisible(notification, false)
  }

  const renderNotification = () => {
    if (!getNotificationValue(notification) || !mounted) {
      return <></>
    }
    switch (notification) {
      case NotificationKeyTypesEnum.HOME:
        return <HomeBannerNotification onDismiss={handleNotificationDismiss} />

      case NotificationKeyTypesEnum.PRODUCT:
        return (
          <ProductBannerNotification onDismiss={handleNotificationDismiss} />
        )
      case NotificationKeyTypesEnum.SUPPORT:
        return (
          <SupportBannerNotification onDismiss={handleNotificationDismiss} />
        )
      case NotificationKeyTypesEnum.BROWSER:
        return (
          <BrowserSupportNotification onDismiss={handleNotificationDismiss} />
        )
      case NotificationKeyTypesEnum.US_REDIRECT:
        return <UsRedirectNotification onDismiss={handleNotificationDismiss} />
    }
  }

  return renderNotification()
}
