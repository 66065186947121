import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export const usePageTitle = () => {
  const pathname = usePathname()
  const [pageTitle, setPageTitle] = useState<string | null>(null)

  useEffect(() => {
    const metaTag = document?.querySelector<HTMLMetaElement>(
      'meta[name="pageTitle"]',
    )?.content
    if (metaTag) {
      setPageTitle(metaTag)
    } else {
      setPageTitle(document.title.split('|')[0].trim())
    }
  }, [pathname])

  return pageTitle
}
