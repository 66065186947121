import type { SVGSpriteComponent } from '../types'

const X: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => (
  <symbol fill="none" {...props}>
    {children}
    <g>
      <path
        d="m14.2833 10.1624 8.9345-10.1624h-2.1172l-7.7579 8.82384-6.19614-8.82384h-7.14656l9.36984 13.3432-9.36984 10.6568h2.11732l8.19248-9.3183 6.5436 9.3183h7.1466l-9.7173-13.8376zm-2.9 3.2984-.9494-1.3287-7.55368-10.57248h3.25208l6.0959 8.53228.9494 1.3287 7.924 11.0907h-3.2521l-6.4662-9.05z"
        fill="currentColor"
      />
    </g>
  </symbol>
)
X.displayName = 'SVG.X'
const XConfigProps = { width: '24', viewBox: '-3 -3 30 30' }
export { X, XConfigProps }
