'use client'

import { cn } from '@chaseweb/utils/cn'
import type { MouseEventHandler } from 'react'

import { useConsentStore } from '@/lib/stores'

export function ConsentModalLink({ className }: { className?: string }) {
  const setCurrentStep = useConsentStore(({ setCurrentStep }) => setCurrentStep)

  const onClick: MouseEventHandler = (event) => {
    event.preventDefault()
    setCurrentStep(0)
  }
  return (
    <div
      className={cn('tw-cursor-pointer', className)}
      onClick={onClick}
      role="link"
    >
      Manage settings
    </div>
  )
}
