import type { AnalyticsConfig, AnalyticsEnvironment, MParticleConfig } from '..'

let environment: AnalyticsEnvironment | null = null
let mParticleConfig: MParticleConfig | null = null

export const analyticsConfig: AnalyticsConfig = {
  reset: function () {
    this.setEnvironment(null)
    this.setMParticleConfig(null)
    return this
  },

  setEnvironment: function (newEnvironment: AnalyticsEnvironment | null) {
    environment = newEnvironment
    return this
  },

  getEnvironment: function () {
    return environment
  },

  setMParticleConfig: function (newMParticleConfig: MParticleConfig | null) {
    mParticleConfig = newMParticleConfig
    return this
  },

  getMParticleConfig: function () {
    return mParticleConfig
  },
}
