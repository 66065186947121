import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'
import type { SvgType } from '@chaseweb/ui-library/src/atoms'
import { SVG } from '@chaseweb/ui-library/src/atoms'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@chaseweb/utils/cn'

export type NotificationType =
  | 'neutral'
  | 'warning'
  | 'information'
  | 'redirect'
  | 'critical'
  | 'success'

const notificationVariants = cva(
  [
    'tw-group/notification tw-relative tw-mb-4 tw-box-border tw-flex tw-w-full tw-flex-row tw-gap-2 tw-p-4 tw-text-sm lg:tw-p-6 lg:tw-text-base',
  ],
  {
    variants: {
      variant: {
        warning: ['tw-border-yellow70 tw-bg-yellow90'],
        information: ['tw-border-blue70 tw-bg-blue90'],
        neutral: ['tw-border-grey70 tw-bg-white'],
        critical: ['tw-border-red70 tw-bg-red90'],
        success: ['tw-border-green70 tw-bg-green90'],
        redirect: [
          'tw-relative tw-top-0 tw-z-20 tw-mb-0 tw-bg-blue90 tw-transition-transform tw-duration-300',
        ],
      },
      show: {
        true: [],
      },
    },
    compoundVariants: [
      {
        variant: ['redirect'],
        show: true,
        class: 'tw-translate-y-0',
      },
      {
        variant: ['redirect'],
        show: false,
        class: '-tw-translate-y-full',
      },
      {
        variant: ['warning', 'information', 'neutral', 'critical', 'success'],
        show: true,
        class:
          'tw-max-h-60 tw-rounded-lg tw-border-[1px] tw-opacity-100 lg:tw-rounded-2xl',
      },
      {
        variant: ['warning', 'information', 'neutral', 'critical', 'success'],
        show: false,
        class: 'tw-max-h-0 tw-opacity-0 tw-transition-all',
      },
    ],
    defaultVariants: {
      variant: 'information',
      show: true,
    },
  },
)

export interface NotificationWidgetRootProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof notificationVariants> {
  variant: NotificationType
  show?: boolean
}

const Root = forwardRef<HTMLDivElement, NotificationWidgetRootProps>(
  (
    { children, variant, show, role = 'alert', className, ...props },
    ref,
  ): React.JSX.Element | null => {
    return (
      <div
        className={cn(
          notificationVariants({
            variant,
            show,
          }),
          className,
        )}
        data-variant={variant}
        ref={ref}
        role={role}
        aria-live="assertive"
        {...props}
      >
        {children}
      </div>
    )
  },
)
Root.displayName = 'NotificationWidget.Root'

const iconName: Partial<Record<NotificationType, SvgType>> = {
  warning: 'WarningStatus',
  critical: 'ErrorStatus',
  information: 'InformationStatus',
  success: 'SuccessStatus',
}

const notificationIconVariants = cva('tw-h-6', {
  variants: {
    variant: {
      warning: 'tw-text-orange50',
      critical: 'tw-text-red40',
      success: 'tw-text-green40',
      neutral: '',
      redirect: '',
      information: 'tw-text-blue30',
    },
  },
})

const Icon = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    variant: NotificationType
    iconClassName?: string
  }
>(({ children, className, iconClassName, variant, ...props }, ref) => {
  return (
    <div
      className={cn(notificationIconVariants({ variant }), className)}
      ref={ref}
      data-ui-component={Icon.displayName}
      {...props}
    >
      {children}
      {variant === 'redirect' && <span aria-label="USA">🇺🇸</span>}
      {variant !== 'redirect' &&
        variant !== 'neutral' &&
        !!iconName[variant] && (
          <SVG
            name={iconName[variant] as SvgType}
            className={cn(iconClassName)}
          />
        )}
    </div>
  )
})
Icon.displayName = 'NotificationWidget.Icon'

const Title = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn('tw-font-semibold', className)}
        role="heading"
        aria-level={2}
        data-ui-component={Title.displayName}
        ref={ref}
        {...props}
      />
    )
  },
)
Title.displayName = 'NotificationWidget.Title'

const Text = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    return <div ref={ref} data-ui-component={Text.displayName} {...props} />
  },
)
Text.displayName = 'NotificationWidget.Text'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'tw-mr-8 tw-flex tw-max-w-[90rem] tw-flex-wrap tw-gap-2',
          'group-data-[variant="redirect"]/notification:tw-ml-0 group-data-[variant="redirect"]/notification:tw-w-11/12 group-data-[variant="redirect"]/notification:md:tw-m-auto group-data-[variant="redirect"]/notification:md:tw-w-auto',
          className,
        )}
        data-ui-component={Content.displayName}
        {...props}
      />
    )
  },
)
Content.displayName = 'NotificationWidget.Content'

const CloseButton = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement>
>(({ tabIndex, role = 'button', className, ...props }, ref) => {
  return (
    <button
      className={cn(
        'tw-absolute tw-right-4 tw-h-6 tw-w-6 lg:tw-right-6',
        className,
      )}
      ref={ref}
      data-ui-component={CloseButton.displayName}
      aria-label="dismiss"
      tabIndex={tabIndex ?? 0}
      role={role}
      {...props}
    >
      <SVG name="Close" />
    </button>
  )
})
CloseButton.displayName = 'NotificationWidget.CloseButton'

const ActionButton = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement>
>(({ tabIndex, role = 'button', children, className, ...props }, ref) => {
  return (
    <button
      className={cn(
        'w-top-1/2 tw-absolute tw-right-4 tw-h-6 tw-w-6 tw-bg-icon-arrow-right lg:tw-right-6 lg:tw-top-1/3',
        className,
      )}
      ref={ref}
      data-ui-component={ActionButton.displayName}
      aria-label="redirect"
      tabIndex={tabIndex ?? 0}
      role={role}
      {...props}
    />
  )
})
ActionButton.displayName = 'NotificationWidget.ActionButton'

export { Root, Icon, Title, Text, Content, CloseButton, ActionButton }
