/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UserActionInterfaceType } from '@chaseweb/utils/tracking'
import { analytics } from '@chaseweb/utils/tracking'
import { useCallback } from 'react'

export const useOnClickTracked = (
  {
    actionName,
    sectionName,
    interfaceType,
  }: {
    actionName?: string
    sectionName?: string
    interfaceType: UserActionInterfaceType
  },
  onClick?: (...props: any) => void,
) => {
  return useCallback(
    (...props: any) => {
      if (actionName && sectionName) {
        analytics.trackAction({
          actionName,
          sectionName,
          interfaceType,
        })
      }
      if (onClick) {
        onClick(...props)
      }
    },
    [actionName, sectionName, onClick, interfaceType],
  )
}
