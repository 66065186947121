/* eslint-disable no-restricted-imports */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @next/next/no-img-element */
import { cn } from '@chaseweb/utils/cn'
import { getImageProps } from 'next/image'
import { forwardRef, type HTMLAttributes, type RefObject } from 'react'

import type { MultiSizeImageData } from '@/types'

import type { NextImageProps } from '../vendor'
import { NextImage } from '../vendor'

interface MultiSizeImgProps extends Omit<NextImageProps, 'src'> {
  data: MultiSizeImageData
}

const MultiSizeImg = forwardRef<
  RefObject<HTMLPictureElement>,
  HTMLAttributes<HTMLPictureElement> & MultiSizeImgProps
>((props, ref) => {
  const { data, alt, priority, fill, className, ...restProps } = props
  const _className = cn('tw-h-full tw-w-full', className)

  if (process.env.IS_DOCKER_BUILD) {
    const common = {
      ...restProps,
      placeholder: undefined,
      fill: fill,
      alt: alt,
    }
    const mobile = data.mobile
      ? getImageProps({ ...common, src: data.mobile?.src }).props.srcSet
      : null
    const tablet = data.tablet
      ? getImageProps({ ...common, src: data.tablet?.src }).props.srcSet
      : null
    const {
      props: { srcSet: desktop, ...rest },
    } = getImageProps({ ...common, src: data.desktop.src })
    return (
      <picture
        ref={ref as RefObject<HTMLElement>}
        className={cn('tw-h-full tw-w-full')}
      >
        {mobile && <source media="(max-width: 767px)" srcSet={mobile} />}
        {tablet && <source media="(max-width: 1023px)" srcSet={tablet} />}
        <source srcSet={desktop} />
        <img {...rest} alt={alt} />
      </picture>
    )
  }

  return (
    <picture
      ref={ref as RefObject<HTMLElement>}
      className={cn('tw-h-full tw-w-full')}
    >
      {data.mobile?.optimisedVersions?.map(({ src, type }) => (
        <source key={src} srcSet={src} type={type} media="(max-width: 767px)" />
      ))}
      {data.mobile && (
        <source srcSet={data.mobile.src} media="(max-width: 767px)" />
      )}
      {data.tablet?.optimisedVersions?.map(({ src, type }) => (
        <source
          key={src}
          srcSet={src}
          type={type}
          media="(max-width: 1023px)"
        />
      ))}
      {data.tablet && (
        <source srcSet={data.tablet.src} media="(max-width: 1023px)" />
      )}

      {data.desktop.optimisedVersions &&
      data.desktop.optimisedVersions.length > 0 ? (
        <>
          {/* We can't use NextImage and optimised avifs at the same time, as it causes two copies of the image to be downloaded. All resolved when we move to next image optimisation. */}
          {data.desktop.optimisedVersions.map((ov) => (
            <source key={ov.src} srcSet={ov.src} type={ov.type} />
          ))}
          <img
            alt={alt}
            src={data.desktop.src}
            className={_className}
            {...restProps}
          />
        </>
      ) : (
        <NextImage
          {...restProps}
          className={_className}
          fill={fill}
          priority={priority}
          alt={alt}
          src={data.desktop.src}
          placeholder={
            data.desktop.base64 && props.placeholder === 'blur'
              ? 'blur'
              : 'empty'
          }
          blurDataURL={data.desktop.base64}
        />
      )}
    </picture>
  )
})
MultiSizeImg.displayName = 'MultiSizeImg'

export { MultiSizeImg }
