/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { RuntimeConfiguration } from './runtime-configuration'
const TIMEOUT = 10000
const INTERVAL = 25

export const getChaseEnv = async (): Promise<
  RuntimeConfiguration | Record<string, unknown>
> => {
  if (typeof window !== 'undefined') {
    const startTime = Date.now()
    // eslint-disable-next-line no-prototype-builtins
    while (!window.hasOwnProperty('__chaseEnv__')) {
      const currentTime = Date.now()
      if (currentTime - startTime > TIMEOUT) break
      await new Promise((resolve) => setTimeout(resolve, INTERVAL))
    }
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { __chaseEnv__ = {} } = window
    return __chaseEnv__
  }
  return {}
}
