import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Heading } from '../../atoms/heading'
import { cva } from 'class-variance-authority'

export interface RootProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
  hasHoverEffect?: boolean
  variant?: 'lg' | 'md' | 'sm'
}

const rootVariants = cva(
  'tw-group/tile tw-box-border tw-flex tw-w-full tw-gap-4 tw-text-text',
  {
    variants: {
      variant: {
        lg: 'tw-flex-col tw-gap-4 md:tw-flex-row md:tw-items-center md:tw-gap-x-6 md:tw-gap-y-8 lg:tw-flex-col lg:tw-gap-4 ',
        md: 'tw-flex-col lg:tw-flex-row',
        sm: 'tw-flex-row',
      },
    },
  },
)

const Root = forwardRef<HTMLDivElement, RootProps>(
  ({ className, variant, hasHoverEffect, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(rootVariants({ variant }), className)}
      data-has-hover-effect={hasHoverEffect}
      data-variant={variant}
      {...props}
    />
  ),
)
Root.displayName = 'ArticleTile.Root'

const ImageWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-flex tw-w-full tw-overflow-hidden tw-rounded-2xl lg:tw-basis-1/3',
          'group-data-[variant=sm]/tile:tw-h-max group-data-[variant=sm]/tile:tw-basis-1/2 group-data-[variant=sm]/tile:md:tw-basis-1/4 group-data-[variant=sm]/tile:lg:tw-basis-1/3',
          'group-data-[variant=md]/tile:tw-aspect-[640/384] group-data-[variant=md]/tile:tw-h-max group-data-[variant=md]/tile:lg:tw-aspect-square',
          'group-data-[variant=lg]/tile:lg:tw-basis-full group-data-[variant=lg]/tile:lg:tw-rounded-3xl',
          'group-data-[has-hover-effect=true]/tile:group-hover/tile:[&_*]:tw-scale-110',
          'motion-reduce:group-data-[has-hover-effect=true]/tile:group-hover/tile:[&_*]:tw-scale-100',
          '[&_*]:tw-aspect-square [&_*]:tw-w-full [&_*]:tw-overflow-hidden [&_*]:tw-object-cover [&_*]:tw-object-center [&_*]:tw-transition-transform [&_*]:tw-duration-300',
          className,
        )}
        {...props}
        ref={ref}
      />
    )
  },
)
ImageWrapper.displayName = 'ArticleTile.ImageWrapper'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-w-full',
        'group-data-[variant=sm]/tile:tw-basis-1/2 group-data-[variant=sm]/tile:md:tw-basis-3/4 group-data-[variant=sm]/tile:lg:tw-basis-2/3',
        'group-data-[variant=md]/tile:lg:tw-basis-2/3',
        className,
      )}
      {...props}
    />
  ),
)
Content.displayName = 'ArticleTile.Content'

const Label = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('tw-font-semibold', className)} {...props} />
))
Label.displayName = 'ArticleTile.Label'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type = 'h3', ...props }, ref) => (
  <Heading
    ref={ref}
    type={type}
    className={cn('tw-mb-0 tw-mt-2 tw-text-xl tw-font-semibold', className)}
    {...props}
  />
))
Title.displayName = 'ArticleTile.Title'

const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('tw-mt-2', className)} {...props} />
  ),
)
Description.displayName = 'ArticleTile.Description'

const Summary = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('tw-mt-2 tw-text-grey40', className)}
    {...props}
  />
))
Summary.displayName = 'ArticleTile.Summary'

export { Root, ImageWrapper, Content, Label, Title, Summary, Description }
