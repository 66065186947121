'use client'

import { forwardRef, useImperativeHandle } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
  RefObject,
} from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { useParallaxImage } from '@chaseweb/utils/useParallaxImage'
import { CardProfile } from '../../atoms'

const Root = forwardRef<
  HTMLElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'section'
  return (
    <Comp
      data-ui-component={Root.displayName}
      ref={ref}
      className={cn(
        'tw-relative tw-w-full tw-overflow-hidden tw-pb-8 tw-pt-[calc(100vw_+_3rem)] md:tw-pb-10 md:tw-pt-28 lg:tw-pt-64 xl:tw-pb-12 2xl:tw-pt-[20rem]',
        className,
      )}
      {...props}
    />
  )
})
Root.displayName = 'FullWidthFeature.Root'

const ImageWrapper = forwardRef<
  RefObject<HTMLDivElement>,
  HTMLAttributes<HTMLDivElement> & {
    parallax?: boolean
  }
>(({ children, parallax, ...props }, ref): JSX.Element => {
  const [pictureRef, pictureWrapperRef] = useParallaxImage(parallax, 20)
  /* istanbul ignore next - can't test refs easily in jest */
  useImperativeHandle(ref, () => pictureWrapperRef, [])
  return (
    <div
      ref={pictureWrapperRef}
      data-ui-component={ImageWrapper.displayName}
      className={cn(
        'tw-absolute tw-left-0 tw-top-0 -tw-z-30 tw-h-full tw-w-full',
      )}
      {...props}
    >
      <div
        ref={pictureRef}
        className={cn(
          'tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full [&>*]:tw-absolute [&_*]:tw-w-full [&_*]:tw-object-cover md:[&_*]:tw-object-center',
          {
            '-tw-top-[10%] tw-h-[120%]': parallax,
          },
        )}
      >
        {children}
      </div>
    </div>
  )
})
ImageWrapper.displayName = 'FullWidthFeature.ImageWrapper'

const Content = forwardRef<
  ElementRef<typeof CardProfile>,
  ComponentPropsWithoutRef<typeof CardProfile>
>(({ className, children, ...props }, ref) => {
  return (
    <div className="tw-container tw-flex tw-flex-row-reverse ">
      <CardProfile
        ref={ref}
        data-ui-component={Content.displayName}
        className={cn(
          'tw-bg-white tw-p-6 tw-pb-8 md:tw-max-w-xs md:tw-justify-end lg:tw-max-w-md',
          className,
        )}
        {...props}
      >
        {children}
      </CardProfile>
    </div>
  )
})
Content.displayName = 'FullWidthFeature.Content'

export { Root, ImageWrapper, Content }
