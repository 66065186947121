import type { SVGSpriteComponent } from '../types'

const ChaseLogo: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol {...props}>
      {children}
      <path
        d="M115.688072,0.118803687 C115.458553,0.118803687 115.238499,0.207457434 115.076624,0.365147585 C114.914749,0.522837737 114.824406,0.736553667 114.825573,0.958984211 L114.825573,6.8099321 L130.78365,6.8099321 L123.512926,0.118803687 L115.688072,0.118803687 Z"
        fill="#005EB8"
        fillRule="nonzero"
      ></path>
      <path
        d="M131.38256,8.58990222 C131.384866,8.36711994 131.294913,8.15272361 131.132775,7.99477767 C130.970638,7.83683174 130.74988,7.74854916 130.519991,7.74969884 L124.469248,7.74969884 L124.469248,23.2367606 L131.38256,16.1775119 L131.38256,8.58990222 Z"
        fill="#005EB8"
        fillRule="nonzero"
      ></path>
      <path
        d="M122.632574,23.7824449 C123.108907,23.7824449 123.495051,23.4082222 123.495051,22.9465952 L123.495051,17.0956473 L107.536997,17.0956473 L114.798783,23.7824449 L122.632574,23.7824449 Z"
        fill="#005EB8"
        fillRule="nonzero"
      ></path>
      <path
        d="M106.947116,15.3243389 C106.948298,15.5460209 107.040302,15.758168 107.202887,15.9141087 C107.365471,16.0700494 107.585318,16.1570094 107.814062,16.1558801 L113.8514,16.1558801 L113.8514,0.686142083 L106.956054,7.73239833 L106.947116,15.3243389 Z"
        fill="#005EB8"
        fillRule="nonzero"
      ></path>
      <polygon
        fill="#000000"
        fillRule="nonzero"
        points="33.7840632 2.94250319 33.7840632 10.0883685 23.5147805 10.0883685 23.5147805 2.94250319 19.7788708 2.94250319 19.7788708 20.9587454 23.5147805 20.9587454 23.5147805 13.2195568 33.7840632 13.2195568 33.7840632 20.9587454 37.5333792 20.9587454 37.5333792 2.94250319"
      ></polygon>
      <polygon
        fill="#000000"
        fillRule="nonzero"
        points="83.5440559 2.94250319 83.5440559 20.9544146 99.9534576 20.9544146 97.884407 17.7842489 87.2978403 17.7842489 87.2978403 13.2195568 97.5492482 13.2195568 97.5492482 10.1663233 87.2978403 10.1663233 87.2978403 6.04337564 97.8620635 6.04337564 99.9043011 2.94250319"
      ></polygon>
      <path
        d="M5.87199193,2.94250319 C1.9796746,2.94250319 0.0134063743,5.23351091 0.0134063743,8.57257888 L0.0134063743,15.2896923 C0.0134063743,19.1484595 2.54274232,20.9544146 5.84964798,20.9544146 L17.5846943,20.9544146 L15.4083928,17.6629857 L6.57359219,17.6629857 C4.69669979,17.6629857 3.89231733,17.0046999 3.89231733,14.9692111 L3.89231733,8.87140596 C3.89231733,6.90087948 4.58498,6.1299922 6.64062406,6.1299922 L15.5156438,6.1299922 L17.6025694,2.94250319 L5.87199193,2.94250319 Z"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M67.1346536,2.93817236 C64.9002578,2.93817236 62.5809551,4.2374206 62.5809551,7.61546599 L62.5809551,8.48163151 C62.5809551,11.9896018 64.8153508,13.3278274 67.0139962,13.3408199 L74.794162,13.3408199 C75.6074821,13.3408199 76.2599256,13.4664139 76.2599256,14.7916471 L76.2599256,16.3074367 C76.2599256,17.4984143 75.6298261,17.7712564 74.7718182,17.7712564 L64.5338169,17.7712564 L62.433485,20.9370913 L75.0131328,20.9370913 C78.0474424,20.9370913 80.1030861,19.4776024 80.1030861,16.099557 L80.1030861,14.8609404 C80.1030861,11.5954964 78.1859745,10.049391 75.2231661,10.049391 L67.7915659,10.049391 C66.9693083,10.049391 66.397303,9.83718048 66.397303,8.6505338 L66.397303,7.40758629 C66.397303,6.39850349 66.7950254,6.03038317 67.7379404,6.03038317 L77.4799057,6.03038317 L79.5221433,2.92951071 L67.1346536,2.93817236 Z"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M48.4014799,2.94683402 L39.5845545,20.9500837 L43.749468,20.9500837 L45.465484,17.2385646 L55.0465728,17.2385646 L56.7536511,20.9500837 L60.9364399,20.9500837 L52.106108,2.94683402 L48.4014799,2.94683402 Z M50.242622,6.68866894 L53.6254971,14.1680079 L46.8776221,14.1680079 L50.242622,6.68866894 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </symbol>
  )
}
ChaseLogo.displayName = 'SVG.ChaseLogo'
const ChaseLogoConfigProps = {
  viewBox: '0 0 132 24',
}
export { ChaseLogo, ChaseLogoConfigProps }
