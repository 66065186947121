'use client'

import { AnimationPhonePermissions } from '@chaseweb/ui-library/src/atoms/animation'
import { Suspense } from 'react'

export const PhonePermissionsAnimation = () => {
  return (
    <Suspense>
      <AnimationPhonePermissions />
    </Suspense>
  )
}
