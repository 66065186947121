import type { RefObject } from 'react'
import { create } from 'zustand'

type CtaRef = RefObject<HTMLElement> | null

interface HeaderCTAState {
  alwaysVisible: boolean
  setAlwaysVisible: (ref: boolean) => void
  ctaRef: CtaRef
  setCtaRef: (ref: CtaRef) => void
}

export const useHeaderCTAStore = create<HeaderCTAState>((set) => ({
  alwaysVisible: false,
  setAlwaysVisible: (value: boolean) => set(() => ({ alwaysVisible: value })),
  ctaRef: null,
  setCtaRef: (ctaRef: CtaRef) => set(() => ({ ctaRef })),
}))
