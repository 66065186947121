import type { SVGSpriteComponent } from '../types'

const RoundUp: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M16 22C17.6569 22 19 20.6569 19 19C19 17.3431 17.6569 16 16 16C14.3431 16 13 17.3431 13 19C13 20.6569 14.3431 22 16 22Z"
        fill="#005EB8"
      />
      <path
        d="M6 20C8.20914 20 10 18.2091 10 16C10 13.7909 8.20914 12 6 12C3.79086 12 2 13.7909 2 16C2 18.2091 3.79086 20 6 20Z"
        fill="#80BBF6"
      />
      <path
        d="M14.5 13C17.5376 13 20 10.5376 20 7.5C20 4.46243 17.5376 2 14.5 2C11.4624 2 9 4.46243 9 7.5C9 10.5376 11.4624 13 14.5 13Z"
        fill="#005EB8"
      />
    </symbol>
  )
}
RoundUp.displayName = 'SVG.RoundUp'
const RoundUpConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { RoundUp, RoundUpConfigProps }
