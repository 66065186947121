import type { SVGSpriteComponent } from '../types'

const WarningStatus: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M21.4033 19.3542L12.4033 3.35415C12.1377 2.88195 11.3623 2.88195 11.0967 3.35415L2.09671 19.3542C1.96581 19.5861 1.96781 19.8703 2.10261 20.1003C2.23741 20.3302 2.48351 20.4719 2.75011 20.4719H20.7501C21.0167 20.4719 21.2628 20.3302 21.3976 20.1003C21.5324 19.8703 21.5341 19.5861 21.4033 19.3542ZM11 10.7218C11 10.3077 11.3359 9.97185 11.75 9.97185C12.1641 9.97185 12.5 10.3077 12.5 10.7218V13.7218C12.5 14.1359 12.1641 14.4718 11.75 14.4718C11.3359 14.4718 11 14.1359 11 13.7218V10.7218ZM11.75 17.5216C11.2529 17.5216 10.8501 17.1188 10.8501 16.6217C10.8501 16.1246 11.2529 15.7218 11.75 15.7218C12.2471 15.7218 12.6499 16.1246 12.6499 16.6217C12.6499 17.1188 12.2471 17.5216 11.75 17.5216Z"
        fill="currentColor"
      />
    </symbol>
  )
}

WarningStatus.displayName = 'SVG.WarningStatus'
const WarningStatusConfigProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
}
export { WarningStatus, WarningStatusConfigProps }
