'use client'

import { SVG } from '@chaseweb/ui-library/src/atoms'
import { useAttributionUrlLink } from '@chaseweb/utils/tracking/vendor'
import { memo } from 'react'

import { useChaseEnv } from '@/lib/hooks'

import { Link } from '../link'

export const DOWNLOAD_SUBSTRING = 'Download the app from the '
export const APPLE_STORE_SUBSTRING = 'Apple Store'
export const GOOGLE_PLAY_STORE_SUBSTRING = 'Google Play Store'

export interface AppStoreButtonProps {
  store: 'apple' | 'google'
  className?: string
}

export const AppStoreButton = memo(
  ({ store, className }: AppStoreButtonProps) => {
    const env = useChaseEnv()
    const link = useAttributionUrlLink({
      appsflyerLink: env?.openAppUrl ?? '',
      variant: store === 'apple' ? 'appstore' : 'playstore',
    })

    if (!globalThis || !env || !link) return null

    return (
      <Link notTracked href={link} className={className}>
        <SVG
          preserveAspectRatio={store === 'apple' ? 'none' : ''}
          name={store === 'apple' ? 'AppleAppStore' : 'GooglePlay'}
          aria-label={`${DOWNLOAD_SUBSTRING}${
            store === 'apple'
              ? APPLE_STORE_SUBSTRING
              : GOOGLE_PLAY_STORE_SUBSTRING
          }`}
        />
      </Link>
    )
  },
)
AppStoreButton.displayName = 'AppStoreButton'
