import { generateOneLinkUrl } from './appsflyerScript'

export const getOrigUrl = (search?: string, href?: string): string => {
  if (search) {
    const searchParamsUrl = new URLSearchParams(search)
    return searchParamsUrl.get('orig_url') as string
  }
  if (href) {
    return href
  }
  return ''
}

export const createAttributionLink = (
  attributionUrl: string,
  variant?: AttributionLinkVariant,
  origUrl?: string,
  sitePrefix?: string,
): string => {
  const oneLinkUrl = generateOneLinkUrl(attributionUrl, variant, sitePrefix)
  const urlObj = new URL(oneLinkUrl)
  const { searchParams } = urlObj
  /* istanbul ignore else: legacy */
  if (origUrl) {
    searchParams.append('orig_url', origUrl)
  }
  return urlObj.toString()
}

export type AttributionLinkVariant =
  | 'download'
  | 'appstore'
  | 'playstore'
  | undefined
