import type { SVGSpriteComponent } from '../types'

const Phone: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M6.844 10.025l1.9-1.357A13.41 13.41 0 017.647 5H5.01c-.006.166-.009.333-.009.5A13.498 13.498 0 0018.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 01-3.668-1.097l-1.357 1.9a12.442 12.442 0 01-1.588-.75l-.058-.033a12.556 12.556 0 01-4.702-4.702l-.033-.058a12.442 12.442 0 01-.75-1.588zm2.522.657a10.556 10.556 0 003.952 3.952l.884-1.238a1 1 0 011.294-.296 11.422 11.422 0 004.583 1.364 1 1 0 01.921.997v4.462a1 1 0 01-.898.995 15.51 15.51 0 01-17.02-17.02A1 1 0 014.077 3h4.462a1 1 0 01.997.921A11.422 11.422 0 0010.9 8.504a1 1 0 01-.296 1.294z"
      />
    </symbol>
  )
}

Phone.displayName = 'SVG.Phone'
const PhoneConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { Phone, PhoneConfigProps }
