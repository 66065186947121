import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'
import { useMemo } from 'react'
import slugify from 'slugify'

export const PageAnchor = ({
  idString = '',
  className,
  asChild,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  className?: string
  idString?: string
  asChild?: boolean
}) => {
  const Comp = asChild ? Slot : 'div'

  const slug = useMemo(() => {
    return slugify(idString, {
      strict: true,
      lower: true,
      remove: /\d/,
    })
  }, [idString])

  // Set scroll-margin-top cannot be set dynamically after the page has loaded
  // so these values have to be hardcoded
  return (
    <Comp
      id={slug}
      className={cn(className, 'tw-scroll-m-15 lg:tw-scroll-mt-24')}
      {...props}
    />
  )
}
