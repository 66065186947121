export enum NotificationSessionKeyTypesEnum {
  HOME = 'notification_home',
  PRODUCT = 'notification_product',
  SUPPORT = 'notification_support',
  BROWSER = 'notification_browser-support',
}

export enum NotificationLocalKeyTypesEnum {
  US_REDIRECT = 'notification_us-redirect',
}

export const NotificationKeyTypesEnum = {
  ...NotificationSessionKeyTypesEnum,
  ...NotificationLocalKeyTypesEnum,
}
