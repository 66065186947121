import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'

const Prose = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { asChild?: boolean; as?: string }
>(({ className, asChild, as, ...props }, ref) => {
  const Comp = asChild ? Slot : as ?? 'div'
  return <Comp className={cn('tw-prose', className)} {...props} ref={ref} />
})
Prose.displayName = 'Prose'

export { Prose }
