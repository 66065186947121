import { isDescendant } from './isDescendant'

export const trapFocus = (element: HTMLElement) => {
  let firstFocusableEl: HTMLElement
  let lastFocusableEl: HTMLElement
  const callback = () => {
    const focusableEls = element.querySelectorAll<HTMLElement>(
      'a:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
    )

    firstFocusableEl = focusableEls[0]
    lastFocusableEl = focusableEls[focusableEls.length - 1]

    const { activeElement } = document
    if (!activeElement || !isDescendant(element, activeElement)) {
      firstFocusableEl?.focus()
    }
  }

  const observer = new MutationObserver(callback)
  observer.observe(element, {
    subtree: true,
    childList: true,
  })

  callback()
  const handleKeyDown = (e: KeyboardEvent) => {
    const isTabPressed = e.key === 'Tab'
    if (!isTabPressed) {
      return
    }
    const { activeElement } = document
    if (
      !activeElement ||
      !isDescendant(element, activeElement) ||
      (activeElement === lastFocusableEl && !e.shiftKey)
    ) {
      firstFocusableEl.focus()
      e.preventDefault()
    } else if (e.shiftKey && activeElement === firstFocusableEl) {
      lastFocusableEl.focus()
      e.preventDefault()
    }
  }

  window.addEventListener('keydown', handleKeyDown)

  return () => {
    window.removeEventListener('keydown', handleKeyDown)
    observer.disconnect()
  }
}
