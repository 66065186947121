'use client'

import { useEffect, useState } from 'react'

export const AssertRehydration = () => {
  const [handlersReady, setHandlersReady] = useState(false)

  useEffect(() => {
    setHandlersReady(true)
  }, [])

  return (
    <div
      data-testid="AssertRehydration"
      data-handlers-ready={handlersReady}
    ></div>
  )
}
