import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'

import { updatePictureYTranslation } from './updatePictureYTranslation'

export interface UseParallaxImageArguments {
  applyParallax?: boolean
  factor?: number
}

export const useParallaxImage = (
  applyParallax: UseParallaxImageArguments['applyParallax'] = true,
  factor?: number,
): [RefObject<HTMLDivElement>, RefObject<HTMLDivElement>] => {
  const pictureRef = useRef<HTMLDivElement>(null)
  const pictureWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (applyParallax && pictureWrapperRef.current) {
      const isReduced = window.matchMedia(
        `(prefers-reduced-motion: reduce)`,
      ).matches
      if (isReduced) {
        return
      }
      const handleScroll = () => {
        updatePictureYTranslation(pictureRef, pictureWrapperRef, factor)
      }
      const checkVisibility: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            window.addEventListener('scroll', handleScroll, false)
          } else {
            window.removeEventListener('scroll', handleScroll, false)
          }
        })
      }
      const options: IntersectionObserverInit = {
        rootMargin: '5px',
        threshold: [0],
      }
      const observer = new IntersectionObserver(checkVisibility, options)
      observer.observe(pictureWrapperRef.current)
      window.addEventListener('resize', handleScroll, false)

      return () => {
        window.removeEventListener('scroll', handleScroll, false)
        window.removeEventListener('resize', handleScroll, false)
        observer.disconnect()
      }
    }
  }, [])
  return [pictureRef, pictureWrapperRef]
}
