import { forwardRef } from 'react'
import type { HTMLAttributes } from 'react'
import { cn } from '@chaseweb/utils/cn'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('tw-my-6 tw-flex tw-items-center', className)}
      {...props}
    />
  ),
)
Root.displayName = 'Avatar.Root'

const ImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    classNameWrapper?: string
  }
>(({ className, classNameWrapper, ...props }, ref) => {
  return (
    <div
      className={cn('tw-relative tw-mr-4 tw-h-14 tw-w-fit', classNameWrapper)}
    >
      <div
        className={cn(
          'tw-h-14 tw-w-14 tw-overflow-hidden tw-rounded-full tw-bg-blue30 tw-bg-chase-octagon tw-bg-[size:1.5rem] tw-bg-center tw-bg-no-repeat',
          className,
        )}
        {...props}
        ref={ref}
      />
    </div>
  )
})
ImageWrapper.displayName = 'Avatar.ImageWrapper'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('', className)} {...props} />
  ),
)
Content.displayName = 'Avatar.Content'

const Name = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('tw-font-semibold', className)} {...props} />
  ),
)
Name.displayName = 'Avatar.Title'

const Info = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('', className)} {...props} />
  ),
)
Info.displayName = 'Avatar.Title'

export { Root, ImageWrapper, Content, Name, Info }
