import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cn } from '@chaseweb/utils/cn'
import { Heading } from '../../atoms/heading'
import { Button } from '../../atoms/button'
import { CardProfile } from '../../atoms/card-profile'

const Root = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    gradientDirection?: 'bottomRight' | 'topLeft'
    hasDiagonalBlue?: boolean
    classNameDiagonal?: string
    classNameGradient?: string
  }
>(
  (
    {
      className,
      classNameDiagonal,
      classNameGradient,
      children,
      gradientDirection,
      hasDiagonalBlue = false,
      ...props
    },
    ref,
  ) => (
    <CardProfile
      ref={ref}
      shape="hero"
      className={cn(
        'tw-z-0 tw-flex tw-min-h-[46rem] tw-flex-col sm:tw-min-h-[39rem] md:tw-min-h-[31rem] md:tw-flex-row lg:tw-min-h-[38rem] xl:tw-min-h-[44rem]',
        className,
      )}
      {...props}
      data-ui-component={Root.displayName}
    >
      {children}
      {gradientDirection && (
        <div
          className={cn(
            'tw-absolute -tw-z-20 tw-h-full tw-w-full tw-from-[#21263099] tw-from-0% tw-to-[50%] md:tw-w-full',
            {
              'tw-bg-gradient-to-b md:tw-bg-gradient-to-r':
                gradientDirection === 'bottomRight',
              'tw-bg-gradient-to-t md:tw-bg-gradient-to-l':
                gradientDirection === 'topLeft',
            },
            classNameGradient,
          )}
        />
      )}
      {hasDiagonalBlue && (
        <div
          className={cn(
            'tw-blue-radial-gradient-portrait tw-absolute -tw-z-40 tw-h-full tw-w-full md:tw-blue-radial-gradient-landscape',
            classNameDiagonal,
          )}
        />
      )}
    </CardProfile>
  ),
)
Root.displayName = 'Hero.Root'

const Container = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-container tw-flex tw-h-full tw-self-center max-md:tw-px-2',
        )}
      >
        <div
          ref={ref}
          data-ui-component={Container.displayName}
          className={cn(
            'tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-8 tw-py-6 md:tw-flex-row md:tw-items-center md:tw-justify-between md:tw-py-16 lg:tw-py-24',
            'md:-tw-mx-4 lg:-tw-mx-6 xl:tw-mx-0',
            className,
          )}
          {...props}
        />
      </div>
    )
  },
)
Container.displayName = 'Hero.Container'

const BackgroundImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { spacerClassName?: string }
>(({ className, spacerClassName, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-ui-component={BackgroundImageWrapper.displayName}
      {...props}
    >
      <div
        className={cn(
          'tw-absolute tw-left-0 tw-top-0 -tw-z-30 tw-h-full tw-w-full',
          '[&_*]:tw-h-full [&_*]:tw-w-full [&_*]:tw-object-cover',
          `[&_*]:tw-object-[center_bottom] md:[&_*]:tw-object-[70%]`, // tune object position in collaboration with design teams
          className,
        )}
      >
        {children}
      </div>
    </div>
  )
})
BackgroundImageWrapper.displayName = 'Hero.BackgroundImageWrapper'

const Content = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      ref={ref}
      data-ui-component={Content.displayName}
      className={cn(
        'tw-flex tw-flex-col tw-justify-center md:tw-max-w-[288px] lg:tw-max-w-md',
        className,
      )}
      {...props}
    />
  )
})
Content.displayName = 'Hero.Content'

const ImageWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        data-ui-component={ImageWrapper.displayName}
        className={cn(
          'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-overflow-hidden md:tw-w-1/2',
          '[&>*]:tw-right-0 [&>*]:-tw-z-10 [&_*]:tw-block [&_*]:tw-w-full [&_*]:tw-max-w-full [&_*]:tw-justify-center [&_*]:tw-object-contain',
          'sm:[&_*]:tw-w-[420px]', // set the height of the image so it doesn't go too large
          'md:[&_*]:tw-max-h-[366px]', // this is to ensure Primary and Secondary heros have the same height with the current content
          'lg:[&_*]:tw-max-h-full',

          className,
        )}
        {...props}
      />
    )
  },
)
ImageWrapper.displayName = 'Hero.ImageWrapper'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, ...props }, ref) => (
  <Heading
    type="h1"
    ref={ref}
    data-ui-component={Title.displayName}
    className={cn(
      'tw-text-[50px] tw-font-light tw-leading-[58px] tw-text-primaryText lg:tw-text-[64px] lg:tw-font-normal lg:tw-leading-[74px]',
      className,
    )}
    {...props}
  />
))
Title.displayName = 'Hero.Title'

const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      data-ui-component={Description.displayName}
      className={cn('tw-mt-6 tw-text-primaryText', className)}
      {...props}
    />
  ),
)
Description.displayName = 'Hero.Description'

const Cta = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => (
  <Button
    ref={ref}
    data-ui-component={Cta.displayName}
    variant="primary"
    className={cn('tw-mt-6 tw-w-fit tw-font-semibold', className)}
    {...props}
  />
))
Cta.displayName = 'Hero.Cta'

export {
  Root,
  Container,
  BackgroundImageWrapper,
  Content,
  ImageWrapper,
  Title,
  Description,
  Cta,
}
