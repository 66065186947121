import type { SVGSpriteComponent } from '../types'

const Search: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path
        d="M19.5296 18.4701L15.6896 14.629C17.1453 12.8997 17.8769 10.6751 17.7318 8.41932C17.5866 6.16358 16.5759 4.05105 14.9106 2.52261C13.2452 0.994183 11.054 0.167956 8.79408 0.216369C6.53419 0.264782 4.38031 1.1841 2.78196 2.78245C1.18361 4.38079 0.264294 6.53468 0.215881 8.79456C0.167467 11.0545 0.993695 13.2457 2.52213 14.9111C4.05056 16.5764 6.16309 17.5871 8.41883 17.7323C10.6746 17.8774 12.8992 17.1458 14.6285 15.6901L18.4696 19.5301C18.6118 19.6626 18.7998 19.7347 18.9941 19.7313C19.1884 19.7278 19.3738 19.6491 19.5112 19.5117C19.6486 19.3743 19.7273 19.1889 19.7308 18.9946C19.7342 18.8003 19.6621 18.6123 19.5296 18.4701V18.4701ZM8.99959 16.2501C7.56567 16.2501 6.16396 15.8249 4.97171 15.0282C3.77945 14.2316 2.8502 13.0993 2.30146 11.7745C1.75273 10.4498 1.60915 8.99204 1.8889 7.58567C2.16864 6.17931 2.85913 4.88748 3.87307 3.87355C4.887 2.85962 6.17882 2.16913 7.58518 1.88938C8.99155 1.60964 10.4493 1.75322 11.774 2.30195C13.0988 2.85069 14.2311 3.77994 15.0277 4.97219C15.8244 6.16445 16.2496 7.56616 16.2496 9.00008C16.2474 10.9222 15.4828 12.765 14.1237 14.1242C12.7645 15.4833 10.9217 16.2479 8.99959 16.2501V16.2501Z"
        fill="currentColor"
      />
    </symbol>
  )
}
Search.displayName = 'SVG.Search'
const SearchConfigProps = {
  width: '20',
  height: '20',
  viewBox: '0 0 20 20',
}
export { Search, SearchConfigProps }
