import { deleteCookies, getCookie, setCookie } from '../cookies'
import type { CookieConsentsArgs, InitConsentsArgs } from './types'

export const COOKIE_CONSENTS = 'CookieConsents'
export const ONETRUST_ALERT_CLOSED = 'OptanonAlertBoxClosed'
export const ONETRUST_CONSENTS = 'OptanonConsent'
const PRODUCTION_HOST = 'www.chase.co.uk'
const PRODUCTION_ONE_TRUST_COOKIE_DOMAIN = '.chase.co.uk'

let setConsentsState: any

export const setCookieConsents = (value: CookieConsentsArgs): void => {
  const timestamp = value.timestamp || new Date()
  const consents = {
    ...value,
    timestamp: new Date(timestamp).toUTCString(),
  }
  const strValue = JSON.stringify(consents)
  const date = new Date()
  date.setFullYear(date.getFullYear() + 1) // adds 1 year
  setCookie({
    name: COOKIE_CONSENTS,
    value: strValue,
    expires: date.toUTCString(),
    path: '/',
    sameSite: 'Lax',
  })
  setConsentsState?.(consents)
}

// Deprecated - confusing API and returns value regardless of timestamp
// use #getAllConsents
export const getConsents = (
  name?:
    | string
    | 'StrictlyNecessary'
    | 'Analytical'
    | 'Performance'
    | 'AdvertisingAndTargeting',
) => {
  const cookieConsents = getCookie(COOKIE_CONSENTS)
  if (cookieConsents?.value) {
    const cookieValue = JSON.parse(cookieConsents.value)
    return name ? cookieValue?.[name] : cookieValue
  }
}

const consentsHaveExpired = (timestamp: string) => {
  const timestampNow = new Date().getTime()
  const dateCookie = new Date(timestamp)
  dateCookie.setFullYear(dateCookie.getFullYear() + 1) // adds 1 year
  return timestampNow > dateCookie.getTime()
}

export const getAllConsents = (): CookieConsentsArgs | undefined => {
  const cookieConsents = getCookie(COOKIE_CONSENTS)
  if (cookieConsents?.value) {
    const cookieValue = JSON.parse(cookieConsents.value)
    if (consentsHaveExpired(cookieValue.timestamp)) return
    return cookieValue
  }
}

export const mapOneTrustConsents = (cookieValue: any): void => {
  const { groups, timestamp } = cookieValue

  if (groups) {
    const mappedValue: CookieConsentsArgs = {
      StrictlyNecessary: groups.includes('C0001:1'),
      Analytical: groups.includes('C0002:1'),
      Performance: groups.includes('C0003:1'),
      AdvertisingAndTargeting: groups.includes('C0004:1'),
      timestamp,
    }

    setCookieConsents(mappedValue)
  }
}

export const checkAndMapOneTrustConsents = (): void => {
  const inHouseConsentTimestamp = getConsents('timestamp')
  const oneTrustConsentTimestamp = getCookie(ONETRUST_ALERT_CLOSED)?.value
  if (oneTrustConsentTimestamp) {
    const oneTrustCookie = getCookie(ONETRUST_CONSENTS)
    const cookieURLSearchParams =
      oneTrustCookie && new URLSearchParams(oneTrustCookie.value)
    const groups = cookieURLSearchParams?.get('groups')
    ;(!inHouseConsentTimestamp ||
      new Date(oneTrustConsentTimestamp) > new Date(inHouseConsentTimestamp)) &&
      mapOneTrustConsents({ timestamp: oneTrustConsentTimestamp, groups })
  }
  deleteCookies(
    [ONETRUST_ALERT_CLOSED, ONETRUST_CONSENTS],
    '/',
    window.location.host === PRODUCTION_HOST
      ? PRODUCTION_ONE_TRUST_COOKIE_DOMAIN
      : undefined,
  )
}

export const initConsents = ({ setState }: InitConsentsArgs) => {
  setConsentsState = setState
  checkAndMapOneTrustConsents()
}
