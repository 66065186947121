import { getCookie } from '../cookies'

export const utmParameter = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_dynamic',
  'af_xp',
  'af_sub1',
  'ranMID',
  'af_adset_id',
  'af_sub_siteID',
  'ranSiteID',
  'af_click_lookback',
  'ranEAID',
  'clickid',
  'af_siteid',
  'pid',
]

export const mediaUtmParameter = ['utm_source', 'utm_medium', 'utm_campaign']

export const getUtmParameters = () => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search
    const utmParameterKeyValues = {} as any
    if (queryString) {
      const urlParams = new URLSearchParams(queryString)
      urlParams.forEach((value, key) => {
        if (utmParameter.includes(key)) {
          utmParameterKeyValues[key] = value
        }
      })
    }
    return Object.keys(utmParameterKeyValues).length !== 0
      ? utmParameterKeyValues
      : undefined
  }
  return undefined
}

export const getUtmParametersFromCookie = () => {
  const utmCookie = getCookie('utmParameters')
  return utmCookie?.value && JSON.parse(utmCookie.value)
}
