import type {
  ComponentPropsWithoutRef,
  DetailedHTMLProps,
  ElementRef,
  HTMLAttributes,
  ThHTMLAttributes,
} from 'react'
import { forwardRef } from 'react'
import { Heading } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'

import { cva, type VariantProps } from 'class-variance-authority'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('tw-mb-2', className)}
      {...props}
      data-component="DataTable.Root"
    />
  ),
)
Root.displayName = 'DataTable.Root'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type = 'h2', ...props }, ref) => (
  <Heading
    ref={ref}
    type={type}
    className={cn('tw-mt-16 lg:tw-mt-18', className)}
    data-component="DataTable.Title"
    {...props}
  />
))
Title.displayName = 'DataTable.Title'

const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('tw-mt-6', className)}
      {...props}
      data-component="DataTable.Description"
    />
  ),
)
Description.displayName = 'DataTable.Description'

const Container = forwardRef<
  HTMLTableElement,
  HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'tw-group tw-mt-10 tw-overflow-hidden tw-rounded-2xl tw-border tw-border-grey70 tw-align-top lg:tw-mt-12',
      className,
    )}
  >
    <table
      ref={ref}
      className="tw-w-full tw-table-fixed"
      {...props}
      data-component="DataTable.Container"
    />
  </div>
))
Container.displayName = 'DataTable.Container'

const Header = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn('tw-bg-grey90', className)}
    {...props}
    data-component="DataTable.Header"
  />
))
Header.displayName = 'DataTable.Header'

const Body = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('', className)}
    {...props}
    data-component="DataTable.Body"
  />
))
Body.displayName = 'DataTable.Body'

const ColumnHeaderCell = forwardRef<
  HTMLTableCellElement,
  DetailedHTMLProps<
    ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > & {
    'data-component'?: string
  }
>(({ className, 'data-component': dataComponent, ...props }, ref) => (
  <th
    ref={ref}
    className={cn('tw-p-4 tw-text-left tw-align-top tw-font-bold', className)}
    {...props}
    data-component={dataComponent ?? 'DataTable.ColumnHeaderCell'}
  />
))
ColumnHeaderCell.displayName = 'DataTable.ColumnHeaderCell'

const RowHeaderCell = forwardRef<
  ElementRef<typeof ColumnHeaderCell>,
  ComponentPropsWithoutRef<typeof ColumnHeaderCell>
>(({ ...props }, ref) => (
  <ColumnHeaderCell
    ref={ref}
    {...props}
    data-component="DataTable.RowHeaderCell"
  />
))
RowHeaderCell.displayName = 'DataTable.RowHeaderCell'

const rowVariants = cva('tw-border-grey70 [&:not(:first-child)]:tw-border-t', {
  variants: {
    isHeader: {
      true: 'tw-bg-grey90',
    },
  },
  defaultVariants: {
    isHeader: false,
  },
})

interface RowProps
  extends HTMLAttributes<HTMLTableRowElement>,
    VariantProps<typeof rowVariants> {}

const Row = forwardRef<HTMLTableRowElement, RowProps>(
  ({ className, isHeader, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn(rowVariants({ isHeader }), className)}
      {...props}
      data-component="DataTable.Row"
    />
  ),
)
Row.displayName = 'DataTable.Row'

const Cell = forwardRef<
  HTMLTableCellElement,
  HTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn('tw-p-4 tw-align-top', className)}
    {...props}
    data-component="DataTable.Cell"
  />
))
Cell.displayName = 'DataTable.Cell'

const Footer = forwardRef<
  HTMLTableRowElement,
  HTMLAttributes<HTMLTableRowElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('tw-mt-10 tw-text-sm tw-text-grey40 lg:tw-mt-12', className)}
    {...props}
    data-component="DataTable.Footer"
  >
    {children}
  </div>
))
Footer.displayName = 'DataTable.Footer'

export {
  Root,
  Title,
  Description,
  Container,
  Header,
  Body,
  ColumnHeaderCell,
  RowHeaderCell,
  Row,
  Cell,
  Footer,
}
