import type { SVGSpriteComponent } from '../types'

const Copy: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => {
  return (
    <symbol fill="currentColor" {...props}>
      {children}
      <path d="M9,6h7a1,1,0,0,1,1,1v9h2V4H9ZM5,8V20H15V8ZM7,6V3A1,1,0,0,1,8,2H20a1,1,0,0,1,1,1V17a1,1,0,0,1-1,1H17v3a1,1,0,0,1-1,1H4a1,1,0,0,1-.71-.29A1,1,0,0,1,3,21V7A1,1,0,0,1,4,6Z" />
    </symbol>
  )
}

Copy.displayName = 'SVG.Copy'
const CopyConfigProps = { width: '24', height: '24' }
export { Copy, CopyConfigProps }
