export const REDACTED = 'REDACTED'
export const ENCRYPTED_ID_REGEX = /^@\S+==$/
export const UUID_REGEX =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[12345][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export const LOCAL_PHONE_NUMBER_1_REGEX =
  /^(.*[^\d])?0(\d{4}[\s.-]?\d{3}[\s.-]?\d{3}|\d{3}[\s.-]?\d{3}[\s.-]?\d{4}|\d{2}[\s.-]?\d{4}[\s.-]?\d{4})([^\d].*)?$/
export const LOCAL_PHONE_NUMBER_2_REGEX =
  /^(.*[^\d])?(\(0\d{4}\)[\s.-]?\d{3}[\s.-]?\d{3}|\(0\d{3}\)[\s.-]?\d{3}[\s.-]?\d{4}|\(0\d{2}\)[\s.-]?\d{4}[\s.-]?\d{4})([^\d].*)?$/
export const INTERNATIONAL_PHONE_NUMBER_1 =
  /(\+44([\s]?(\(0\)[\s]?)?|[-.]))(\d{4}[\s.-]?\d{3}[\s.-]?\d{3}|\d{3}[\s.-]?\d{3}[\s.-]?\d{4}|\d{2}[\s.-]?\d{4}[\s.-]?\d{4})([^\d].*)?$/
export const INTERNATIONAL_PHONE_NUMBER_2 =
  /\+44[\s]?(\(0\d{4}\)[\s.-]?\d{3}[\s.-]?\d{3}|\(0\d{3}\)[\s.-]?\d{3}[\s.-]?\d{4}|\(0\d{2}\)[\s.-]?\d{4}[\s.-]?\d{4})([^\d].*)?$/
export const CREDIT_CARD_NUMBER = /^(?:\d[ -.]*?){16}$/
