import { useEffect, useState } from 'react'

export const useIsMobileBreakpoint = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const checkDevice = () => {
      const isMobile = window.innerWidth < 1024
      setIsMobile(isMobile)
    }
    checkDevice()
    window.addEventListener('resize', checkDevice, false)
    return () => {
      window.removeEventListener('resize', checkDevice, false)
    }
  }, [])

  return isMobile
}
