import type { SVGSpriteComponent } from '../types'

const App: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M18 21.75H6C5.80109 21.75 5.61032 21.671 5.46967 21.5303C5.32902 21.3897 5.25 21.1989 5.25 21V3C5.25 2.80109 5.32902 2.61032 5.46967 2.46967C5.61032 2.32902 5.80109 2.25 6 2.25H18C18.1989 2.25 18.3897 2.32902 18.5303 2.46967C18.671 2.61032 18.75 2.80109 18.75 3V21C18.75 21.1989 18.671 21.3897 18.5303 21.5303C18.3897 21.671 18.1989 21.75 18 21.75ZM6.75 20.25H17.25V3.75H6.75V20.25ZM12 17.25C11.8517 17.25 11.7067 17.294 11.5833 17.3764C11.46 17.4588 11.3639 17.5759 11.3071 17.713C11.2503 17.85 11.2355 18.0008 11.2644 18.1463C11.2933 18.2918 11.3648 18.4254 11.4697 18.5303C11.5746 18.6352 11.7082 18.7066 11.8537 18.7356C11.9992 18.7645 12.15 18.7497 12.287 18.6929C12.4241 18.6361 12.5412 18.54 12.6236 18.4167C12.706 18.2933 12.75 18.1483 12.75 18C12.75 17.8011 12.671 17.6103 12.5303 17.4697C12.3897 17.329 12.1989 17.25 12 17.25Z"
        fill="currentColor"
      />
    </symbol>
  )
}
App.displayName = 'SVG.App'
const AppConfigProps = { width: '24px', viewBox: '0 0 24 24' }
export { App, AppConfigProps }
