import type { CookieConsentsArgs } from '@chaseweb/utils/tracking'
import {
  checkAndMapOneTrustConsents,
  getAllConsents,
  setCookieConsents,
} from '@chaseweb/utils/tracking'
import { create } from 'zustand'

export interface ConsentState {
  consents: {
    StrictlyNecessary: boolean
    Analytical: boolean
    Performance: boolean
    AdvertisingAndTargeting: boolean
  }
  initialised: boolean
  captured: boolean
  init: () => void
  reset: () => void
  setConsents: (consents: Omit<CookieConsentsArgs, 'StrictlyNecessary'>) => void
  isModalOpen: boolean
  setCurrentStep: (newStep: number) => void
  currentModalStep: number
  closeModal: () => void
}

const initialValues = {
  currentModalStep: 0,
  isModalOpen: false,
  consents: {
    StrictlyNecessary: true,
    Analytical: false,
    Performance: false,
    AdvertisingAndTargeting: false,
  },
  initialised: false,
  captured: false,
}

export const useConsentStore = create<ConsentState>((set) => ({
  ...initialValues,
  reset: () => set(() => initialValues),
  setCurrentStep: (newStep: number) =>
    set(() => {
      return {
        currentModalStep: newStep,
        isModalOpen: true,
      }
    }),
  closeModal: () =>
    set((state) => {
      if (state.captured) {
        return { isModalOpen: false }
      } else {
        return {
          currentModalStep: 0,
        }
      }
    }),
  init: () =>
    set((state) => {
      if (state.initialised) return {}
      checkAndMapOneTrustConsents()
      const consents = getAllConsents()
      if (consents) {
        return {
          consents,
          initialised: true,
          captured: true,
        }
      } else {
        return {
          initialised: true,
          isModalOpen: true,
        }
      }
    }),
  setConsents: (consents: Omit<CookieConsentsArgs, 'StrictlyNecessary'>) =>
    set(() => {
      const newConsents = {
        ...consents,
        StrictlyNecessary: true,
      }
      setCookieConsents(newConsents)
      return {
        consents: newConsents,
        captured: true,
        isModalOpen: false,
        currentModalStep: 0,
      }
    }),
}))
