import { setCookie } from '../cookies'
import { redactPii } from '../validators'
import { getUtmParameters } from './utmUtils'

export const captureUtmParameters = async () => {
  if (typeof window !== 'undefined') {
    const utmParameterKeyValues = await getUtmParameters()
    if (
      utmParameterKeyValues &&
      Object.keys(utmParameterKeyValues).length !== 0
    ) {
      setCookie({
        name: 'utmParameters',
        value: JSON.stringify(utmParameterKeyValues),
      })
    }
  }
}

const redactedUrlHash = (hash?: string) => {
  if (!hash) {
    return null
  }

  const clippedHash = hash.substring(1)
  const hasMultipleParams = hash.includes('=')
  if (!hasMultipleParams) {
    return redactPii(clippedHash)
  }
  const redacted = new URLSearchParams(clippedHash)

  for (const [key, value] of redacted) {
    redacted.set(key, redactPii(value))
  }

  return redacted.toString()
}

export const redactedUrl = (url?: URL): URL => {
  const result = new URL(url?.toString() ?? window.location.toString())

  result.hash = redactedUrlHash(result.hash) ?? ''
  const params = result.searchParams
  for (const key of params.keys()) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    params.set(key, redactPii(params.get(key)!))
  }
  return result
}
