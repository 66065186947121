import { lazy } from 'react'

/**
 * Warning. Before you try to restructure this code, know that
 * a lot of time was spent to make sure the code-splitting in nextjs was working properly.
 *
 * The json animation files are very very large and cause easily causes the JS bundle to be 5Mb+
 * if the code splitting does not work. The app will still copile and run, but wimth a huge bundle.
 *
 * Triple check this is not happening in the production build if you change any of the patterns here.
 */

export const AnimationError404 = lazy(
  async () => await import('./animations/error-404'),
)

export const AnimationGenericError = lazy(
  async () => await import('./animations/generic-error'),
)

export const AnimationPhonePermissions = lazy(
  async () => await import('./animations/phone-permissions'),
)
