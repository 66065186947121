'use client'

import { forwardRef, useEffect, useRef, useState } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
  LegacyRef,
} from 'react'
import * as RadixTabs from '@radix-ui/react-tabs'
import { useIsMobileBreakpoint } from '@chaseweb/utils/useIsMobileBreakpoint'
import { cn } from '@chaseweb/utils/cn'
import { Button, SVG } from '../../atoms'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { TabsContextProvider, useTabsContext } from './tabs.context'

const Root = forwardRef<
  ElementRef<typeof RadixTabs.Root>,
  Omit<
    ComponentPropsWithoutRef<typeof RadixTabs.Root>,
    'orientation' | 'activationMode'
  > & { defaultTabLabel?: string; defaultValue?: string }
>(
  (
    {
      className,
      defaultValue,
      defaultTabLabel,
      onValueChange,
      children,
      ...props
    },
    ref,
  ) => {
    const [isTopMenuOpen, setIsTopMenuOpen] = useState(false)
    const isMobile = useIsMobileBreakpoint()
    const containerRef = useRef<ElementRef<typeof RadixTabs.Root>>(null)
    const [tabSelected, setTabSelected] = useState<{
      label?: string
      path?: string
    }>({ label: defaultTabLabel, path: defaultValue })

    const handleValueChange = (newValue: string) => {
      const tabEl = containerRef.current?.querySelectorAll(
        '[aria-selected=true]',
      )[0]
      if (onValueChange) {
        onValueChange(newValue)
      }
      setTabSelected({
        label: tabEl?.textContent as string,
        path: newValue,
      })
    }

    useEffect(() => {
      if (!defaultTabLabel && containerRef.current) {
        const tabEl = containerRef.current?.querySelectorAll(
          '[aria-selected=true]',
        )[0]

        setTabSelected({
          label: tabEl?.textContent as string,
          path: defaultValue as string,
        })
      }
    }, [defaultTabLabel, containerRef.current])

    return (
      <TabsContextProvider
        isTopMenuOpen={isTopMenuOpen}
        defaultValue={defaultValue}
        setIsTopMenuOpen={setIsTopMenuOpen}
        setTabSelected={setTabSelected}
        tabSelected={tabSelected}
        isMobile={isMobile}
        onValueChange={onValueChange}
      >
        <>
          {isMobile && (
            <div className={cn('tw-h-full', className)} ref={ref}>
              {children}
            </div>
          )}
          {!isMobile && (
            <RadixTabs.Root
              defaultValue={tabSelected.path}
              activationMode={'automatic'}
              orientation={'horizontal'}
              onValueChange={handleValueChange}
              className={cn('tw-h-full', className)}
              ref={containerRef}
              {...props}
            >
              {children}
            </RadixTabs.Root>
          )}
        </>
      </TabsContextProvider>
    )
  },
)
Root.displayName = 'Tabs.Root'

const List = forwardRef<
  ElementRef<typeof RadixTabs.List> | HTMLDivElement,
  ComponentPropsWithoutRef<typeof RadixTabs.List> & {
    'data-testid'?: string
    mobileButtonClassName?: string
    mobileCloseTopMenuClassName?: string
    'mobile-aria-label-close'?: string
    'mobile-aria-label-open'?: string
  }
>(
  (
    {
      className,
      'data-testid': dataTestId,
      'mobile-aria-label-close': mobileAriaLabelClose,
      'mobile-aria-label-open': mobileAriaLabelOpen,
      mobileButtonClassName,
      mobileCloseTopMenuClassName,
      children,
      ...props
    },
    ref,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const mobileDropdownButtonRef = useRef<ElementRef<typeof Button>>(null)

    const {
      tabSelected,
      isMobile,
      defaultValue,
      isTopMenuOpen,
      setIsTopMenuOpen,
    } = useTabsContext()

    let stateContainer
    if (isTopMenuOpen) {
      stateContainer = 'open'
    } else if (!isTopMenuOpen) {
      stateContainer = 'closed'
    }

    useEffect(() => {
      if (isTopMenuOpen && containerRef.current) {
        const handleResize = () => {
          setIsTopMenuOpen(false)
        }
        const handleKeydown = (ev: KeyboardEvent) => {
          if (ev.key === 'Escape') {
            setIsTopMenuOpen(false)
          }
        }
        window.document.body.classList.add('ui-no-scroll')
        window.addEventListener('keydown', handleKeydown, false)
        window.addEventListener('resize', handleResize, false)
        return () => {
          window.document.body.classList.remove('ui-no-scroll')
          window.removeEventListener('keydown', handleKeydown, false)
          window.removeEventListener('resize', handleResize, false)
        }
      }
    }, [isTopMenuOpen])

    return (
      <div
        ref={containerRef}
        className={cn(
          'ui-stop-no-scroll tw-flex tw-w-full tw-items-center tw-justify-center',
        )}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        data-testid={dataTestId && `${dataTestId}-container`}
      >
        {isMobile ? (
          <>
            <div className={cn('tw-relative lg:tw-hidden')} {...props}>
              <DialogPrimitive.Root
                open={isTopMenuOpen}
                onOpenChange={() => {
                  setIsTopMenuOpen(!isTopMenuOpen)
                }}
              >
                <DialogPrimitive.Trigger asChild>
                  <Button
                    ref={mobileDropdownButtonRef}
                    variant="link"
                    aria-label={mobileAriaLabelOpen}
                    tabIndex={isTopMenuOpen ? -1 : 0}
                    className={cn(
                      'tw-relative tw-flex tw-text-base',
                      'focus-visible:tw-border-focusRing focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-focusRing focus-visible:tw-ring-offset-0 lg:tw-hidden',
                      'tw-mt-0.5 tw-rounded-6xl tw-border tw-border-grey70 tw-py-2 tw-pl-6 tw-pr-4 tw-transition-colors tw-duration-300',
                      'hover:tw-border-grey60 hover:tw-text-blue30 focus-visible:tw-text-blue30',
                      'tw-border-solid tw-bg-white tw-font-semibold tw-text-blue30 active:tw-border-grey40',
                      mobileButtonClassName,
                    )}
                    data-testid={
                      dataTestId && `${dataTestId}-mobile-tabs-button`
                    }
                  >
                    {!tabSelected.label ? defaultValue : tabSelected.label}
                    <SVG name="ArrowDown" />
                  </Button>
                </DialogPrimitive.Trigger>
                <DialogPrimitive.Portal>
                  <div
                    aria-hidden="true"
                    data-testid={dataTestId && `${dataTestId}-backdrop`}
                    className={cn(
                      'tw-fixed tw-left-full tw-top-0 tw-z-30 tw-h-screen tw-w-screen tw-bg-grey10 tw-opacity-0 peer-data-[state=closed]/tabs:tw-animate-backdropFadeOut peer-data-[state=open]/tabs:tw-animate-backdropFadeIn lg:tw-hidden',
                      {
                        'tw-left-0 tw-opacity-30': isTopMenuOpen,
                      },
                    )}
                  />
                  <DialogPrimitive.Overlay data-testid="overlay" forceMount>
                    <DialogPrimitive.Content>
                      <Button
                        onClick={() => {
                          setIsTopMenuOpen(!isTopMenuOpen)
                        }}
                        variant="link"
                        aria-label={mobileAriaLabelClose}
                        tabIndex={isTopMenuOpen ? 0 : -1}
                        className={cn(
                          'tw-fixed tw-right-3 tw-top-4 tw-text-grey10 tw-opacity-0 tw-transition-opacity',
                          {
                            'tw-z-50 tw-opacity-100': isTopMenuOpen,
                          },
                          mobileCloseTopMenuClassName,
                        )}
                        data-testid={
                          dataTestId && `${dataTestId}-mobile-close-tabs-button`
                        }
                      >
                        <SVG name="Close" />
                      </Button>

                      <div
                        data-state={stateContainer}
                        className={cn(
                          'tw-peer/tabs tw-relative -tw-translate-y-full tw-font-semibold max-lg:data-[state=closed]:tw-animate-tabsPanelFadeOut max-lg:data-[state=open]:tw-animate-slideFromTop lg:tw-translate-y-0',
                          'tw-rounded-6xl tw-bg-grey90 lg:tw-p-0.5',
                          {
                            'tw-fixed tw-left-0 tw-top-0 tw-z-40 tw-h-fit tw-w-full tw-translate-y-0 tw-rounded-b-2xl tw-rounded-t-none tw-bg-white tw-shadow-popup ':
                              isTopMenuOpen,
                          },
                          className,
                        )}
                        data-testid={'navlist'}
                      >
                        <ul
                          role={'list'}
                          data-testid={dataTestId}
                          data-top-menu={isTopMenuOpen}
                          className={cn(
                            'tw-hidden tw-items-center tw-gap-1 lg:tw-flex',
                            {
                              'tw-flex tw-max-h-screen tw-flex-col tw-items-center tw-gap-6 tw-overflow-auto tw-overscroll-contain tw-px-4 tw-pb-12 tw-pt-16 md:tw-px-16':
                                isTopMenuOpen,
                            },
                          )}
                        >
                          {children}
                        </ul>
                      </div>
                    </DialogPrimitive.Content>
                  </DialogPrimitive.Overlay>
                </DialogPrimitive.Portal>
              </DialogPrimitive.Root>
            </div>
          </>
        ) : (
          <>
            <div
              data-state={stateContainer}
              className={cn(
                'tw-peer/tabs tw-relative -tw-translate-y-full tw-font-semibold max-lg:data-[state=closed]:tw-animate-tabsPanelFadeOut max-lg:data-[state=open]:tw-animate-slideFromTop lg:tw-translate-y-0',
                'tw-rounded-6xl tw-bg-grey90 lg:tw-p-0.5',
                className,
              )}
              data-testid={dataTestId && `${dataTestId}-content`}
            >
              <RadixTabs.List
                role={'tablist'}
                ref={ref}
                data-testid={dataTestId}
                data-top-menu={isTopMenuOpen}
                className={cn('tw-hidden tw-items-center tw-gap-1 lg:tw-flex')}
                {...props}
              >
                {children}
              </RadixTabs.List>
            </div>

            <div
              aria-hidden="true"
              data-testid={dataTestId && `${dataTestId}-backdrop`}
              className={cn(
                'tw-fixed tw-left-full tw-top-0 tw-z-30 tw-h-screen tw-w-screen tw-bg-grey10 tw-opacity-0 peer-data-[state=closed]/tabs:tw-animate-backdropFadeOut peer-data-[state=open]/tabs:tw-animate-backdropFadeIn lg:tw-hidden',
              )}
            />
          </>
        )}
      </div>
    )
  },
)

List.displayName = 'Tabs.List'

const Trigger = forwardRef<
  ElementRef<typeof RadixTabs.Trigger> | HTMLLIElement,
  ComponentPropsWithoutRef<typeof RadixTabs.Trigger> &
    HTMLAttributes<HTMLLIElement> & {
      'mobile-aria-label'?: string
      'aria-label'?: string
    }
>(
  (
    {
      className,
      'mobile-aria-label': mobileAriaLabel,
      'aria-label': ariaLabel,
      children,
      value,
      ...props
    },
    ref,
  ) => {
    const buttonContainer = useRef<HTMLLIElement>(null)
    const {
      isMobile,
      tabSelected,
      isTopMenuOpen,
      setIsTopMenuOpen,
      onValueChange,
      setTabSelected,
    } = useTabsContext()
    const additionalProps = isMobile
      ? {
          tabIndex: 0,
        }
      : {}

    const [isActive, setIsActive] = useState('inactive')

    const handleClick = () => {
      setIsTopMenuOpen(!isTopMenuOpen)
      if (buttonContainer.current?.textContent != null) {
        setTabSelected({
          path: value,
          label: buttonContainer.current.textContent,
        })
        if (onValueChange) {
          onValueChange(value)
        }
      }
    }

    useEffect(() => {
      if (buttonContainer.current && tabSelected.path === value) {
        setIsActive('active')
      }
    })

    return (
      <>
        {isMobile && (
          <li ref={buttonContainer as LegacyRef<HTMLLIElement>}>
            <Button
              value={value}
              onClick={handleClick}
              data-state={isActive}
              variant={'link'}
              role={'link'}
              aria-label={mobileAriaLabel}
              className={cn(
                'tw-bg-transparent tw-text-base tw-font-semibold tw-capitalize tw-text-grey10 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-focusRing',
                'tw-border-solid tw-border-transparent tw-transition-colors tw-duration-300 hover:tw-text-blue30 focus-visible:tw-text-blue30',
                'max-lg:data-[state=active]:tw-underline max-lg:data-[state=active]:tw-decoration-blue30 max-lg:data-[state=active]:tw-underline-offset-4',
                className,
              )}
              {...additionalProps}
              {...props}
            >
              {children}
            </Button>
          </li>
        )}
        {!isMobile && (
          <RadixTabs.Trigger
            value={value}
            ref={ref as LegacyRef<ElementRef<typeof RadixTabs.Trigger>>}
            role={'tab'}
            aria-label={ariaLabel}
            className={cn(
              'tw-bg-transparent tw-text-base tw-font-semibold tw-capitalize tw-text-grey10 focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-focusRing',
              'tw-rounded-6xl tw-border tw-border-solid tw-border-transparent tw-bg-grey90 tw-px-6 tw-py-2 tw-transition-colors tw-duration-300 hover:tw-text-blue30',
              'hover:tw-text-blue30 focus-visible:tw-text-blue30 hover:data-[state=active]:tw-border-grey60 focus-visible:data-[state=active]:tw-border-focusRing',
              'data-[state=active]:tw-border-grey70 data-[state=active]:tw-bg-white data-[state=active]:tw-text-blue30',
              className,
            )}
            {...additionalProps}
            {...props}
          >
            {children}
          </RadixTabs.Trigger>
        )}
      </>
    )
  },
)

Trigger.displayName = 'Tabs.Trigger'

const Content = forwardRef<
  ElementRef<typeof RadixTabs.Content>,
  ComponentPropsWithoutRef<typeof RadixTabs.Content>
>(({ className, ...props }, ref) => {
  const { isMobile } = useTabsContext()

  return (
    <>
      {!isMobile && (
        <RadixTabs.Content
          ref={ref}
          className={cn('tw-mt-6 lg:tw-mt-8', className)}
          {...props}
          tabIndex={-1}
        />
      )}
    </>
  )
})

Content.displayName = 'Tabs.Content'

export { Root, List, Trigger, Content }
