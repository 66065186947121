'use client'

import { cn } from '@chaseweb/utils/cn'
import { cva } from 'class-variance-authority'
import {
  useEffect,
  type ElementType,
  type FC,
  type HTMLAttributes,
  useState,
} from 'react'

const hamburgerSpanVariants = cva(
  'tw-absolute tw-block tw-h-0.5 tw-w-full tw-rounded-5xl tw-bg-grey10 tw-transition-all tw-will-change-[transform,_width]',
  {
    variants: {
      position: {
        first: 'tw-left-0 tw-top-0 tw-origin-[-3%_255%]',
        second: 'tw-top-1/2 tw-origin-center -tw-translate-y-1/2',
        third: 'tw-bottom-0 tw-right-0 tw-origin-[108%_-75%]',
      },
      active: {
        false: '',
        first: 'tw-w-1/2 tw-rotate-45',
        second: 'tw-w-4 -tw-rotate-45',
        third: 'tw-w-1/2 tw-rotate-45',
      },
    },
    defaultVariants: {
      active: false,
    },
  },
)

interface HamburgerIconProps extends HTMLAttributes<HTMLDivElement> {
  active: boolean
  activeOnMount?: boolean
  as?: ElementType
}

export const HamburgerIcon: FC<HamburgerIconProps> = ({
  active,
  as,
  className,
  children,
  activeOnMount,
  ...props
}) => {
  const Comp = as ?? 'div'
  const [isActive, setIsActive] = useState(active && activeOnMount)

  // This is a workaround as 2 hamburgers icons (Trigger and Close) are stacked on top of each other
  // in the Navigation component.
  useEffect(() => {
    setIsActive(active)
  }, [active])

  return (
    <Comp {...props} className={cn('tw-relative tw-h-4 tw-w-4', className)}>
      <span
        className={cn(
          hamburgerSpanVariants({
            position: 'first',
            active: isActive && 'first',
          }),
        )}
      />
      <span
        className={cn(
          hamburgerSpanVariants({
            position: 'second',
            active: isActive && 'second',
          }),
        )}
      />
      <span
        className={cn(
          hamburgerSpanVariants({
            position: 'third',
            active: isActive && 'third',
          }),
        )}
      />
      {children}
    </Comp>
  )
}
