'use client'
import { forwardRef } from 'react'
import type { ToggleProps } from '@radix-ui/react-toggle'
import { Root } from '@radix-ui/react-toggle'
import { cn } from '@chaseweb/utils/cn'

interface Props extends ToggleProps {
  className?: string
  onLabel?: string | JSX.Element | JSX.Element[]
  offLabel?: string | JSX.Element | JSX.Element[]
}

const Toggle = forwardRef<HTMLButtonElement, Props>(
  ({ className, onLabel, offLabel, ...props }, ref) => {
    return (
      <div className="tw-flex">
        <Root
          ref={ref}
          className={cn(
            'tw-peer/toggle tw-group/toggle tw-relative tw-inline-flex tw-h-6 tw-w-14 tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-duration-250 tw-ease-in-out',
            'tw-outline-2 tw-outline-offset-4 focus-visible:tw-outline-blue50',
            'data-[state=off]:tw-bg-grey40',
            'data-[state=on]:tw-bg-blue30',
            className,
          )}
          {...props}
        >
          <span
            aria-hidden="true"
            className={cn(
              'tw-pointer-events-none tw-inline-block tw-h-5 tw-w-8 tw-transform tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-duration-500 tw-ease-in-out',
              'group-data-[state=off]/toggle:tw-translate-x-0',
              'group-data-[state=on]/toggle:tw-translate-x-5',
            )}
          />
        </Root>
        <div className="tw-ml-4 tw-font-semibold peer-data-[state=off]/toggle:tw-hidden">
          {onLabel}
        </div>
        <div className="tw-ml-4 tw-font-semibold peer-data-[state=on]/toggle:tw-hidden">
          {offLabel}
        </div>
      </div>
    )
  },
)
Toggle.displayName = 'Toggle'

export { Toggle }
