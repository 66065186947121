import { useEffect, useState } from 'react'

export const useHasRehydrated = () => {
  const [hasRehydrated, setHasRehydrated] = useState<boolean>(false)

  useEffect(() => {
    setHasRehydrated(true)
  }, [])

  return hasRehydrated
}
