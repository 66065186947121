'use client'

import { cn } from '@chaseweb/utils/cn'
import { trapFocus } from '@chaseweb/utils/trapFocus'

import * as Portal from '@radix-ui/react-portal'
import type { HTMLAttributes } from 'react'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

const Dialog = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { isOpen: boolean }
>(({ className, isOpen, children, ...props }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null)

  /* istanbul ignore next - can't test refs easily in jest */
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => containerRef.current,
    [],
  )

  useEffect(() => {
    let inertElements: Element[]
    if (containerRef.current && isOpen) {
      const portalEl = containerRef.current.parentElement
      if (portalEl?.parentElement?.children) {
        const excludedTags = ['SCRIPT', 'IMG']
        inertElements = [...portalEl.parentElement?.children].filter(
          (element) =>
            element !== portalEl && !excludedTags.includes(element.tagName),
        )
        inertElements.forEach((element) => {
          element.setAttribute('inert', 'true')
        })
      }
      document.documentElement.style.overflow = 'hidden'
      const dismissTrapFocus = trapFocus(containerRef.current)

      return () => {
        if (inertElements) {
          inertElements.forEach((element) => {
            element.removeAttribute('inert')
          })
        }
        document.documentElement.style.overflow = 'auto'
        dismissTrapFocus()
      }
    }
  }, [isOpen])

  const content = (
    <div
      id="container"
      ref={containerRef}
      {...props}
      className={cn('tw-fixed tw-z-50', className)}
    >
      {children}
    </div>
  )

  return <Portal.Root>{content}</Portal.Root>
})

Dialog.displayName = 'Dialog'

export { Dialog }
