import type { SVGSpriteComponent } from '../types'

const Close: SVGSpriteComponent = ({
  children,
  role,
  spriteFilePath,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <symbol {...props}>
      {children}
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M13.414 12l4.293-4.293a1 1 0 00-1.414-1.414L12 10.586 7.707 6.293a1 1 0 00-1.414 1.414L10.586 12l-4.293 4.293a1 1 0 101.414 1.414L12 13.414l4.293 4.293a1 1 0 001.414-1.414z"
        fill="currentColor"
      />
    </symbol>
  )
}

Close.displayName = 'SVG.Close'
const CloseConfigProps = { width: '24', viewBox: '0 0 24 24' }
export { Close, CloseConfigProps }
